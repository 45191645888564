import { ImageFieldProps, ImageField, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import get from 'lodash/get';
import { IFile } from 'types/strapi';
import EmptyTableCell from './EmptyTableCell';
import ListImageFieldModal from './ListImageFieldModal';
import { useState } from 'react';

export default function ListImageField(props: ImageFieldProps) {
  const { source, ...rest } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const record = useRecordContext(props);

  if (!source) return null;

  const imagesValue: IFile | IFile[] | undefined = get(record, source);
  const images = !imagesValue
    ? []
    : Array.isArray(imagesValue)
    ? imagesValue
    : [imagesValue];

  if (!images.length) return <EmptyTableCell />;

  const fullSizeImagesUrls = images.map((image) => image.url);
  const thumbnailsUrls = images.map(
    (image) => image?.formats?.thumbnail?.url || image.url
  );
  const thumbnailsUrlsPaths = thumbnailsUrls.map(
    (image, index) => `${source}[${index}].url`
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsModalOpen(true);
      }}
    >
      <ImageList images={thumbnailsUrlsPaths} {...rest} />
      {isModalOpen && (
        <ListImageFieldModal
          images={fullSizeImagesUrls}
          onClose={() => setIsModalOpen(false)}
          open={true}
        />
      )}
    </div>
  );
}

const ImageList = (props: { images: string[] } & ImageFieldProps) => {
  const { images, ...rest } = props;
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const imagesCount = images.length;
  const moreImagesCount = imagesCount - 3;

  if (isSmall) {
    return (
      <ImageField
        {...rest}
        source={images[0]}
        sortable={false}
        classes={{ image: classes.image }}
      />
    );
  }

  if (imagesCount > 3)
    return (
      <div className={classes.imagesWrapper}>
        {images.slice(0, 3).map((image) => (
          <ImageField
            {...rest}
            key={image}
            source={image}
            sortable={false}
            classes={{ image: classes.image }}
          />
        ))}
        <HiddenImagesCounter count={moreImagesCount} />
      </div>
    );

  return (
    <div className={classes.imagesWrapper}>
      {images.map((image) => (
        <ImageField
          {...rest}
          key={image}
          source={image}
          sortable={false}
          classes={{ image: classes.image }}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    margin: 0,
    width: '3rem',
    height: '3rem',
    borderRadius: '100%',
    objectFit: 'cover',
    zIndex: 1,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      width: '2rem',
      height: '2rem',
    },
  },
  placeholderSvg: {
    color: theme.palette.grey[300],
    width: '100%',
    height: '100%',
  },
  imagesWrapper: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& > :not(:first-child)': {
        marginLeft: '-8px',
        zIndex: 1,
      },
    },
  },
  hiddenCount: {
    margin: 0,
    width: '3rem',
    height: '3rem',
    borderRadius: '100%',
    backgroundColor: theme.palette.grey[300],
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

interface HiddenImagesCounterProps {
  count: number;
}

function HiddenImagesCounter(props: HiddenImagesCounterProps) {
  const { count } = props;

  const classes = useStyles();

  return <div className={classes.hiddenCount}>+{count}</div>;
}
