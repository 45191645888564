import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';
import * as strapi from '@casbah/strapi-fetch';
import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  LineController
);

export default function DashboardSendoutsChart() {
  const { sendoutsByMonths, loading, error } = useSendoutsChartData();
  const styles = useStyles();

  if (loading) {
    return (
      <Box className={styles.container}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.container}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!sendoutsByMonths) {
    return null;
  }

  return (
    <Box className={styles.chartContainer}>
      <ChartComponent data={sendoutsByMonths} />
    </Box>
  );
}

interface ISendoutsByMonth {
  month: string;
  count: number;
}
const useSendoutsChartData = () => {
  const [sendoutsByMonths, setSendoutsByMonths] = useState<ISendoutsByMonth[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;

    function generateLastTwelveMonthsFirstAndLastDay() {
      const currentMonth = new Date().getMonth();
      return Array.from({ length: 12 }, (_, i) => i).map((i) => [
        changeMonthAndDay(new Date(), currentMonth - i, 1),
        changeMonthAndDay(new Date(), currentMonth - i + 1, 0),
      ]);
    }

    const lastTwelveMonthsFirstAndLastDay =
      generateLastTwelveMonthsFirstAndLastDay();

    function changeMonthAndDay(date: Date, month: number, day: number) {
      date.setMonth(month, day);
      return date;
    }

    Promise.all(
      lastTwelveMonthsFirstAndLastDay.map(([startDate, endDate]) =>
        strapi.get<number>(
          getStrapiURL(
            `/sendouts/count?_limit=-1&created_at_gte=${
              startDate.toISOString().split('T')[0]
            }&created_at_lte=${endDate.toISOString().split('T')[0]}`
          ),
          getJwt()
        )
      )
    )
      .then((res) => {
        if (!mounted) return;
        if (res.some((c) => !c.ok)) {
          setError('Error fetching sendouts');
          return;
        }
        setSendoutsByMonths(
          res
            .map((c, index) => ({
              month: lastTwelveMonthsFirstAndLastDay[
                index
              ][0].toLocaleDateString('en-GB', { month: 'short' }),
              count: c.payload as number,
            }))
            .reverse()
        );
      })
      .finally(() => {
        if (!mounted) return;
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return {
    sendoutsByMonths,
    loading,
    error,
  } as const;
};

function ChartComponent(props: { data: ISendoutsByMonth[] }) {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  const styles = useChartStyles();

  useEffect(() => {
    if (!props.data.length) {
      setChartData({ datasets: [] });
      return;
    }

    const createGradient = (ctx?: CanvasRenderingContext2D) => {
      if (!ctx) return;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(48,217,136,0.34)');
      gradient.addColorStop(1, 'rgba(48,217,136,0.05)');
      return gradient;
    };

    setChartData({
      labels: props.data.map((s) => s.month),
      datasets: [
        {
          data: props.data.map((s) => s.count),
          borderColor: '#30D988',
          backgroundColor: createGradient(chartRef.current?.ctx),
          borderWidth: 2,
          fill: true,
          pointBackgroundColor: '#30D988',
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    });
  }, [props.data]);

  return (
    <Chart
      className={styles.chart}
      ref={chartRef}
      type="line"
      data={chartData}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  container: { padding: '2rem' },
  chartContainer: {
    height: '400px',
    padding: '2rem',
  },
}));

const useChartStyles = makeStyles((theme) => ({
  chart: {
    height: '100% !important',
    width: '100% !important',
  },
}));
