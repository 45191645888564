import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function Section(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const styles = useStyles();
  return <Card className={styles.card}>{props.children}</Card>;
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '30px 50px',
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 30px',
    },
  },
}));
