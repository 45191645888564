import {
  ChipField,
  TextField,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Box } from '@material-ui/core';
import ListImageField from '../ListImageField';
import { IProduct } from 'types/strapi';
import SendoutProductReturnedField from './SendoutProductReturnedField';

export default function ProductMobile(props: {
  onItemClick?: (product: IProduct) => void;
}) {
  const { onItemClick } = props;
  const product = useRecordContext<IProduct>();
  const resource = useResourceContext();
  const isResourceSendout = resource === 'sendouts';

  const onClick = () => {
    onItemClick?.(product);
  };

  if (!product) return null;

  return (
    <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <TextField source="name" />
      </Box>
      <Box sx={{ ml: 1 }}>
        <ListImageField label="Images" source="images" />
      </Box>
      {!!product.brand?.name && (
        <Box sx={{ ml: 1 }}>
          <ChipField source="brand.name" />
        </Box>
      )}
      {!!product.size?.name && (
        <Box sx={{ ml: 1 }}>
          <ChipField source="size.name" />
        </Box>
      )}
      {isResourceSendout && (
        <Box sx={{ ml: 1 }}>
          <SendoutProductReturnedField />
        </Box>
      )}
    </Box>
  );
}
