import { FieldProps, useRecordContext } from 'react-admin';
import { IProduct } from 'types/strapi';

const Colors = (props: FieldProps<IProduct>) => {
  const product = useRecordContext<IProduct>();
  const count = product?.colors.length;

  if (!product?.colors.length) return null;

  if (count > 2)
    return (
      <>
        {product?.colors
          .slice(0, 2)
          .map((c) => c.name)
          .join(', ')}{' '}
        +{count - 2}
      </>
    );

  return <>{product?.colors.map((c) => c.name).join(', ')}</>;
};

export default Colors;
