import { useState, useEffect } from 'react';
import {
  TextField,
  DateField,
  Labeled,
  ListContextProvider,
  useList,
  FunctionField,
  ListView,
} from 'react-admin';
import { ISendout } from 'types/strapi';
import { Box, Typography } from '@material-ui/core';
import SendoutChat from 'components/SendoutChat';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { getIdArray } from 'utils/getId';
import { getStrapiURL } from 'utils/getStrapiUrl';
import ResponsiveProductList from 'components/ResponsiveProductList';
import _ from 'lodash';
import { productColumns } from 'constants/productColumns';
import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import TwoColumns from './TwoColumns';
import SectionHeader from './SectionHeader';
import { sendoutProductsColumns } from 'constants/sendoutProductsColumns';
import EmptyTable from './EmptyTable';
import * as strapi from '@casbah/strapi-fetch';

export default function SendoutShare() {
  const shareToken = _.last(window.location.pathname.split('/'));
  const styles = useStyles();
  const [sendout, setSendout] = useState<ISendout | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;
    strapi
      .get<ISendout>(getStrapiURL(`/sendouts/token/${shareToken}`))
      .then((res) => {
        if (!mounted) return;
        if (res.ok) {
          setSendout(res.payload as ISendout);
        } else {
          setError(res.messages.join(', ') || 'Unknown error');
        }
      });
    return () => {
      mounted = false;
    };
  }, [shareToken]);

  const productsContext = useList({
    data: sendout?.products,
    ids: getIdArray(sendout?.products),
    loading: !Boolean(sendout),
    loaded: Boolean(sendout),
  });

  if (error)
    return (
      <Box className={styles.root}>
        <Box className={styles.inner}>
          <Box className={styles.header}>
            <Box>
              <Logo className={styles.logoSvg} />
            </Box>
          </Box>
          <Box className={styles.content}>
            <Typography color="error">{error}</Typography>
          </Box>
        </Box>
      </Box>
    );

  if (!sendout) return null;

  return (
    <Box className={styles.root}>
      <Box className={styles.inner}>
        <Box className={styles.header}>
          <Box>
            <Logo className={styles.logoSvg} />
          </Box>
        </Box>

        <Box className={styles.content}>
          <Box>
            <Section>
              <FieldWithLabel label="Status">
                <TextField record={sendout} source="status" emptyText="n/a" />
              </FieldWithLabel>
              <TwoColumns>
                <Box>
                  <FieldWithLabel label="Due date">
                    <DateField
                      emptyText="n/a"
                      record={sendout}
                      source="dueDate"
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="Talent">
                    <TextField
                      record={sendout}
                      source="talent"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                </Box>
                <Box>
                  <FieldWithLabel label="Expected return date">
                    <DateField
                      emptyText="n/a"
                      record={sendout}
                      source="estimatedReturnDate"
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="Event">
                    <TextField
                      record={sendout}
                      source="event"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                </Box>
              </TwoColumns>
              <FieldWithLabel label="Notes">
                <TextField record={sendout} source="notes" emptyText="n/a" />
              </FieldWithLabel>
            </Section>

            <SectionHeader title="Recipient" />
            <Section>
              <TwoColumns>
                <Box>
                  <FieldWithLabel label="Contact">
                    <FunctionField<ISendout>
                      record={sendout}
                      emptyText="n/a"
                      render={(sendout) =>
                        `${sendout?.contact?.firstName || ''} ${
                          sendout?.contact?.lastName || ''
                        }`
                      }
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="Address line 1">
                    <TextField
                      record={sendout}
                      source="address.line1"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="Address line 2">
                    <TextField
                      record={sendout}
                      source="address.line2"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                </Box>
                <Box>
                  <FieldWithLabel label="Postcode">
                    <TextField
                      record={sendout}
                      source="address.postcode"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="City">
                    <TextField
                      record={sendout}
                      source="address.city"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                  <FieldWithLabel label="Country">
                    <TextField
                      record={sendout}
                      source="address.country"
                      emptyText="n/a"
                    />
                  </FieldWithLabel>
                </Box>
              </TwoColumns>
            </Section>

            <SectionHeader title="Products" />
            <ListContextProvider value={productsContext}>
              <ListView
                pagination={false}
                actions={false}
                bulkActionButtons={false}
                empty={<EmptyTable columns={sendoutProductsColumns} />}
              >
                <ResponsiveProductList
                  columns={productColumns.filter((c) => c.label !== 'Status')}
                  onRowClickReturn=""
                />
              </ListView>
            </ListContextProvider>
          </Box>
        </Box>
      </Box>

      <SendoutChat sendoutToken={shareToken} />
    </Box>
  );
}

const FieldWithLabel = (props: { children: JSX.Element; label: string }) => {
  const styles = useLabelStyles();
  return (
    <Labeled
      label={props.label}
      className={styles.root}
      classes={{ label: styles.label, value: styles.value }}
    >
      {props.children}
    </Labeled>
  );
};

const useLabelStyles = makeStyles((theme) => ({
  root: {
    color: '#000',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  value: {
    paddingTop: '0',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'whitesmoke',
    padding: '48px 16px',
  },
  inner: {
    minHeight: '100vh',
    maxWidth: '900px',
    margin: '0 auto',
  },
  content: {},
  header: {
    textAlign: 'center',
    marginBottom: '32px',
  },
  logoSvg: {
    width: '80px',
    height: 'auto',
  },
}));
