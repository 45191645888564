import { Admin, Resource } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';
import authProvider from 'authProvider';
import i18nProvider from 'i18nProvider';
import dataProvider from 'dataProvider';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/theme';
import LoginPage from 'components/LoginPage';
import ProductList from 'components/ProductList';
import ProductEdit from 'components/ProductEdit';
import ContactList from 'components/ContactList';
import ContactEdit from 'components/ContactEdit';
import SendoutList from 'components/SendoutList';
import SendoutEdit from 'components/SendoutEdit';
import SendoutCreate from 'components/SendoutCreate';
import ProductCreate from 'components/ProductCreate';
import ContactCreate from 'components/ContactCreate';
import BrandList from 'components/BrandList';
import BrandCreate from 'components/BrandCreate';
import BrandEdit from 'components/BrandEdit';
import CategoryList from 'components/CategoryList';
import CategoryEdit from 'components/CategoryEdit';
import CategoryCreate from 'components/CategoryCreate';
import CollectionList from 'components/CollectionList';
import CollectionEdit from 'components/CollectionEdit';
import CollectionCreate from 'components/CollectionCreate';
import Dashboard from 'components/Dashboard';
import customRoutes from 'customRoutes';
import UserEdit from 'components/UserEdit';
import { createBrowserHistory as createHistory } from 'history';
import { useObservableCssHeight } from 'utils/hooks';
import columnFilters from 'redux/columnFilters';
import 'styles/index.css';
import MainLayout from 'components/MainLayout';
import navigationHistory from 'redux/navigationHistory';
import ContactsIcon from 'components/Icons/ContactsIcon';
import ProductsIcon from 'components/Icons/ProductsIcon';
import BrandsIcon from 'components/Icons/BrandsIcon';
import CollectionsIcon from 'components/Icons/CollectionsIcon';
import CategoriesIcon from 'components/Icons/CategoriesIcon';
import SendoutsIcon from 'components/Icons/SendoutsIcon';
import SizeList from 'components/SizeList';
import SizeEdit from 'components/SizeEdit';
import SizeCreate from 'components/SizeCreate';
import SizesIcon from 'components/Icons/SizesIcon';
import SeasonList from 'components/SeasonList';
import SeasonEdit from 'components/SeasonEdit';
import SeasonCreate from 'components/SeasonCreate';
import SeasonsIcon from 'components/Icons/SeasonsIcon';
import ColorList from 'components/ColorList';
import ColorEdit from 'components/ColorEdit';
import ColorCreate from 'components/ColorCreate';
import ColorsIcon from 'components/Icons/ColorsIcon';

const history = createHistory();

function App() {
  useObservableCssHeight();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        customReducers={{
          columnFilters,
          navigationHistory,
        }}
        customRoutes={customRoutes}
        loginPage={LoginPage}
        dashboard={Dashboard}
        theme={theme}
        layout={MainLayout}
        history={history}
      >
        <Resource
          name="sendouts"
          list={SendoutList}
          edit={SendoutEdit}
          create={SendoutCreate}
          icon={SendoutsIcon}
        />
        <Resource
          name="products"
          list={ProductList}
          edit={ProductEdit}
          create={ProductCreate}
          icon={ProductsIcon}
        />
        <Resource
          name="contacts"
          list={ContactList}
          edit={ContactEdit}
          create={ContactCreate}
          icon={ContactsIcon}
        />
        <Resource
          name="brands"
          list={BrandList}
          edit={BrandEdit}
          create={BrandCreate}
          icon={BrandsIcon}
        />
        <Resource
          name="collections"
          list={CollectionList}
          edit={CollectionEdit}
          create={CollectionCreate}
          icon={CollectionsIcon}
        />
        <Resource
          name="categories"
          list={CategoryList}
          edit={CategoryEdit}
          create={CategoryCreate}
          icon={CategoriesIcon}
        />
        <Resource
          name="sizes"
          list={SizeList}
          edit={SizeEdit}
          create={SizeCreate}
          icon={SizesIcon}
        />
        <Resource
          name="seasons"
          list={SeasonList}
          edit={SeasonEdit}
          create={SeasonCreate}
          icon={SeasonsIcon}
        />
        <Resource
          name="colors"
          list={ColorList}
          edit={ColorEdit}
          create={ColorCreate}
          icon={ColorsIcon}
        />
        <Resource name="users" edit={UserEdit} />
        <Resource name="sendout-messages" />
      </Admin>
    </ThemeProvider>
  );
}

export default App;
