import SendoutForm from 'components/SendoutForm';
import { CreateProps, SimpleForm, Create, Record } from 'react-admin';
import { ISendout } from 'types/strapi';
import { addOrganization } from 'utils/addOrganization';
import { getTodayPlusTwoWeeks } from 'utils/getTodayPlusTwoWeeks';
import CreateToolbar from './CreateToolbar';

const SendoutCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    const sendout = data as ISendout;
    /**
     *  if the estimatedReturnDate is null or undefined, change it to today + 2 weeks
     */
    if (!sendout.estimatedReturnDate) {
      sendout.estimatedReturnDate = getTodayPlusTwoWeeks();
    }
    return addOrganization(sendout);
  };
  return (
    <Create {...props} transform={transform}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        variant="standard"
        toolbar={<CreateToolbar title="Create sendout" />}
      >
        <SendoutForm />
      </SimpleForm>
    </Create>
  );
};

export default SendoutCreate;
