import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { useRecordContext, useGetOne, TextInput } from 'react-admin';
import { IDpdService } from 'types/dpd';
import * as dpd from 'utils/dpdIntegration';
import _ from 'lodash';
import { IAddress, IContact } from '../../../types/generated/strapi';
import { useForm } from 'react-final-form';
import SectionHeader from './SectionHeader';
import Section from './Section';
import { Search, LocalShipping } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Sendout Shipping
 * This section controls DPD authentication, shipping creation and label
 * view and printing.
 * @constructor
 */
export default function SendoutShipping() {
  const styles = useStyles();
  const record = useRecordContext();
  const form = useForm();
  const address = record.address as IAddress;
  const { data: contact } = useGetOne<IContact>(
    'contacts',
    record.contact?.id || record.contact,
    {
      enabled: !!record.contact,
    }
  );

  // state
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [weight, setWeight] = useState(1);
  const [serviceNetworkCode, setServiceNetworkCode] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [services, setServices] = useState<IDpdService[]>();
  const [dispatchShippingLabel, setDispatchShippingLabel] = useState('');

  const onCreateClick = () => {
    const dpdCredentials = dpd.getDpdCredentials();
    if (dpdCredentials) {
      setOpenCreateDialog(true);
    } else {
      setOpenLoginDialog(true);
    }
  };

  const onLoginDialogClose = () => {
    setOpenLoginDialog(false);
  };

  const onLoginCancelClick = () => {
    setOpenLoginDialog(false);
  };

  const onLoginSubmitClick = () => {
    try {
      dpd.authenticate(accountNumber, username, password);
      setOpenLoginDialog(false);
      setOpenCreateDialog(true);
    } catch (e) {
      alert(e);
    }
  };

  const onCreateDialogClose = () => {
    setOpenLoginDialog(false);
  };

  const onCreateCancelClick = () => {
    setOpenCreateDialog(false);
  };

  const setService = (code: string) => {
    if (!services) throw new Error('No services found');
    const found = services?.filter((s) => s.network.networkCode === code);
    const service = found.length ? found[0] : undefined;
    if (!service) throw new Error('Invalid service id');
    setServiceNetworkCode(service.network.networkCode);
    setServiceName(
      `${service.product.productDescription} - ${service.service.serviceDescription}`
    );
  };

  const onCreateSubmitClick = async () => {
    const credentials = dpd.getDpdCredentials();
    if (!credentials) throw new Error('Unauthorized');
    if (!contact) throw new Error('Contact undefined');
    if (!address.postcode) throw new Error('Address.postcode undefined');
    if (!address.city) throw new Error('Address.city undefined');
    if (!address.line1) throw new Error('Address.line1 undefined');

    try {
      const result = await dpd.createShipment(
        credentials,
        weight,
        serviceNetworkCode,
        `${contact.firstName} ${contact.lastName}`,
        contact.phone ?? null,
        contact.company ?? null,
        'GB',
        address.postcode,
        address.city,
        address.line1,
        address.line2,
        contact.email,
        contact.phone,
        null,
        `Sendout #${record.id}`
      );

      form.change('dispatchService', `DPD: ${serviceName}`);
      form.change('dispatchShipmentId', result.shipmentId);
      form.change('dispatchTrackingCode', result.parcelNumber);
      form.submit();

      setOpenCreateDialog(false);
    } catch (error) {
      if (String(error) === 'Error: Unauthorized') {
        dpd.removeExpiredCredentials();
        setOpenCreateDialog(false);
        setOpenLoginDialog(true);
      } else {
        alert('Failed to create a shipment');
        console.error(error);
      }
    }
  };

  // dpd doesn't accept their own html labels, so we need to scale it
  const patchLabel = (label: string) => {
    const css = `<style>
      html {
         transform: scale(1.1);
         padding-top: 5%;
         padding-left: 5%;
      }
    </style>`;
    return css + label;
  };

  const onViewClick = async () => {
    const credentials = dpd.getDpdCredentials();
    if (!credentials) {
      dpd.removeExpiredCredentials();
      setOpenCreateDialog(false);
      setOpenLoginDialog(true);
      return;
    }

    const shipmentId = form.getFieldState('dispatchShipmentId')?.value || '';
    let label;
    try {
      label = await dpd.getShippingLabel(credentials, shipmentId);
      label = patchLabel(label); // needs scaling
      if (!label) throw new Error(`Unable to get label for #${shipmentId}`);
    } catch (error) {
      if (String(error) === 'Error: Unauthorized') {
        dpd.removeExpiredCredentials();
        setOpenCreateDialog(false);
        setOpenLoginDialog(true);
        return;
      } else {
        alert('Failed to retrieve label');
        console.error(error);
        return;
      }
    }

    // success
    setDispatchShippingLabel(label);
    setOpenViewDialog(true);
  };

  const onViewDialogClose = () => {
    setOpenViewDialog(false);
  };

  const fetchServices = async () => {
    const credentials = dpd.getDpdCredentials();
    if (!credentials) throw new Error('Unauthorized');
    let services = await dpd.getServices(
      credentials,
      weight,
      record.address.line1,
      record.address.line2,
      record.address.postcode,
      record.address.city,
      'GB'
    );
    services = _.sortBy(services, (x) => x.product.productDescription);
    setServices(services);
    // TODO: filter out unwanted services
  };

  const onPrintClick = () => {
    if (!dispatchShippingLabel) return;
    const label = document.getElementById('shipping-label') as HTMLFrameElement;
    if (label && label.contentWindow) label.contentWindow.print();
  };

  // render
  return (
    <div>
      <SectionHeader title="Shipping">
        <div>
          {
            // create
            !form.getFieldState('dispatchShipmentId')?.value && (
              <Button
                variant="contained"
                color="secondary"
                endIcon={<LocalShipping />}
                onClick={onCreateClick}
              >
                Create Label
              </Button>
            )
          }
          {
            // view
            form.getFieldState('dispatchShipmentId')?.value && (
              <Button
                variant="contained"
                color="secondary"
                endIcon={<Search />}
                onClick={onViewClick}
              >
                View Label
              </Button>
            )
          }
          {/*{*/}
          {/*  // print*/}
          {/*  form.getFieldState('dispatchShipmentId')?.value &&*/}
          {/*  <Button variant="contained" color="secondary" endIcon={<Print />}>Print</Button>*/}
          {/*}*/}
        </div>
      </SectionHeader>

      <Section>
        <Box>
          <TextInput
            source="dispatchService"
            variant="standard"
            record={record}
          />
          <TextInput
            source="dispatchTrackingCode"
            variant="standard"
            record={record}
          />
          <TextInput
            source="dispatchShipmentId"
            variant="standard"
            label="Shipment ID"
            record={record}
          />

          {/* ----------- AUTHENTICATION ------------------ */}
          <Dialog
            open={openLoginDialog}
            onClose={onLoginDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>DPD Login</DialogTitle>
            <DialogContent>
              <TextField
                label="Account number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
              <br />
              <br />
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br />
              <br />
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onLoginCancelClick}>
                Cancel
              </Button>
              <Button color="primary" onClick={onLoginSubmitClick}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------- CREATE LABEL ------------------ */}
          <Dialog
            open={openCreateDialog}
            onClose={onCreateDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>Create Shipping Label</DialogTitle>
            <DialogContent>
              <TextField
                label="Weight (kg)"
                type="number"
                inputProps={{ min: 0.1 }}
                value={weight}
                onChange={(e) => setWeight(Number(e.target.value))}
              />
              <br />
              <br />
              <InputLabel htmlFor="service">
                <small>Service</small>
              </InputLabel>
              <Select
                native
                label="Service"
                fullWidth
                disabled={weight <= 0}
                value={serviceNetworkCode}
                onChange={(e: any) => setService(e.target.value)}
                onClick={fetchServices}
                inputProps={{
                  name: 'service',
                  id: 'service',
                }}
              >
                <option aria-label="None" value="" />
                {services &&
                  services.map((x, i) => (
                    <option key={i} value={x.network.networkCode}>
                      {`${x.product.productDescription} - ${x.service.serviceDescription}`}
                    </option>
                  ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onCreateCancelClick}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={onCreateSubmitClick}
                disabled={!weight || !serviceNetworkCode}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------- VIEW LABEL ------------------ */}

          <Dialog
            open={openViewDialog}
            onClose={onViewDialogClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Shipping Label</DialogTitle>
            <DialogContent>
              <iframe
                srcDoc={dispatchShippingLabel}
                title="Shipping label"
                id="shipping-label"
                name="shipping-label"
                className={styles.shippingLabelViewFrame}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onPrintClick}>
                Print
              </Button>
              <Button color="primary" onClick={onViewDialogClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Section>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  shippingLabelViewFrame: {
    width: '100%',
    border: '1px solid lightgray',
    minHeight: '500px',
  },
}));
