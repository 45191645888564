import { Location } from 'history';

export default function navigationHstory(
  state: NavigationHistory[] = [],
  action: any
): NavigationHistory[] {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      const location: Location = action.payload.location;
      switch (action.payload.action) {
        case 'POP':
          return state.slice(0, state.length - 1);
        case 'PUSH':
          return [...state, location];
        case 'REPLACE':
          return [...state.slice(0, state.length - 1), location];
        default:
          return state;
      }
    default:
      return state;
  }
}

export interface NavigationHistory extends Location {}
