import { FunctionField } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import Dot from './Dot';

export default function DotField(props: {
  source: string;
  title: string;
  color: string;
}) {
  const classes = useStyles();

  return (
    <FunctionField
      source={props.source}
      render={() => (
        <Box className={classes.statusWrapper} title={props.title}>
          <Dot className={classes.statusDot} color={props.color} />
        </Box>
      )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  statusDot: {
    height: '0.6rem',
    width: '0.6rem',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '0.5rem',
  },
  statusWrapper: {
    whiteSpace: 'nowrap',
  },
}));
