import { Box } from '@material-ui/core';
import { useRecordContext, useGetOne } from 'react-admin';
import { IContact, ISendout } from '../../../types/generated/strapi';
import { getId } from 'utils/getId';

/**
 * Provide the HTML template needed to print
 * Note: styles MUST be inline for this to work
 */
export default function ContactPrint() {
  const sendout = useRecordContext<ISendout>();
  const contactId = getId(sendout.contact);
  const { data: contact } = useGetOne<IContact>('contacts', contactId || 0, {
    enabled: !!contactId,
  });
  if (!contact) return null;

  const fieldsToPrint = {
    Name: (contact.firstName + ' ' + contact.lastName).trim(),
    Company: contact.company,
    'Address Line 1': sendout.address?.line1,
    'Address Line 2': sendout.address?.line2,
    City: sendout.address?.city,
    Country: sendout.address?.country,
    Postcode: sendout.address?.postcode,
    'Contact Number': contact.phone,
  };

  const fields = Object.entries(fieldsToPrint).filter(([, value]) => !!value);

  return (
    <Box
      id="contact-print"
      style={{
        display: 'none',
        backgroundColor: 'white',
        maxWidth: '7cm',
        textAlign: 'left',
        fontFamily: 'sans-serif',
        fontSize: '12px',
        textTransform: 'uppercase',
      }}
    >
      {fields.map(([label, value]) => (
        <Box key={label}>
          <strong>{label}:</strong> {value}
        </Box>
      ))}
    </Box>
  );
}
