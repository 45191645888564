import { Typography, Box, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as strapi from '@casbah/strapi-fetch';
import { getJwt } from 'utils/getJwt';
import { makeStyles } from '@material-ui/core/styles';
import themeBase from 'styles/theme-base';
import { getStrapiURL } from 'utils/getStrapiUrl';

export default function DashboardCountCard(props: {
  title: string;
  subtitle?: (count: number) => string;
  emptyTitle: string;
  emptySubtitle?: string;
  link: string;
  linkLabel: string;
  icon: React.ReactElement;
  fetchUrl: string;
  variant: 'withCountOnRight' | 'default';
}) {
  const { status, loading, count, error } = useDashboardCountCardData({
    url: props.fetchUrl,
  });

  const styles = useStyles({
    status,
    variant: props.variant,
  });

  if (loading) {
    return (
      <Box className={styles.root}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.root}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (props.variant === 'withCountOnRight') {
    return (
      <Box className={styles.root}>
        <Box className={styles.iconColumn}>
          <Box className={styles.iconWrapper}>{props.icon}</Box>
        </Box>
        <Box className={styles.contentColumn}>
          <Box className={styles.title}>
            {count > 0 ? props.title : props.emptyTitle}
          </Box>
          {count > 0 && (
            <Link className={styles.linkLabel} to={props.link}>
              {props.linkLabel}
            </Link>
          )}
        </Box>
        <Box className={styles.rightColumn}>
          <Box className={styles.count}>{count}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.iconColumn}>
        <Box className={styles.iconWrapper}>{props.icon}</Box>
      </Box>
      <Box className={styles.contentColumn}>
        <Box className={styles.title}>
          {count > 0 ? props.title : props.emptyTitle}
        </Box>
        <Box className={styles.subtitle}>
          {count > 0 && props.subtitle
            ? props.subtitle(count)
            : props.emptySubtitle}
        </Box>
      </Box>
      <Box className={styles.rightColumn}>
        {count > 0 && (
          <Link className={styles.linkLabel} to={props.link}>
            {props.linkLabel}
          </Link>
        )}
      </Box>
    </Box>
  );
}

const useDashboardCountCardData = (props: { url: string }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;
    strapi.get<number>(getStrapiURL(props.url), getJwt()).then((res) => {
      if (!mounted) return;
      if (res.ok) {
        setCount(res.payload as number);
        setLoading(false);
      } else {
        setLoading(false);
        setError(res.messages.join(', ') || 'Unknown error');
      }
    });
    return () => {
      mounted = false;
    };
  }, [props.url]);

  return {
    status: count > 0 ? 'warning' : 'success',
    loading,
    count,
    error,
  } as const;
};

type StyleProps = {
  variant: 'withCountOnRight' | 'default';
  status: 'warning' | 'success' | 'error';
};

const gradientColors = {
  withCountOnRight: {
    warning: '#fdf6eb',
    success: '#ecfcf3',
    error: '#fcecec',
  },
  default: {
    warning: 'white',
    success: 'white',
    error: 'white',
  },
};

const iconBorderColors = {
  withCountOnRight: {
    warning: '#fbc471',
    success: '#cde6fe',
    error: '#fcecec',
  },
  default: {
    warning: 'rgb(255, 246, 233)',
    success: 'rgb(214, 247, 231)',
    error: 'rgb(255, 246, 233)',
  },
};

const iconColors = {
  withCountOnRight: {
    warning: '#017EFA',
    success: '#017EFA',
    error: '#017EFA',
  },
  default: {
    warning: '#FFA827',
    success: '#30D988',
    error: '#fcecec',
  },
};

const iconBackgroundColors = {
  withCountOnRight: {
    warning: 'rgb(204, 229, 254)',
    success: 'rgb(204, 229, 254)',
    error: 'rgb(204, 229, 254)',
  },
  default: {
    warning: 'rgb(255, 246, 233)',
    success: 'rgb(214, 247, 231)',
    error: 'rgb(255, 246, 233)',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '1.5rem 2rem',
    borderRadius: '2px',
    boxShadow: ({ variant }: StyleProps) =>
      variant === 'withCountOnRight' ? themeBase.shadows[1] : 'none',
    background: ({ status, variant }: StyleProps) =>
      `linear-gradient(135deg, white 48%, ${gradientColors[variant][status]} 80%)`,
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
  iconColumn: {
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '1.35rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.75rem',
    height: '2.75rem',
    fontSize: '24px',
    backgroundColor: ({ variant, status }: StyleProps) =>
      iconBackgroundColors[variant][status],
    borderRadius: '50%',
    padding: '8px',
    border: ({ status, variant }: StyleProps) =>
      `2px solid ${iconBorderColors[variant][status]}`,
    color: ({ variant, status }: StyleProps) => iconColors[variant][status],
    [theme.breakpoints.down('sm')]: {
      width: '2.25rem',
      height: '2.25rem',
      fontSize: '20px',
    },
  },
  contentColumn: {
    marginRight: '1rem',
  },
  count: {
    fontSize: '2.25rem',
    lineHeight: 1,
    marginRight: '4rem',
    fontWeight: 'lighter',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
      marginRight: '2rem',
    },
  },
  title: {
    fontSize: '1rem',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  linkLabel: {
    fontSize: '1rem',
    color: 'rgb(0, 102, 204)',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: '1rem',
    color: '#90929e',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  rightColumn: {
    marginLeft: 'auto',
  },
}));
