import cookies from 'utils/cookies';

import type { IUser } from 'types/strapi';
import type { AuthProvider, UserIdentity } from 'react-admin';
import { getStrapiURL } from 'utils/getStrapiUrl';

const authProvider: AuthProvider = {
  async login({ username, password }: { username: string; password: string }) {
    const response = await fetch(getStrapiURL('/auth/local'), {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ 'content-type': 'application/json' }),
    });
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const data = await response.json();
    const user: IUser = data.user;
    const organizations = user.organizations;

    // Update cookies
    cookies.setCookie('token', data.jwt, 30);
    cookies.setCookie('role', user.role?.name || '', 30);
    if (organizations.length) {
      cookies.setCookie('organization', organizations[0].id.toString(), 30);
    }
  },

  logout: () => {
    cookies.deleteCookie('token');
    cookies.deleteCookie('role');
    cookies.deleteCookie('organization');
    return Promise.resolve();
  },

  checkAuth: () =>
    cookies.getCookie('token')
      ? Promise.resolve()
      : Promise.reject({ message: false }),

  checkError: ({ status }: { status: number }) => {
    if (status === 401 || status === 403) {
      cookies.deleteCookie('token');
      cookies.deleteCookie('role');
      cookies.deleteCookie('organization');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  async getIdentity() {
    const token = cookies.getCookie('token');
    if (!token) return Promise.reject();

    try {
      const response = await fetch(getStrapiURL('/users/me'), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        authProvider.logout(false);
      }
      const strapiUser: IUser = await response.json();
      const { id, username, organizations, role } = strapiUser;

      // Update cookies
      cookies.setCookie('role', role?.name || '', 30);
      if (organizations.length) {
        cookies.setCookie('organization', organizations[0].id.toString(), 30);
      }

      const reactAdminUser: UserIdentity = {
        id,
        fullName: username,
        organizations,
      };
      return reactAdminUser;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const role = cookies.getCookie('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
