import Create from 'components/Create';
import ProductForm from 'components/ProductForm';
import { CreateProps, SimpleForm, Record } from 'react-admin';
import { IProduct } from 'types/strapi';
import { addOrganization } from 'utils/addOrganization';
import { getNewProductBarcode } from 'utils/getNewProductBarcode';
import CreateToolbar from './CreateToolbar';

export default function ProductCreate(props: CreateProps) {
  return (
    <Create {...props} transform={transform}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        variant="standard"
        toolbar={<CreateToolbar title="Create product" />}
      >
        <ProductForm />
      </SimpleForm>
    </Create>
  );
}

const transform = async (data: Record) => {
  const product = data as IProduct;

  if (!product.barcode) {
    product.barcode = await getNewProductBarcode();
  }
  return addOrganization(product);
};
