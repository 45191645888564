import { SimpleList, FunctionField, Datagrid, TextField } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { IContact } from 'types/strapi';
import { ResponsiveListProps } from 'types/ResponsiveListProps';

interface ResponsiveContactListProps extends ResponsiveListProps {
  onRowClick?: (product: IContact) => void;
  onRowClickReturn: 'edit' | 'show' | 'expand' | 'toggleSelection' | '';
}

const ResponsiveContactList = (props: ResponsiveContactListProps) => {
  const { onRowClick, onRowClickReturn, columns, ...rest } = props;

  const handleRowClick = (
    _id: any,
    _resource: any,
    contact: any
  ): ResponsiveContactListProps['onRowClickReturn'] => {
    onRowClick && onRowClick(contact);

    return onRowClickReturn;
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Datagrid rowClick={handleRowClick} {...rest}>
          {columns.map((c) => {
            switch (c.source) {
              case 'name':
                return c.hide ? null : (
                  <FunctionField<IContact>
                    label={c.label}
                    source={c.source}
                    render={(record) =>
                      `${record?.firstName} ${record?.lastName}`
                    }
                    key={c.source}
                    sortBy="firstName"
                  />
                );
              case 'addresses':
                return c.hide ? null : (
                  <FunctionField<IContact>
                    label={c.label}
                    source={c.source}
                    render={(record) => getAddress(record)}
                    key={c.source}
                  />
                );
              default:
                return c.hide ? null : (
                  <TextField label={c.label} source={c.source} key={c.source} />
                );
            }
          })}
        </Datagrid>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SimpleList linkType="edit" primaryText={<Contact />} />
      </Box>
    </>
  );
};

const Contact = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.contactInfoItem}>
        <AccountCircleIcon />
        <Box sx={{ ml: 1 }}>
          <FunctionField<IContact>
            render={(record) =>
              record ? `${record.firstName} ${record.lastName}` : ''
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  contactInfoItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const getAddress = (record: IContact | undefined) => {
  const address = record?.addresses[0];

  if (!address) return '';

  return (
    Object.values(address)
      // Remove null empty, undef
      .filter((s) => s)
      .join(', ')
  );
};

export default ResponsiveContactList;
