import { useGetOne } from 'react-admin';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IProduct } from 'types/strapi';
import { ReactComponent as ClosedBoxIcon } from 'assets/svg/closed-box.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    padding: '0 3rem',
    textAlign: 'center',
    marginBottom: '20px',
  },
  pageButton: {
    maxWidth: '245px',
    width: '100%',
    marginBottom: '24px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '32px',
  },
  subtitle: {
    width: '160px',
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '1rem',
    marginRight: '74px',
  },
}));

interface ProductInSendouProps {
  product: IProduct;
}

const ProductInSendout = (props: ProductInSendouProps) => {
  const { product } = props;
  const classes = useStyles();

  const contactId = product.sendout?.contact as never as number;
  const { data: contact } = useGetOne('contacts', contactId);

  return (
    <Box className={classes.root}>
      <span className={classes.title}>
        This product belongs to the following sendout:
      </span>
      <Box sx={{ marginBottom: '40px', display: 'flex' }}>
        <ClosedBoxIcon style={{ width: '62px', height: '100%' }} />
        <Box sx={{ marginLeft: '16px' }}>
          <Box>Sendout #{product?.sendout?.id}</Box>
          <Box>
            To: {contact?.firstName} {contact?.lastName}
          </Box>
          <Box>Status: {product?.sendout?.status}</Box>
        </Box>
      </Box>
      <Box className={classes.buttonsWrapper}>
        <span className={classes.subtitle}>
          Do you want to mark this sendout as returned?
        </span>
        {product.sendout?.status !== 'Returned' && (
          <Button
            className={classes.pageButton}
            component={Link}
            to={{
              pathname: `sendouts/${product.sendout?.id}`,
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Mark sendout as returned
          </Button>
        )}
        <Button
          className={classes.pageButton}
          component={Link}
          to={{
            pathname: `sendouts/${product.sendout?.id}`,
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Open sendout
        </Button>
        <Button
          className={classes.pageButton}
          component={Link}
          to={{
            pathname: `products/${product.id}`,
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Open Product
        </Button>
      </Box>
    </Box>
  );
};

export default ProductInSendout;
