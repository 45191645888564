import {
  Typography,
  Box,
  IconButton,
  Menu,
  useMediaQuery,
} from '@material-ui/core';
import { useState } from 'react';
import React from 'react';
import BackButton from './BackButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import variables from 'styles/variables';
import { makeStyles } from '@material-ui/core/styles';

interface ToolbarProps {
  title: string;
  children?: React.ReactNode;
}

export default function Toolbar(props: ToolbarProps) {
  const { children, title } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = useStyles();
  const validChildrens = React.Children.toArray(children);
  const isDesktopToolbar = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );
  const handleClick = (event: any) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            [variables.appBar.breakpointDesktop]: 'block',
          },
        }}
      >
        <Box className={styles.rootDesktop}>
          <Box className={styles.buttonsWrapperDesktop}>
            <Box>
              <BackButton />
            </Box>
            <Box className={styles.buttonsDesktop}>
              {validChildrens.map((child, index) => (
                <Box
                  component="span"
                  key={index}
                  className={styles.buttonDesktop}
                >
                  {child}
                </Box>
              ))}
            </Box>
          </Box>

          <Typography variant="h5" color="inherit">
            {title}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'block',
            [variables.appBar.breakpointDesktop]: 'none',
          },
        }}
      >
        <Box className={styles.rootMobile}>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>

          <>
            <IconButton
              color="inherit"
              aria-label="more"
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleClick}
              component="button"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              open={open && !isDesktopToolbar}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              onClose={handleClose}
              className={styles.menuMobile}
            >
              <Box>
                <BackButton />
              </Box>

              {validChildrens.map((child, index) => (
                <Box key={index}>{child}</Box>
              ))}
            </Menu>
          </>
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  rootDesktop: {
    width: '100%',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  buttonsWrapperDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  buttonsDesktop: {
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
  },
  buttonDesktop: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  rootMobile: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    top: 0,
    left: 0,
    width: '100%',
    height: variables.appBar.mobileHeight,
    backgroundColor: 'black',
    padding: '1rem 1rem 1rem 1.5rem',
    color: 'white',
    zIndex: 1000,
  },
  menuMobile: {
    '& button, & a': {
      display: 'flex',
      width: '100%',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontSize: 17.1,
      fontFamily: 'inherit',
      padding: '0.5rem 1.25rem',
    },
  },
}));
