import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import variables from 'styles/variables';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/AppState';

export default function BackButton() {
  const classes = useStyles();
  const history = useHistory();
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );
  const navigationHistory = useSelector(
    (state: AppState) => state.navigationHistory
  );
  const isPrevPageLogin =
    navigationHistory[navigationHistory.length - 3]?.pathname === '/login';

  return (
    <Button
      variant="text"
      onClick={() => history.goBack()}
      startIcon={isDesktopButton ? <KeyboardBackspaceIcon /> : null}
      classes={classes}
      disabled={navigationHistory.length === 0 || isPrevPageLogin}
      disableRipple
    >
      Back
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up(variables.appBar.breakpointDesktop)]: {
      color: '#1661D1',
      justifyContent: 'flex-start',
      lineHeight: '1.5',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  label: {
    [theme.breakpoints.up(variables.appBar.breakpointDesktop)]: {
      textTransform: 'none',
      fontSize: 16,
    },
  },
}));
