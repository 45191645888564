export const sendoutColumns = [
  {
    label: 'Created',
    source: 'created_at',
    hide: false,
  },
  {
    label: 'Contact',
    source: 'contact',
    hide: false,
  },
  {
    label: 'Status',
    source: 'status',
    hide: false,
  },
  {
    label: 'Products',
    source: 'products',
    hide: false,
  },
  {
    label: 'Messages',
    source: 'messages',
    hide: false,
  },
  {
    label: 'Return date',
    source: 'estimatedReturnDate',
    hide: false,
  },
];
