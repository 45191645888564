import { IColumnToImport, IColumnValueReport } from './types';

const relationOne =
  (collection: any[], collectionFieldName: string) =>
  (column: IColumnValueReport): IColumnValueReport => {
    const normalized = normalize(column.columnValueRaw);

    if (normalized.length === 0) {
      // Remove from post
      column.columnValueStrapi = undefined;
      return column;
    }
    if (normalized.length === 1) {
      // Validate
      validateOneName({
        column,
        collection,
        name: normalized[0],
        collectionFieldName,
      });
      return column;
    }

    // Take first
    const first = normalized.shift() as string;

    // Validate first
    validateOneName({ column, collection, name: first, collectionFieldName });

    // Push others to errors
    normalized.forEach((c, i) => {
      column.values.push({
        value: c,
        invalidType: 'oneOnly',
      });
    });

    return column;
  };

const relationMany =
  (collection: any[], collectionFieldName: string) =>
  (column: IColumnValueReport): IColumnValueReport => {
    const normalized = normalize(column.columnValueRaw);

    if (normalized.length === 0) {
      // Remove from post
      column.columnValueStrapi = undefined;
      return column;
    }

    // Validate
    validateManyNames({
      column,
      collection,
      names: normalized,
      collectionFieldName,
    });

    return column;
  };

const notRequired = (column: IColumnValueReport): IColumnValueReport => {
  if (!column.columnValueRaw) {
    // Remove from post
    column.columnValueStrapi = undefined;
    return column;
  }

  column.values.push({
    value: column.columnValueRaw,
  });
  column.columnValueStrapi = column.columnValueRaw;

  return column;
};

const required = (column: IColumnValueReport): IColumnValueReport => {
  if (!column.columnValueRaw) {
    // Add it as invalid
    column.values.push({
      value: '',
      invalidType: 'required',
    });

    // Remove from post
    column.columnValueStrapi = undefined;
    return column;
  }

  column.values.push({
    value: column.columnValueRaw,
  });
  column.columnValueStrapi = column.columnValueRaw;

  return column;
};

// The softest solution anystring@anystring.anystring
const regexEmail = /\S+@\S+\.\S+/;
const normalizeEmail = (email: string) => email.trim().toLowerCase();
const email = (column: IColumnValueReport): IColumnValueReport => {
  if (!column.columnValueRaw) {
    // Remove from post
    column.columnValueStrapi = undefined;
    return column;
  }

  // Not valid email
  if (!regexEmail.test(column.columnValueRaw)) {
    // Add it as invalid
    column.values.push({
      value: column.columnValueRaw,
      invalidType: 'notCorrectEmail',
    });
    return column;
  }

  const normalizedEmail = normalizeEmail(column.columnValueRaw);

  // Valid email
  column.values.push({
    value: normalizedEmail,
  });
  column.columnValueStrapi = normalizedEmail;

  return column;
};

const requiredEmail = (column: IColumnValueReport): IColumnValueReport => {
  if (!column.columnValueRaw) {
    // Add it as invalid
    column.values.push({
      value: '',
      invalidType: 'required',
    });

    // Remove from post
    column.columnValueStrapi = undefined;
    return column;
  }

  // Not valid email
  if (!regexEmail.test(column.columnValueRaw)) {
    // Add it as invalid
    column.values.push({
      value: column.columnValueRaw,
      invalidType: 'notCorrectEmail',
    });

    // Remove from post
    column.columnValueStrapi = undefined;
    return column;
  }

  const normalizedEmail = normalizeEmail(column.columnValueRaw);

  // Valid email
  column.values.push({
    value: normalizedEmail,
  });
  column.columnValueStrapi = normalizedEmail;

  return column;
};

const normalize = (value: string | undefined) =>
  value
    ? value
        .split(',')
        .map((c) => c.trim().toLowerCase())
        .filter((c) => c)
    : [];

const validateOneName = ({
  column,
  collection,
  collectionFieldName,
  name,
}: {
  column: IColumnValueReport;
  collection: any[];
  collectionFieldName: string;
  name: string;
}) => {
  // Validate first
  const exist = collection.find(
    (c) => c[collectionFieldName].trim().toLowerCase() === name
  );

  // Its found by name in collection
  if (exist) {
    // Add it as valid
    column.values.push({
      value: name,
    });
    // Add to post id
    column.columnValueStrapi = exist.id;
    // Its not found by name in collection
  } else {
    // Add it as invalid
    column.values.push({
      value: name,
      invalidType: 'notCorrectName',
    });
    // Remove from post
    column.columnValueStrapi = undefined;
  }
};

const validateManyNames = ({
  column,
  collection,
  collectionFieldName,
  names,
}: {
  column: IColumnValueReport;
  collection: any[];
  collectionFieldName: string;
  names: string[];
}) => {
  column.columnValueStrapi = [];

  names.forEach((name) => {
    // Validate first
    const exist = collection.find(
      (c) => c[collectionFieldName].trim().toLowerCase() === name
    );

    // Its found by name in collection
    if (exist) {
      // Add it as valid
      column.values.push({
        value: name,
      });
      // Add to post id
      Array.isArray(column.columnValueStrapi) &&
        column.columnValueStrapi.push(exist.id as number);
      // Its not found by name in collection
    } else {
      // Add it as invalid
      column.values.push({
        value: name,
        invalidType: 'notCorrectName',
      });
    }
  });
};

const getValidation = (field: IColumnToImport) => {
  const {
    collectionFieldNameToValidateWith = '',
    collectionEntries = [],
    validationRule,
  } = field;

  switch (validationRule) {
    case 'required':
      return required;
    case 'requiredEmail':
      return requiredEmail;
    case 'email':
      return email;
    case 'relationOne':
      // if (!collectionEntries?.length || !collectionFieldNameToValidateWith) {
      //   throw new Error(
      //     `Column ${name} required field name and collection entries`
      //   );
      // }
      return relationOne(collectionEntries, collectionFieldNameToValidateWith);
    case 'relationMany':
      // if (!collectionEntries?.length || !collectionFieldNameToValidateWith) {
      //   throw new Error(
      //     `Column ${name} required field name and collection entries`
      //   );
      // }
      return relationMany(collectionEntries, collectionFieldNameToValidateWith);
    default:
      return notRequired;
  }
};

export default getValidation;
