import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import JsBarcode from 'jsbarcode';

const Barcode = (props: { value?: string; style?: React.CSSProperties }) => {
  const classes = useStyles();

  const barcodeEl = useRef<SVGSVGElement>(null);

  const [barcodeString, setBarcodeString] = useState('');
  const [isValid, setIsValid] = useState(true);

  const renderBarcode = useCallback(() => {
    JsBarcode(barcodeEl.current, barcodeString, {
      ...barcodeOptions,
      valid: (valid) => {
        setIsValid(valid);
      },
    });
  }, [barcodeString]);

  const errorMessage = useMemo(() => {
    if (barcodeString && !isValid) return 'Barcode is invalid';
    return '';
  }, [barcodeString, isValid]);

  useEffect(() => {
    setBarcodeString(props.value || '');
  }, [props.value]);

  useEffect(() => {
    renderBarcode();
  });

  return (
    <div className={classes.wrapper} style={props.style}>
      <svg
        ref={barcodeEl}
        className={classes.barcode}
        style={{ display: isValid ? 'block' : 'none' }}
      />
      {errorMessage && <pre style={{ color: 'crimson' }}>{errorMessage}</pre>}
    </div>
  );
};

export default Barcode;

const barcodeOptions: JsBarcode.Options = {
  width: 2,
  height: 40,
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: '100%',
  },
  barcode: {
    display: 'block',
    pointerEvents: 'none',
    userSelect: 'none',
  },
});
