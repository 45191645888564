export function printHtmlElement(id: string) {
  var target = document.getElementById(id) as any;
  var iframe = document.createElement('iframe') as any;
  iframe.domain = document.domain;
  document.body.appendChild(iframe);
  iframe.contentDocument.write(`
      <style>
        @media print {
          @page { margin: 0; }
          body { margin: 0.5cm; }
          svg { max-width: 100% }
        }
      </style>
    `);
  iframe.contentDocument.write(target.outerHTML);
  target = iframe.contentDocument.getElementById(id) as any;
  target.style.display = 'block';
  iframe.contentWindow.print();
  iframe.parentNode.removeChild(iframe);
  window.focus();
}
