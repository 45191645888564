import { ListActionsProps } from 'react-admin';
import ImportCsv from 'components/ImportCsv';
import { IColumnToImport } from 'components/ImportCsv/types';

interface ContactCsvColumns extends IColumnToImport {}

const ContactImportCsv = (props: ListActionsProps) => {
  function getColumnsToImport(): Promise<ContactCsvColumns[]> {
    return Promise.resolve([
      {
        csvColumnName: 'firstName',
        path: 'firstName',
      },
      {
        csvColumnName: 'lastName',
        path: 'lastName',
        validationRule: 'required',
      },
      {
        csvColumnName: 'email',
        path: 'email',
        validationRule: 'requiredEmail',
      },
      {
        csvColumnName: 'phone',
        path: 'phone',
      },
      {
        csvColumnName: 'company',
        path: 'company',
      },
      {
        csvColumnName: 'role',
        path: 'role',
      },
      {
        csvColumnName: 'line1',
        path: 'addresses.0.line1',
      },
      {
        csvColumnName: 'line2',
        path: 'addresses.0.line2',
      },
      {
        csvColumnName: 'country',
        path: 'addresses.0.country',
      },
      {
        csvColumnName: 'city',
        path: 'addresses.0.city',
      },
      {
        csvColumnName: 'postcode',
        path: 'addresses.0.postcode',
      },
      {
        csvColumnName: 'notes',
        path: 'notes',
      },
    ]);
  }

  return (
    <ImportCsv
      listActionProps={props}
      getColumnsToImport={getColumnsToImport}
      labels={{ summary: 'contacts' }}
    />
  );
};

export default ContactImportCsv;
