import ListImageField from 'components/ListImageField';
import { Box } from '@material-ui/core';
import { Datagrid, TextField, SimpleList } from 'react-admin';
import { IProduct } from 'types/strapi';
import { ResponsiveListProps } from 'types/ResponsiveListProps';
import ProductMobile from './ProductMobile';
import Colors from './Colors';
import ProductStatusField from './ProductStatusField';
import EmptyTableCell from 'components/EmptyTableCell';
import SendoutProductReturnedField from './SendoutProductReturnedField';

interface ResponsiveContactListProps extends ResponsiveListProps {
  onRowClick?: (product: IProduct) => void;
  onRowClickReturn: 'edit' | 'show' | 'expand' | 'toggleSelection' | '';
}

const ResponsiveProductList = (props: ResponsiveContactListProps) => {
  const { onRowClick, onRowClickReturn, columns, ...rest } = props;

  const handleRowClick = (
    product: any
  ): ResponsiveContactListProps['onRowClickReturn'] => {
    onRowClick?.(product);
    return onRowClickReturn;
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Datagrid
          rowClick={(_id, _basePath, product) => handleRowClick(product)}
          {...rest}
        >
          {columns.map((c) => {
            switch (c.source) {
              case 'images':
                return c.hide ? null : (
                  <ListImageField
                    label={c.label}
                    source={c.source}
                    sortable={false}
                    key={c.source}
                  />
                );
              case 'colors':
                return c.hide ? null : (
                  <Colors
                    label={c.label}
                    source={c.source}
                    sortable={false}
                    key={c.source}
                  />
                );
              case 'status':
                return c.hide ? null : (
                  <ProductStatusField
                    {...rest}
                    label={c.label}
                    source={c.source}
                    sortable={false}
                    key={c.source}
                  />
                );
              case 'returned':
                return c.hide ? null : (
                  <SendoutProductReturnedField
                    {...rest}
                    label={c.label}
                    source={c.source}
                    sortable={false}
                    key={c.source}
                  />
                );
              default:
                return c.hide ? null : c.source ? (
                  <TextField label={c.label} source={c.source} key={c.source} />
                ) : (
                  <EmptyTableCell />
                );
            }
          })}
        </Datagrid>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SimpleList
          linkType={onRowClickReturn || false}
          primaryText={<ProductMobile onItemClick={handleRowClick} />}
        />
      </Box>
    </>
  );
};

export default ResponsiveProductList;
