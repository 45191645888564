export const productColumns = [
  {
    label: 'Name',
    source: 'name',
    hide: false,
  },
  {
    label: 'Code',
    source: 'productCode',
    hide: false,
  },
  {
    label: 'Barcode',
    source: 'barcode',
    hide: false,
  },
  {
    label: 'Photos',
    source: 'images',
    hide: false,
  },
  {
    label: 'Brand',
    source: 'brand.name',
    hide: false,
  },
  {
    label: 'Size',
    source: 'size.name',
    hide: false,
  },
  {
    label: 'Colors',
    source: 'colors',
    hide: false,
  },
  {
    label: 'Season',
    source: 'season.name',
    hide: true,
  },
  {
    label: 'Status',
    source: 'status',
    hide: false,
  },
  {
    label: 'Archived',
    source: 'archived',
    hide: true,
  },
];
