import { Record } from 'react-admin';
import cookies from 'utils/cookies';

export function addOrganization(data: Record) {
  const organization = cookies.getCookie('organization');
  return {
    ...data,
    organization,
  };
}
