import { Box } from '@material-ui/core';
import {
  FunctionField,
  Record,
  TextField,
  useRecordContext,
  useGetMany,
} from 'react-admin';
import Barcode from './Barcode';

/**
 * Provide the HTML template needed to print
 * the Barcode, including some custom information.
 * Note: styles MUST be inline for this to work
 */
export default function BarcodePrint() {
  const product = useRecordContext();
  const { data: brands } = useGetMany('brands', product.brand ? [product.brand] : []);
  const { data: colors } = useGetMany('colors', product.colors ?? []);

  const colorNames = Object.values(colors)
    .map((x) => x?.name)
    .join(' & ');

  return (
    <Box
      id="product-barcode-print"
      style={{
        display: 'none',
        backgroundColor: 'white',
        maxWidth: '7cm',
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: '12px',
        textTransform: 'uppercase',
      }}
    >
      <TextField
        source="productCode"
        style={{
          position: 'relative',
          zIndex: 2,
          fontSize: '14px',
        }}
      />
      <FunctionField
        render={(record?: Record) => (
          <Barcode
            value={record?.barcode}
            style={{
              marginTop: '-5px',
            }}
          />
        )}
      />
      <Box>
        {[brands[0]?.name, product?.name, colorNames]
          .filter((x) => !!x)
          .join(' • ')}
      </Box>
    </Box>
  );
}
