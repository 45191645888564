import { SimpleForm, Edit, EditProps, ToolbarProps } from 'react-admin';
import ProductForm from 'components/ProductForm';
import DeleteButton from './DeleteButton';
import Toolbar from './Toolbar';
import SaveButton from './SaveButton';
import CloneButton from './CloneButton';

export default function ProductEdit(props: EditProps) {
  return (
    <Edit title="Edit product" {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        variant="standard"
        redirect={false}
        toolbar={<ProductEditToolbar />}
      >
        <ProductForm />
      </SimpleForm>
    </Edit>
  );
}

const ProductEditToolbar = (props: ToolbarProps) => (
  <Toolbar title="Edit product">
    <CloneButton {...props} omit={['barcode']} />
    <DeleteButton {...props} />
    <SaveButton {...props} />
  </Toolbar>
);
