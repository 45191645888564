import { ListContextProvider, ListView, useList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import EmptyTable from 'components/EmptyTable';
import { sendoutColumns } from 'constants/sendoutColumns';
import ResponsiveSendoutList from 'components/ResponsiveSendoutList';
import { ISendout } from 'types/strapi';

/**
 * A sendout list to be displayed as a page section
 */
const SectionSendoutList = (props: { data: ISendout[] }) => {
  const { data } = props;
  const classes = useStyles();
  const listContext = useList({
    data,
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider
      value={{ ...listContext, resource: 'sendouts', basePath: '/sendouts' }}
    >
      <ListView
        pagination={false}
        actions={false}
        classes={classes}
        bulkActionButtons={false}
        empty={<EmptyTable columns={sendoutColumns} />}
      >
        <ResponsiveSendoutList columns={sendoutColumns} />
      </ListView>
    </ListContextProvider>
  );
};

export default SectionSendoutList;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 0,
      padding: 0,
    },
    main: {
      padding: 0,
    },
    content: {
      padding: 0,
    },
  };
});
