import { makeStyles } from '@material-ui/core/styles';

export default function Dot(props: {
  color: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  const styles = useStyles();

  return (
    <span
      className={`${styles.root} ${props.className}`}
      style={{ backgroundColor: props.color, ...props.style }}
    />
  );
}

const useStyles = makeStyles({
  root: {
    padding: '4px',
    borderRadius: '100%',
    display: 'inline-block',
  },
});
