import React, { useState, useEffect } from 'react';
import {
  useLogin,
  useNotify,
  Notification,
  LoginComponent,
  useCheckAuth,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TextField,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

const MyLoginPage: LoginComponent = (props) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordHidden, setIsPassWordHidden] = useState(true);
  const checkAuth = useCheckAuth();
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  const handlePasswordVisible = () => setIsPassWordHidden((prev) => !prev);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify('Invalid login or password')
    );
  };

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        redirect('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, redirect]);

  return (
    <>
      <Box className={classes.formWrapper}>
        <Box className={classes.logo}>
          <Logo className={classes.logoSvg} />
        </Box>
        <form onSubmit={submit} className={classes.form}>
          <TextField
            className={classes.inputItem}
            name="username"
            type="string"
            value={username}
            inputProps={{ autoCapitalize: 'off' }}
            onChange={(e) => setUsername(e.target.value)}
            label="Login"
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Password</InputLabel>
            <Input
              id="standard-adornment-password"
              name="password"
              inputProps={{ autoCapitalize: 'off' }}
              type={isPasswordHidden ? 'password' : 'text'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisible}
                    edge="end"
                  >
                    {isPasswordHidden ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
            />
          </FormControl>
          <Button
            className={classes.submitButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            Log In
          </Button>
        </form>
      </Box>
      <Notification />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    minHeight: '600px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '6.45rem',
  },
  logoSvg: {
    width: '130px',
    height: '100%',
    color: theme.palette.primary.main,
  },
  form: {
    minWidth: '300px',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
  },
  inputItem: {
    marginBottom: '2rem',
  },
  formControl: {
    marginBottom: '2.5rem',
  },
  submitButton: {
    marginTop: '4rem',
    marginBottom: '6rem',
    textTransform: 'none',
    '& span': {
      fontSize: '15px',
    },
  },
}));

export default MyLoginPage;
