import {
  Edit,
  SimpleForm,
  EditProps,
  useEditController,
  Record,
  ToolbarProps,
} from 'react-admin';
import SendoutChat from 'components/SendoutChat';
import SendoutForm from 'components/SendoutForm';
import { ISendout } from 'types/strapi';
import { getTodayPlusTwoWeeks } from 'utils/getTodayPlusTwoWeeks';
import ShareButton from './ShareButton';
import Toolbar from './Toolbar';
import DeleteButton from './DeleteButton';
import SaveButton from './SaveButton';

export default function SendoutEdit(props: EditProps) {
  const editControllerProps = useEditController(props);

  const transform = (data: Record) => {
    /**
     * when the status is "Returned", move
     * sendout.products to sendout.returnedProducts
     */
    const sendout = data as ISendout;
    if (sendout.status === 'Returned' && sendout.products.length) {
      sendout.returnedProducts = sendout.products;
      sendout.products = [];
    }
    if (!sendout.estimatedReturnDate) {
      sendout.estimatedReturnDate = getTodayPlusTwoWeeks();
    }

    return sendout;
  };

  return (
    <>
      <Edit title="Edit sendouts" transform={transform} {...props}>
        <SimpleForm
          warnWhenUnsavedChanges
          variant="standard"
          redirect={false}
          submitOnEnter={false}
          toolbar={<SendoutEditToolbar />}
        >
          <SendoutForm />
        </SimpleForm>
      </Edit>
      <SendoutChat sendoutId={editControllerProps.record?.id} />
    </>
  );
}

const SendoutEditToolbar = (props: ToolbarProps) => (
  <Toolbar title="Edit sendout">
    <DeleteButton {...props} />
    <ShareButton {...props} />
    <SaveButton {...props} />
  </Toolbar>
);
