import { useCallback, useState } from 'react';
import {
  Button,
  useDataProvider,
  useListContext,
  useNotify,
  useResourceContext,
  fetchRelatedRecords,
  ButtonProps,
  Exporter,
  FilterPayload,
  Record,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadIcon from '@material-ui/icons/GetApp';

const ExportCsvButton = (props: ExportCsvButtonProps) => {
  const {
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const [loading, setLoading] = useState(false);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(
    (event) => {
      const entriesPerRequest = 500;
      const entriesToFetch = total;
      const totalPages = Math.ceil(entriesToFetch / entriesPerRequest);
      const fetchedEntries: Record[] = [];
      const fetcher = (page: number) =>
        dataProvider.getList(resource, {
          sort: currentSort,
          filter: filter ? { ...filterValues, ...filter } : filterValues,
          pagination: { page, perPage: entriesPerRequest },
        });
      const fetchAll = async () => {
        for (let page = 1; page <= totalPages; page++) {
          console.log(page);
          const { data } = await fetcher(page);
          fetchedEntries.push(...data);
        }
        return fetchedEntries;
      };
      setLoading(true);
      fetchAll()
        .then((data) => {
          exporter &&
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource
            );
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          notify('ra.notification.http_error', { type: 'warning' });
          setLoading(false);
        });

      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      currentSort,
      dataProvider,
      exporter,
      filter,
      filterValues,
      notify,
      onClick,
      resource,
      total,
    ]
  );

  return (
    <Button
      onClick={handleClick}
      label={loading ? 'Loading' : label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}
    >
      {loading ? <CircularProgress size={18} /> : icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  basePath,
  filterValues,
  resource,
  ...rest
}: Omit<ExportCsvButtonProps, 'label' | 'exporter'>) => rest;

interface Props {
  basePath?: string;
  exporter?: Exporter;
  filterValues?: FilterPayload;
  icon?: JSX.Element;
  label?: string;
  onClick?: (e: Event) => void;
  resource?: string;
}

export type ExportCsvButtonProps = Props & ButtonProps;

export default ExportCsvButton;
