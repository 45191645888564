import { MenuItemLink, useGetIdentity } from 'react-admin';
import { Box, Typography, Button, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Toolbar from 'components/Toolbar';
import { useMenuItems } from 'hooks/useMenuItems';
import { useState } from 'react';

const Menu = () => {
  const classes = useStyles();
  const { home, user, settings, other } = useMenuItems();
  const { identity } = useGetIdentity();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const SettingsToggleIcon = settings.iconToggle(open);

  return (
    <>
      <Toolbar title="Menu" />
      <Box className={classes.root}>
        <Box>
          <MenuItemLink
            className={classes.menuItemLink}
            to={home.url}
            primaryText={<MenuItemText text={home.label} />}
            leftIcon={<home.icon />}
          />
          {other.slice(3).map((menuItem) => (
            <MenuItemLink
              key={menuItem.label}
              className={classes.menuItemLink}
              to={menuItem.url}
              leftIcon={<menuItem.icon />}
              primaryText={<MenuItemText text={menuItem.label} />}
            />
          ))}

          <MenuItemLink
            className={classes.menuItemLink}
            to={settings.url}
            primaryText={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>{settings.label}</Typography>
                <SettingsToggleIcon />
              </Box>
            }
            onClick={handleClick}
            leftIcon={<settings.icon />}
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                paddingLeft: '0.75rem',
              }}
            >
              {settings.children.map((menuItem) => (
                <MenuItemLink
                  key={menuItem.label}
                  className={classes.menuItemLink}
                  to={menuItem.url}
                  primaryText={<MenuItemText text={menuItem.label} />}
                  leftIcon={<menuItem.icon />}
                />
              ))}
            </Box>
          </Collapse>

          {identity && (
            <MenuItemLink
              className={classes.menuItemLink}
              to={user.url}
              primaryText={<MenuItemText text={user.label} />}
              leftIcon={<user.icon />}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={classes.contactButton}
            classes={{ label: classes.contactButtonLabel }}
            variant="contained"
            color="primary"
            onClick={() => (window.location.href = 'mailto:help@exposure.net')}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  menuItemLink: {
    padding: '0',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    marginBottom: '0.5rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '1rem',
      marginBottom: '1.5rem',
    },
    '&:hover': {
      background: 'none',
    },
  },
  contactButton: {
    marginTop: '3rem',
    textTransform: 'none',
    width: '245px',
    height: '48px',
  },
  contactButtonLabel: {
    fontSize: '14px',
  },
}));

interface MenuItemTextProps {
  text: string;
}

const MenuItemText = (props: MenuItemTextProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <Typography>{props.text}</Typography>
    <ChevronRightIcon />
  </Box>
);

export default Menu;
