import { linkToRecord, useGetIdentity, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import DashboardIcon from '../components/Icons/DashboardIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ExpandLess, ExpandMore, Settings } from '@material-ui/icons';

export function useMenuItems() {
  const { identity } = useGetIdentity();
  const userEditPageLink = linkToRecord('/users', identity?.id, 'edit');
  const resources = useSelector(getResources);
  const settingsResoursesNames = [
    'colors',
    'sizes',
    'seasons',
    'brands',
    'collections',
    'categories',
  ];
  const settingsResources = resources
    .filter((c) => settingsResoursesNames.includes(c.name))
    .filter((c) => c.hasList)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const otherResources = resources
    .filter((c) => !settingsResoursesNames.includes(c.name))
    .filter((c) => c.hasList);

  return {
    home: {
      url: '/',
      label: 'Dashboard',
      icon: DashboardIcon,
    },
    user: {
      url: userEditPageLink,
      label: 'Account',
      icon: AccountCircleIcon,
    },
    settings: {
      url: '#',
      label: 'Settings',
      iconToggle: (open: boolean) => (open ? ExpandLess : ExpandMore),
      icon: Settings,
      children: settingsResources.map(transformResource),
    },
    other: otherResources.map(transformResource),
  };
}

const transformResource = (resource: any) => {
  return {
    url: `/${resource.name}`,
    label: resource.name,
    icon: resource.icon,
  };
};
