import { SimpleForm, Edit, EditProps } from 'react-admin';
import EditToolbar from './EditToolbar';
import SeasonForm from './SeasonForm';

const SeasonEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit season" />}
    >
      <SeasonForm />
    </SimpleForm>
  </Edit>
);

export default SeasonEdit;
