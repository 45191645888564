import { SimpleForm, CreateProps } from 'react-admin';
import Create from 'components/Create';
import ContactForm from 'components/ContactForm';
import CreateToolbar from './CreateToolbar';

const ContactCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create contact" />}
    >
      <ContactForm />
    </SimpleForm>
  </Create>
);

export default ContactCreate;
