import { Box, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    marginBottom: '60px',
  },
  pageButton: {
    maxWidth: '245px',
    width: '100%',
    marginBottom: '48px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '32px',
  },
}));

interface ProductNotFoundProps {
  barcodeValue: string;
  reset: () => void;
}

const ProductNotFound = (props: ProductNotFoundProps) => {
  const { barcodeValue, reset } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <span className={classes.title}>No product matches this barcode.</span>
      <Box className={classes.buttonsWrapper}>
        <Button
          className={classes.pageButton}
          component={Link}
          to={{
            pathname: '/products/create',
            state: { record: { barcode: barcodeValue } },
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Create new product
        </Button>
        <Button
          className={classes.pageButton}
          type="submit"
          onClick={reset}
          variant="contained"
          color="primary"
        >
          Re-scan
        </Button>
      </Box>
    </Box>
  );
};

export default ProductNotFound;
