import { SimpleForm, CreateProps } from 'react-admin';
import Create from 'components/Create';
import CreateToolbar from './CreateToolbar';
import ColorForm from './ColorForm';

const ColorCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create color" />}
    >
      <ColorForm />
    </SimpleForm>
  </Create>
);

export default ColorCreate;
