import cookies from 'utils/cookies';
import { getStrapiURL } from 'utils/getStrapiUrl';
import raStrapiRest from 'ra-strapi-rest.js';
import { fetchUtils } from 'react-admin';
import { addOrganization } from 'utils/addOrganization';
import * as strapi from '@casbah/strapi-fetch';
import { getJwt } from 'utils/getJwt';

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const defaultOptions: fetchUtils.Options = {};

  // Files uploading content-type not json
  if (typeof options?.body === 'object') {
    defaultOptions.headers = new Headers();
  } else {
    defaultOptions.headers = new Headers({
      'content-type': 'application/json',
    });
  }

  const token = cookies.getCookie('token');
  if (token) defaultOptions.headers.append('Authorization', `Bearer ${token}`);

  const mergedOptions: fetchUtils.Options = {
    ...defaultOptions,
    ...options,
  };

  return fetchUtils.fetchJson(url, mergedOptions);
};

const strapiDataProvider = raStrapiRest(
  getStrapiURL(''),
  httpClient,
  ['images', 'files', 'avatar']
);

// High order function that will execute before passing to ra-strapi-dataprovider
const dataProviderWithProfile =
  (dataProvider: any) => (verb: string, resource: string, params: any) => {
    // createMany used by import by csv button

    if (verb === 'createMany') {
      return new Promise((resolve, reject) => {
        const jwt = getJwt();
        const resolved: any[] = [];
        const rejected: { resource: any; body: any }[] = [];
        let index = -1;

        const intervalId = setInterval(async () => {
          index++;

          if (index === params.data.length - 1) {
            clearInterval(intervalId);
          }

          const response = await strapi.post(
            getStrapiURL(`/${resource}`),
            addOrganization(params.data[index]),
            jwt
          );

          if (response.ok) resolved.push(response);
          else
            rejected.push({
              resource: resource,
              body: addOrganization(params.data[index]),
            });

          if (resolved.length + rejected.length === params.data.length) {
            // retry failed, awaiting each
            rejected.forEach(async (r, i) => {
              console.log('Retrying ' + i);
              const retry = await strapi.post(
                getStrapiURL(`/${r.resource}`),
                r.body,
                jwt
              );
              console.log(retry.ok ? 'Success' : 'Failed');
            });
            resolve({ data: resolved.map((c) => c.data) });
          }
        }, 200);
      });
    }

    return dataProvider(verb, resource, params);
  };

export default dataProviderWithProfile(strapiDataProvider);
