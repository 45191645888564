import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
type AnyFunction = (...args: any[]) => any;

// Add css variable to fix height on mobiles
// https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
export function useObservableCssHeight() {
  useEffect(() => {
    function resize() {
      const root = document.documentElement;
      root.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    }

    resize();

    window.addEventListener('resize', resize);
    document.addEventListener('focus', resize, true);

    return () => {
      window.removeEventListener('resize', resize);
      document.removeEventListener('focus', resize, true);
    };
  }, []);
}

// Usage
// function Component(props) {
//   const [visible, setVisible] = useState(false)
//   // props.onToggle may not be stable
//   const onToggle = useEvent(props.onToggle)
//   // But our onToggle is stable
//   useEffect(() => onToggle(visible), [onToggle, visible])
//   // ❌ Throws when used in the render phase
//   onToggle(visible)
// }
export function useEvent<T extends AnyFunction>(callback?: T) {
  const ref = useRef<AnyFunction | undefined>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });
  useLayoutEffect(() => {
    ref.current = callback;
  });
  return useCallback<AnyFunction>(
    (...args) => ref.current?.apply(null, args),
    []
  ) as T;
}
