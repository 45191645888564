import { ImageField } from 'react-admin';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IProduct } from 'types/strapi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    padding: '0 3rem',
    textAlign: 'center',
    marginBottom: '60px',
  },
  pageButton: {
    maxWidth: '245px',
    width: '100%',
    marginBottom: '24px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '32px',
  },
  image: {
    width: '89px',
    height: '100px',
    boxShadow: 'none',
  },
}));

interface ProductFounProps {
  product: IProduct;
}

const ProductFound = (props: ProductFounProps) => {
  const { product } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <span className={classes.title}>
        We found a product that matches this barcode:
      </span>
      <Box sx={{ marginBottom: '80px', display: 'flex' }}>
        <ImageField
          classes={{ image: classes.image }}
          record={product}
          source="images[0].url"
        />
        <Box sx={{ marginLeft: '16px' }}>
          <Box>
            <Typography variant="subtitle2">{product.name}</Typography>
          </Box>
          <Box>
            {product.description ? product.description : 'No description'}
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonsWrapper}>
        <Button
          className={classes.pageButton}
          component={Link}
          to={{
            pathname: 'sendouts/create',
            state: { record: { products: [product] } },
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Add to sendout
        </Button>
        <Button
          className={classes.pageButton}
          component={Link}
          to={{ pathname: `products/${product.id}` }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Open product
        </Button>
      </Box>
    </Box>
  );
};

export default ProductFound;
