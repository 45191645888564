import { BulkDeleteButton, BulkActionProps } from 'react-admin';
import ProductArchiveBulkActionButton from './ProductArchiveBulkActionButton';

const ProductListBulkActionButtons = (props: BulkActionProps) => {
  return (
    <>
      <ProductArchiveBulkActionButton {...props} />
      <BulkDeleteButton {...props} undoable={false} />
    </>
  );
};

export default ProductListBulkActionButtons;
