import { SimpleForm, CreateProps } from 'react-admin';
import Create from 'components/Create';
import CreateToolbar from './CreateToolbar';
import CategoryForm from './CategoryForm';

const CategoryCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create category" />}
    >
      <CategoryForm />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;
