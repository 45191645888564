import themeBase from './theme-base';
import themeMuiOverrides from './theme-mui-overrides';
import themeRaOverrides from './theme-ra-overrides';
import merge from 'lodash/merge';

const overrides = merge(themeMuiOverrides, themeRaOverrides);

const theme = merge(themeBase, { overrides });

export default theme;
