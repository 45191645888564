import { ToolbarProps } from 'react-admin';
import Toolbar from './Toolbar';
import SaveButton from './SaveButton';

export default function CreateToolbar(props: ToolbarProps & { title: string }) {
  return (
    <Toolbar title={props.title}>
      <SaveButton {...props} alwaysEnableSaveButton />
    </Toolbar>
  );
}
