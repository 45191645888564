import { SimpleForm, Edit, EditProps } from 'react-admin';
import EditToolbar from './EditToolbar';
import CategoryForm from './CategoryForm';

const CategoryEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit category" />}
    >
      <CategoryForm />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
