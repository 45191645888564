import { Box } from '@material-ui/core';
import { useEffect, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export default function FlagFilter(props: {
  label: string;
  source: string;
  filters: { [key: string]: any };
  defaultFilters?: { [key: string]: any };
}) {
  const styles = useStyles();
  const cache = useRef({
    filters: props.filters,
    initialLocationPathname: useMemo(() => window.location.pathname, []),
    history: useHistory(),
    defaultFilters: props.defaultFilters,
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const parsedFilterQuery = JSON.parse(query.get('filter') || '{}');
    const newFilterQuery = {
      ...parsedFilterQuery,
      ...cache.current.filters,
    };
    query.set('filter', JSON.stringify(newFilterQuery));
    cache.current.history.replace(`${window.location.pathname}?${query}`);

    return () => {
      // If user navigated to a new page while filter were open, dont remove it from url
      if (cache.current.initialLocationPathname !== window.location.pathname)
        return;
      const query = new URLSearchParams(window.location.search);
      const parsedFilterQuery = JSON.parse(query.get('filter') || '{}');
      const newFilterQuery = _.pickBy(
        parsedFilterQuery,
        //eslint-disable-next-line react-hooks/exhaustive-deps
        (value, key) => !Object.keys(cache.current.filters).includes(key)
      );
      query.set(
        'filter',
        JSON.stringify(
          cache.current.defaultFilters
            ? Object.assign({}, newFilterQuery, cache.current.defaultFilters)
            : newFilterQuery
        )
      );
      cache.current.history.replace(`${window.location.pathname}?${query}`);
    };
  }, []);

  return <Box className={styles.root}>{props.label}</Box>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #bcbcbc',
    padding: '8px 13px',
    margin: '8px 0',
    borderRadius: '4px',
    fontSize: '1rem',
  },
}));
