import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  email,
  number,
  useDataProvider,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import Section from './Section';
import SectionHeader from './SectionHeader';
import TwoColumns from './TwoColumns';
import SectionSendoutList from './SectionSendoutList';
import { useEffect, useState } from 'react';
import { IContact, ISendout } from '../../../types/generated/strapi';

export default function ContactForm(props: any) {
  const {
    values: { id, addresses },
  } = useFormState();

  const [sendouts, setSendouts] = useState<ISendout[]>([]);

  const form = useForm();

  const addNewAddress = () => {
    form.change('addresses', [...(addresses || []), {}]);
  };

  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!sendouts?.length)
      dataProvider
        .getList<ISendout>('sendouts', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'created_at', order: 'asc' },
          filter: {
            contact: id,
          },
        })
        .then((res) => setSendouts(res?.data || []));
  }, [dataProvider, id, sendouts?.length]);

  const alreadyTaken = async (value: string) => {
    if (value === 'na@na.com') return undefined;

    const { total } = await dataProvider.getList<IContact>('contacts', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {
        email: value,
        id_ne: id,
      },
    });
    if (total > 0) {
      return 'Contact with this email already exists. If you dont know the email use na@na.com';
    }
    return undefined;
  };

  return (
    <>
      <Section>
        <TwoColumns>
          <Box>
            <TextInput source="firstName" validate={required()} {...props} />
            <TextInput source="lastName" validate={required()} {...props} />
            <TextInput
              source="email"
              validate={[required(), email(), alreadyTaken]}
              vaidateOnBlur={true}
              helperText="Please enter a real email address here this is important!"
              {...props}
            />
          </Box>
          <Box>
            <TextInput source="phone" validate={number()} {...props} />
            <TextInput source="company" {...props} />
            <TextInput source="role" {...props} />
          </Box>
        </TwoColumns>
        <TextInput
          source="notes"
          multiline
          {...props}
          style={{ maxWidth: '100%' }}
        />
      </Section>

      <SectionHeader title="Addresses">
        <Button variant="contained" color="secondary" onClick={addNewAddress}>
          Add new address
        </Button>
      </SectionHeader>
      <Section>
        {addresses?.length ? (
          <ArrayInput source="addresses" label="">
            <SimpleFormIterator addButton={<span></span>}>
              <TextInput source="country" label="Country" {...props} />
              <TextInput source="city" label="City" {...props} />
              <TextInput source="line1" label="Line 1" {...props} />
              <TextInput source="line2" label="Line 2" {...props} />
              <TextInput source="postcode" label="Postcode" {...props} />
            </SimpleFormIterator>
          </ArrayInput>
        ) : (
          <p style={{ color: 'gray' }}>No address found</p>
        )}
      </Section>

      <SectionHeader title="Sendouts" />
      <SectionSendoutList data={sendouts} />
    </>
  );
}
