import { useCallback, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Quagga from '@ericblade/quagga2';

interface BarcodeScannerWithDialogProps {
  onClose: () => void;
  onChangeBarcodeValue: (value: string) => void;
}
const BarcodeScannerWithDialog = (props: BarcodeScannerWithDialogProps) => {
  const { onChangeBarcodeValue, onClose } = props;
  const classes = useStyles();

  const [barcodeScannerOpen, setBarcodeScannerOpen] = useState(true);
  const [notAllowedWarningOpen, setNotAllowedWarningOpen] = useState(false);

  const [cameraEl, setCameraEl] = useState<HTMLDivElement | null>(null);

  const setCameraRef = useCallback((node) => {
    setCameraEl(node);
  }, []);

  useEffect(() => {
    if (!cameraEl) return;

    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: cameraEl,
          constraints: {
            width: 640,
            height: 480,
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: ['code_128_reader'],
          debug: {
            drawBoundingBox: true,
            drawScanline: true,
          },
        },
      },
      (error) => {
        if (error) {
          // For now lets use one message for all errors (but we can get the reason error.name === 'NotAllowedError')
          setNotAllowedWarningOpen(true);
          setBarcodeScannerOpen(false);
          return;
        }

        Quagga.start();
      }
    );

    Quagga.onDetected((data) => {
      const result = data.codeResult.code;

      if (result) {
        onChangeBarcodeValue(result);
      }

      onClose();
    });

    return () => {
      Quagga.stop();
    };
  }, [onClose, onChangeBarcodeValue, cameraEl]);

  return (
    <>
      <Dialog open={barcodeScannerOpen} onClose={onClose} maxWidth="md">
        <DialogTitle>Scan barcode</DialogTitle>
        <DialogContent>
          <div className={classes.cameraWrapper}>
            <div id="camera" ref={setCameraRef} className={classes.camera} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notAllowedWarningOpen}
        onClose={onClose}
      >
        <Alert severity="error" variant="filled" onClose={onClose}>
          <AlertTitle>Something goes wrong.</AlertTitle>
          Camera permissions denied. This page doesn't have permission to use
          your camera for barcode scanning.
          <br />
          Please check your settings and refresh the page.
        </Alert>
      </Snackbar>
    </>
  );
};

export default BarcodeScannerWithDialog;

const useStyles = makeStyles((theme) => ({
  cameraWrapper: {
    position: 'relative',
    width: '640px',
    maxWidth: '100%',
    paddingBottom: '75%',
    backgroundColor: '#ccc',
  },
  camera: {
    display: 'block',
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    '&> video': {
      display: 'block',
      width: '100%',
    },
    '&> canvas': {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
}));