import { ListActionsProps } from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { MutableRefObject, useRef, useState, FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'utils/compose';
import { uniq } from 'lodash';
import { IColumnValueReport, IColumnToImport, InvalidType } from './types';
import getValidation from './validations';
import { dset } from 'dset';

interface ImportCsvProps {
  listActionProps: ListActionsProps;
  labels: {
    summary: string;
  };
  getColumnsToImport: () => Promise<IColumnToImport[]>;
}
export default function ImportCsv(props: ImportCsvProps) {
  const { listActionProps, labels, getColumnsToImport } = props;

  const [report, setReport] = useState<IColumnValueReport[]>([]);
  const [columnsNames, setColumnsNames] = useState<string[]>([]);
  const [csvRowsCount, setCsvRowsCount] = useState<number>();

  const resolve = useRef<(args: any) => void>();
  const reject = useRef<() => void>();
  const classes = useStyles();

  const resetValues = () => {
    setReport([]);
    deleteReport();
  };

  const onProcced = () => {
    resolve.current && resolve.current('');
    resetValues();
  };

  const onCancel = () => {
    reject.current && reject.current();
    resetValues();
  };

  const critical = useMemo<IColumnValueReport[]>(() => {
    if (!report.length) return [];
    return getCriticalErrors(report);
  }, [report]);

  const warnings = useMemo<IColumnValueReport[]>(() => {
    if (!report.length) return [];
    return getWarningErrors(report);
  }, [report]);

  const tableReportData = useMemo<ITableReportProps | undefined>(() => {
    if (!report.length) return undefined;

    const rowsIndexes = uniq(report.map((c) => c.rowIndex));

    return {
      head: columnsNames,
      rows: rowsIndexes.map((rowIndex) => {
        const cells = report.filter((c) => c.rowIndex === rowIndex);
        return {
          cells,
        };
      }),
    };
  }, [columnsNames, report]);

  return (
    <div className={classes.root}>
      <ImportButton
        {...listActionProps}
        validateRow={validateRow(resolve, reject)}
        transformRows={transformRows(
          setCsvRowsCount,
          setReport,
          setColumnsNames,
          getColumnsToImport
        )}
      />
      {!!report.length && (
        <Dialog open onClose={onCancel} fullWidth maxWidth="lg">
          <DialogTitle>
            Importing {csvRowsCount} {labels.summary}
          </DialogTitle>
          <DialogContent>
            <div className={classes.contentInner}>
              {!!critical.length && (
                <div className={classes.critical}>
                  <div className={classes.blockTitle}>Critical issues</div>
                  {columnsNames.map((columnName, index) => {
                    const filteredByColumnName = critical.filter(
                      (columnValueReport) =>
                        columnValueReport.columnName === columnName
                    );

                    if (!filteredByColumnName.length) return null;

                    return (
                      <ErrorBlock key={index} items={filteredByColumnName} />
                    );
                  })}
                </div>
              )}
              {!!warnings.length && (
                <div className={classes.warnings}>
                  <div className={classes.blockTitle}>Warnings</div>
                  {columnsNames.map((columnName, index) => {
                    const filteredByColumnName = warnings.filter(
                      (columnValueReport) =>
                        columnValueReport.columnName === columnName
                    );

                    if (!filteredByColumnName.length) return null;

                    return (
                      <ErrorBlock key={index} items={filteredByColumnName} />
                    );
                  })}
                </div>
              )}
              {!!tableReportData && (
                <>
                  <div className={classes.blockTitle}>Import preview</div>
                  <TableReport {...tableReportData} />
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            {!critical.length && <Button onClick={onProcced}>Procced</Button>}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentInner: {
    marginBottom: '-2rem',
    '--color-required': '#ca0707',
    '--color-warning': '#ffa827',
  },
  critical: {
    '--color': 'var(--color-required)',
    marginBottom: '2rem',
  },
  warnings: {
    '--color': 'var(--color-warning)',
    marginBottom: '2rem',
  },
  blockTitle: {
    color: 'var(--color)',
    fontSize: '1.25rem',
    marginBottom: '1rem',
  },
}));
let columnValueReports: IColumnValueReport[] = [];

const deleteReport = () => (columnValueReports = []);

const getEntriesByInvalidType = (
  columnValueReports: IColumnValueReport[],
  callback: (invalidType: InvalidType | undefined) => boolean
) =>
  columnValueReports
    .map((columnValueReport) => {
      const filteredValues = columnValueReport.values.filter((c) =>
        callback(c.invalidType)
      );

      return filteredValues.length > 0
        ? { ...columnValueReport, values: filteredValues }
        : null;
    })
    .filter((c) => c) as IColumnValueReport[];

const criticalTypes: InvalidType[] = ['required', 'notCorrectEmail'];
const getCriticalErrors = (columnValueReports: IColumnValueReport[]) =>
  getEntriesByInvalidType(
    columnValueReports,
    (invalidType) =>
      invalidType !== undefined && criticalTypes.includes(invalidType)
  );

const getWarningErrors = (columnValueReports: IColumnValueReport[]) =>
  getEntriesByInvalidType(
    columnValueReports,
    (invalidType) =>
      invalidType !== undefined && !criticalTypes.includes(invalidType)
  );

const createColumnValueReport = ({
  columnName,
  columnValueRaw,
  rowIndex,
}: {
  columnName: string;
  columnValueRaw: string;
  rowIndex: number;
}): IColumnValueReport => {
  return {
    columnName,
    rowIndex,
    columnValueRaw,
    columnValueStrapi: columnValueRaw,
    values: [],
  };
};

const transformRows =
  (
    setCsvRowsCount: any,
    setReport: any,
    setColumnsNames: any,
    getColumnsToImport: () => Promise<IColumnToImport[]>
  ) =>
  (csvRows: any[]) =>
    new Promise(async (resolve, reject) => {
      getColumnsToImport().then((columnsToImport) => {
        csvRows.forEach((row: any, index: number) => {
          columnsToImport.forEach((columnToImport) => {
            try {
              const { csvColumnName, path } = columnToImport;

              const validation = getValidation(columnToImport);
              const initialColumnValueReport = createColumnValueReport({
                columnName: csvColumnName,
                columnValueRaw: row[csvColumnName] || '',
                rowIndex: index,
              });
              const columnValueReport = compose(validation)(
                initialColumnValueReport
              );

              columnValueReports.push(columnValueReport);

              dset(row, path, columnValueReport.columnValueStrapi);
            } catch (err) {
              console.log(err);
            }
          });
        });

        setReport(columnValueReports);
        setCsvRowsCount(csvRows.length);
        setColumnsNames(columnsToImport.map((c) => c.csvColumnName));
        return resolve(csvRows);
      });
    });

// Second step step validate
const validateRow =
  (
    resolvePromise: MutableRefObject<((args: any) => void) | undefined>,
    rejectPromise: MutableRefObject<(() => void) | undefined>
  ) =>
  (row: any, rowIndex: number, allRows: any[]) =>
    new Promise((resolve, reject) => {
      // Last row, show dialog
      if (allRows.length === rowIndex + 1) {
        rejectPromise.current = reject;
        resolvePromise.current = resolve;
        return;
      }

      return resolve(null);
    });

const useErrorBlockStyles = makeStyles({
  errorValue: {
    '&:after': {
      content: '", "',
    },
    '&:last-child:after': {
      content: '""',
    },
  },
  columnName: {
    textTransform: 'capitalize',
  },
  valueList: {
    marginLeft: '0.5ch',
  },
});

const findUniqValuesByInvalidType = (
  items: IColumnValueReport[],
  invalidType: InvalidType
) =>
  uniq(
    items
      .map((b) =>
        b.values
          .filter((d) => d.invalidType === invalidType)
          .map((d) => d.value)
      )
      .flat()
  );

interface ErrorBlockRowProps {
  list: (string | number)[];
  columnName: string;
  text: string;
}
const ErrorBlockRow = (props: ErrorBlockRowProps) => {
  const { list, columnName, text } = props;
  const classes = useErrorBlockStyles();

  return (
    <div>
      <span className={classes.columnName}>{columnName}</span> {text}
      <span className={classes.valueList}>
        {list.map((value, index) => (
          <span className={classes.errorValue} key={index}>
            {value}
          </span>
        ))}
      </span>
    </div>
  );
};

interface ErrorBlockProps {
  items: IColumnValueReport[];
}
const ErrorBlock: FC<ErrorBlockProps> = (props) => {
  const { items } = props;
  const columnName = items[0].columnName;

  const required = getEntriesByInvalidType(
    items,
    (invalidType) => invalidType !== undefined && invalidType === 'required'
  );
  const notCorrectEmail = findUniqValuesByInvalidType(items, 'notCorrectEmail');
  const requiredRowIndexes = required.map((b) => b.rowIndex + 1);
  const oneOnlyValues = findUniqValuesByInvalidType(items, 'oneOnly');
  const notFoundValues = findUniqValuesByInvalidType(items, 'notCorrectName');

  return (
    <>
      {!!requiredRowIndexes.length && (
        <ErrorBlockRow
          columnName={columnName}
          list={requiredRowIndexes}
          text="not found:"
        />
      )}
      {!!notCorrectEmail.length && (
        <ErrorBlockRow
          columnName={columnName}
          list={notCorrectEmail}
          text="not correct:"
        />
      )}
      {!!notFoundValues.length && (
        <ErrorBlockRow
          columnName={columnName}
          list={notFoundValues}
          text="not found:"
        />
      )}
      {!!oneOnlyValues.length && (
        <ErrorBlockRow
          columnName={columnName}
          list={oneOnlyValues}
          text="not first
          value in column and will be ignored:"
        />
      )}
    </>
  );
};

const useTableStyles = makeStyles({
  container: {
    marginBottom: '1rem',
  },
  row: {
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      borderBottom: '0',
    },
  },
});
interface ITableReportProps {
  head: string[];
  rows: {
    cells: IColumnValueReport[];
  }[];
}
const TableReport = (props: ITableReportProps) => {
  const { head, rows } = props;
  const classes = useTableStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            {head.map((text, index) => (
              <TableCell key={index}>
                <span style={{ textTransform: 'capitalize' }}>{text}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const { cells } = row;

            return (
              <TableRow key={index} className={classes.row}>
                {head.map((columnName, index) => {
                  const cell = cells.find(
                    (cell) => cell.columnName === columnName
                  );

                  return !!cell ? (
                    <TableCell key={index} style={{ borderBottom: '0' }}>
                      {cell.values.map((value, index, items) => {
                        const color = !value.invalidType
                          ? 'inherit'
                          : criticalTypes.includes(value.invalidType)
                          ? 'var(--color-required)'
                          : 'var(--color-warning)';
                        const textContent = value.value || '*required';

                        return (
                          <span style={{ color }} key={index}>
                            {index + 1 === items.length
                              ? textContent
                              : `${textContent}, `}
                          </span>
                        );
                      })}
                    </TableCell>
                  ) : (
                    <TableCell key={index} style={{ borderBottom: '0' }} />
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
