export const collectionColumns = [
  {
    label: 'Name',
    source: 'name',
    hide: false,
  },
  {
    label: 'Brand',
    source: 'brand.name',
    hide: false,
  },
];
