import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-admin';
import { Box, Menu, MenuItem, Checkbox } from '@material-ui/core';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import { Column, COLUMNFILTER } from 'redux/columnFilters';
import { makeStyles } from '@material-ui/core/styles';

interface ColumnsFilterProps {
  columns: Column[];
  resource: string;
}

export default function ColumnsFilter(props: ColumnsFilterProps) {
  const { columns, resource } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChange = (value: string) => {
    const newColumns = columns.map((c) => {
      if (value === c.source) return { ...c, hide: !c.hide };
      return c;
    });

    // Not allow to disable all columns, because it will break table
    const isAllDisabled = newColumns.every((c) => c.hide);
    if (isAllDisabled) {
      newColumns.forEach((c) => {
        if (value === c.source) c.hide = false;
      });
    }

    dispatch({
      type: COLUMNFILTER,
      payload: {
        resource,
        columns: newColumns,
      },
    });
  };

  return (
    <Box className={classes.root}>
      <Button
        label="Columns"
        onClick={(e) => {
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <ViewWeekIcon />
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
      >
        {columns.map((c) => (
          <MenuItem
            key={c.source}
            className={classes.menuItem}
            value={c.source}
            onClick={() => handleChange(c.source)}
          >
            <Checkbox
              className={classes.checkBox}
              checked={!c.hide}
              color="primary"
            />
            {c.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-1px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  select: {
    minWidth: '160px',
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  checkBox: {
    padding: '0.25rem 0.25rem 0.25rem 0.0625rem',
  },
  menuItem: {
    paddingRight: '1.5rem',
  },
}));
