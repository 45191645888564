import {
  DeleteWithConfirmButton as RADeleteWithConfirmButton,
  ToolbarProps,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import variables from 'styles/variables';

export default function DeleteButton(props: ToolbarProps) {
  const { record, resource } = props;
  const notify = useNotify();
  const redirect = useRedirect();

  // -------------------------------------------------------------------
  // Default "onSuccess" action for DeleteWithConfirmButton has a bug
  // which leads to a 'getOne' call on an already deleted object
  // and results in "Element does not exist" message in UI
  // (see https://github.com/marmelab/react-admin/issues/5541).
  // So we have to create and pass a different function
  // -------------------------------------------------------------------
  const onSuccess = () => {
    notify('ra.notification.deleted', {
      type: 'info',
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    redirect('list', `/${resource}`);
  };
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  return (
    <RADeleteWithConfirmButton
      record={record}
      undoable={false}
      variant={isDesktopButton ? 'contained' : 'text'}
      label={!isXSmall ? 'Delete' : undefined}
      onSuccess={onSuccess}
      color={isDesktopButton ? 'secondary' : 'primary'}
      size="medium"
      icon={!isXSmall ? <></> : <span>Delete</span>}
    />
  );
}
