import { useLogout } from 'react-admin';
import { Button } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import variables from 'styles/variables';

const LogoutButton = () => {
  const logout = useLogout();
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );

  return (
    <Button
      variant={isDesktopButton ? 'contained' : 'text'}
      color={isDesktopButton ? 'secondary' : 'primary'}
      onClick={() => logout()}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
