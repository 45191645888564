import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getResources } from 'react-admin';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { ViewList as DefaultIcon, Menu as MenuIcon } from '@material-ui/icons';
import { ReactComponent as BarcodeIcon } from 'assets/svg/barcode.svg';
import { makeStyles } from '@material-ui/core/styles';

export default function BottomMenu() {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const resources = useSelector(getResources);

  const [currentValue, setCurrentValue] = useState(
    `/${location.pathname.split('/')[1]}`
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(newValue);
    setCurrentValue(newValue);
  };

  return (
    <BottomNavigation
      className={classes.root}
      value={currentValue}
      onChange={handleChange}
    >
      {resources
        .slice(0, 3)
        .filter((r) => r.hasList)
        .map((r) => (
          <BottomNavigationAction
            className={classes.button}
            key={r.name}
            value={`/${r.name}`}
            icon={
              r.icon ? <r.icon className={classes.icon} /> : <DefaultIcon />
            }
          />
        ))}
      <BottomNavigationAction
        className={classes.button}
        value="/scan"
        icon={<BarcodeIcon className={classes.icon} />}
      />
      <BottomNavigationAction
        className={classes.button}
        value="/menu"
        icon={<MenuIcon className={classes.icon} />}
      />
    </BottomNavigation>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '80px !important',
    left: '0',
    bottom: '0',
    zIndex: 100,
  },
  button: {
    maxWidth: 'auto',
    minWidth: 0,
  },
  icon: {
    width: 24,
    height: 24,
  },
}));
