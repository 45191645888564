import {
  TextInput,
  ImageInput,
  ImageField,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ChipField,
  ReferenceArrayInput,
  required,
  useRedirect,
  BooleanInput,
  useRecordContext,
} from 'react-admin';
import { Box, Chip } from '@material-ui/core';
import BarcodeInput from 'components/BarcodeInput';
import { productGenderChoices } from 'constants/index';
import Section from './Section';
import SectionHeader from './SectionHeader';
import TwoColumns from './TwoColumns';
import Dot from './Dot';
import { getId } from 'utils/getId';
import { IProduct } from '../../../types/generated/strapi';
import { useFormState, useForm } from 'react-final-form';

const ProductForm = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const { values } = useFormState<IProduct>();
  const form = useForm();
  const brandId = values.brand ? getId(values.brand) : '';
  const onBrandSelected = () => {
    form.change('collections', []);
  };
  return (
    <>
      <Section>
        <TwoColumns>
          <Box>
            <TextInput source="name" variant="standard" validate={required()} />
            <TextInput source="description" variant="standard" multiline />
            <br />
            <br />
            {record.sendout ? (
              <Chip
                label={`Reserved on Sendout ${getId(record.sendout)}`}
                onClick={() => redirect(`/sendouts/${getId(record.sendout)}`)}
                variant="outlined"
                icon={<Dot color="red" style={{ marginLeft: '12px' }} />}
              />
            ) : (
              <Chip
                label="Available"
                variant="outlined"
                icon={<Dot color="#7abd51" style={{ marginLeft: '12px' }} />}
              />
            )}
          </Box>
          <Box>
            <TextInput source="productCode" variant="standard" />
            <BarcodeInput />
          </Box>
        </TwoColumns>
      </Section>

      <SectionHeader title="Attributes" />
      <Section>
        <TwoColumns>
          <Box>
            <ReferenceInput
              label="Brand"
              source="brand"
              reference="brands"
              variant="standard"
              onChange={onBrandSelected}
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>

            {!!brandId && (
              <ReferenceArrayInput
                label="Collections"
                source="collections"
                reference="collections"
                filter={{ brand: brandId }}
                perPage={1000}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectArrayInput variant="standard">
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            )}

            <ReferenceInput
              label="Size"
              source="size"
              reference="sizes"
              variant="standard"
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              label="Colors"
              source="colors"
              reference="colors"
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectArrayInput variant="standard">
                <ChipField source="name" />
              </SelectArrayInput>
            </ReferenceArrayInput>
          </Box>
          <Box>
            <ReferenceInput
              label="Season"
              source="season"
              reference="seasons"
              variant="standard"
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput
              source="gender"
              variant="standard"
              choices={productGenderChoices}
            />
            <ReferenceArrayInput
              label="Categories"
              source="categories"
              reference="categories"
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectArrayInput variant="standard">
                <ChipField source="name" />
              </SelectArrayInput>
            </ReferenceArrayInput>
            <BooleanInput source="archived" />
          </Box>
        </TwoColumns>
      </Section>

      <SectionHeader title="Images" />
      <Section>
        <ImageInput source="images" label="" accept="image/*" multiple={true}>
          <ImageField source="url" />
        </ImageInput>
      </Section>
    </>
  );
};

export default ProductForm;
