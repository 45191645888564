export default function EmptyTableCell() {
  return (
    <span
      style={{
        color: 'lightgray',
      }}
    >
      —
    </span>
  );
}
