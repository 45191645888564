import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import themeBase from 'styles/theme-base';

export default function DashboardTableCard(props: {
  title: string;
  children: JSX.Element[] | JSX.Element;
  rightNavigation?: JSX.Element;
}) {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.head}>
        <Box className={styles.title}>{props.title}</Box>
        <Box className={styles.rightNavigation}>{props.rightNavigation}</Box>
      </Box>
      <Box className={styles.content}>{props.children}</Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    boxShadow: themeBase.shadows[1],
    backgroundColor: 'white',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem 2rem',
    borderBottom: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  title: {
    fontSize: '1.25rem',
  },
  rightNavigation: {},
  content: {
    flexGrow: 1,
    '& > *:not(:last-child)': {
      borderBottom: '1px solid #e0e0e0',
    },
  },
}));
