import { createTheme } from '@material-ui/core/styles';

const themeBase = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#000',
      dark: '#fff',
    },
    secondary: {
      main: '#fff',
      light: '#000',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontSize: 15,
    fontFamily: [
      'Helvetica Now Display',
      'HelveticaNeue',
      'helvetica',
      'arial',
    ].join(','),
  },
});

export default themeBase;
