import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SimpleList,
  SearchInput,
  TextInput,
  TopToolbar,
  FilterButton,
  BulkDeleteButton,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';
import ListHeader from 'components/ListHeader';
import CustomPagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import { ResponsiveListProps } from 'types/ResponsiveListProps';
import { AppState } from 'redux/AppState';

const SizeList = (props: ListProps) => {
  const columns = useSelector((state: AppState) => state.columnFilters.sizes);

  return (
    <>
      <ListHeader title="Sizes" />
      <List
        {...props}
        perPage={10}
        filter={getOrganizationFilter()}
        filters={[
          <SearchInput variant="outlined" source="q" alwaysOn />,
          <TextInput variant="outlined" label="Name" source="name" />,
        ]}
        actions={
          <>
            <TopToolbar>
              <FilterButton />
            </TopToolbar>
          </>
        }
        bulkActionButtons={<BulkDeleteButton undoable={false} />}
        pagination={<CustomPagination />}
        empty={<EmptyTable columns={columns} />}
      >
        <ResponsiveList columns={columns} />
      </List>
    </>
  );
};

const ResponsiveList = (props: ResponsiveListProps) => {
  const { columns, ...rest } = props;
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Datagrid rowClick="edit" {...rest}>
          {columns.map((c) =>
            c.hide ? null : (
              <TextField key={c.label} label={c.label} source={c.source} />
            )
          )}
        </Datagrid>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SimpleList primaryText={<TextField source="name" />} />
      </Box>
    </>
  );
};

export default SizeList;
