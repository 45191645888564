import { addOrganization } from 'utils/addOrganization';
import { Create as ReactAdminCreate } from 'react-admin';

//https://marmelab.com/blog/2019/03/27/supplying-your-own-defaults-to-react-admin.html

const Create = ReactAdminCreate;

//@ts-ignore
Create.defaultProps = {
  transform: addOrganization,
};

export default Create;
