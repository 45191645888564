import { FieldProps } from 'react-admin';
import { useFormState } from 'react-final-form';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { SendoutFormValues } from 'types/sendoutFormValues';
import * as strapi from '@casbah/strapi-fetch';
import SendoutProductsTable from 'components/SendoutProductsTable';
import { Link } from 'react-router-dom';
import { IProduct, ISendout } from 'types/strapi';
import { useCallback, useState, useEffect } from 'react';
import BarcodeScannerWithDialog from 'components/BarcodeScannerWithDialog';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';
import { useForm } from 'react-final-form';
import Barcode from 'components/Barcode';
import SearchResource from './SearchResource';
import { CameraAlt } from '@material-ui/icons';
import SectionHeader from './SectionHeader';
import { getId, getIdArray } from 'utils/getId';

export default function SendoutEditProducts(props: FieldProps<ISendout>) {
  const {
    values: { products, returnedProducts },
  } = useFormState<SendoutFormValues>();
  const form = useForm();
  const [barcodeScannerOpen, setBarcodeScannerOpen] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState<string>();
  const [barcodeProduct, setBarcodeProduct] = useState<IProduct>();
  const [barcodeProductLoaded, setBarcodeProductLoaded] = useState(false);

  const onProductSelected = (product: IProduct | null) => {
    if (!product) return;
    form.change('products', [...(products || []), product.id]);
  };

  const reset = () => {
    setBarcodeProductLoaded(false);
    setBarcodeProduct(undefined);
    setBarcodeValue(undefined);
  };

  const onRescanBarcode = () => {
    reset();
    setBarcodeScannerOpen(true);
  };

  const onReturnedProduct = () => {
    if (!barcodeProduct || !products?.length) return;
    reset();
    // Add to returned products and remove from products
    form.change('returnedProducts', [
      ...(returnedProducts || []),
      barcodeProduct.id,
    ]);
    form.change(
      'products',
      products.map(getId).filter((c) => getId(c) !== barcodeProduct.id)
    );
  };

  const onConfirmProductFromBarcodeScanner = () => {
    if (!barcodeProduct) return;
    reset();
    // Add to products and remove from returned products
    form.change('products', [...(products || []), barcodeProduct.id]);
    form.change(
      'returnedProducts',
      returnedProducts.map(getId).filter((c) => getId(c) !== barcodeProduct.id)
    );
  };

  const onCloseBarcodeScanner = useCallback(() => {
    setBarcodeScannerOpen(false);
  }, []);

  const openBarcodeScanner = useCallback(() => {
    setBarcodeScannerOpen(true);
  }, []);

  const fetchProductByBarcode = useCallback(async (barcode) => {
    const response = await strapi.get<IProduct[]>(
      getStrapiURL(`/products?barcode=${barcode}`),
      getJwt()
    );
    if (response.ok) {
      setBarcodeProduct(response?.payload?.[0]);
      setBarcodeProductLoaded(true);
    } else console.error(response.error);
  }, []);

  useEffect(() => {
    if (barcodeValue) {
      fetchProductByBarcode(barcodeValue);
    }
  }, [barcodeValue, fetchProductByBarcode]);

  return (
    <>
      <SectionHeader title="Products">
        <Button
          variant="contained"
          color="secondary"
          onClick={openBarcodeScanner}
          endIcon={<CameraAlt />}
        >
          Scan barcode
        </Button>
      </SectionHeader>
      <SearchResource<IProduct>
        resource="products"
        variant="outlined"
        onSelected={onProductSelected}
        filter={(product) =>
          ![...getIdArray(products), ...getIdArray(returnedProducts)].includes(
            product.id
          )
        }
        match={(product) => [
          product.name,
          product.barcode,
          product.productCode,
        ]}
        renderOption={(product) => (
          <div style={{ fontSize: '0.85em' }}>
            <div>{`${product.brand?.name || 'No brand'} • ${
              product.name
            }`}</div>
            <div>{`${product.barcode || 'No barcode'} • ${
              product.productCode || 'No product code'
            }`}</div>
          </div>
        )}
      />
      <br />
      <Box sx={{ mt: 1, mb: 3 }}>
        <SendoutProductsTable readonly={false} />
      </Box>
      {barcodeScannerOpen && (
        <BarcodeScannerWithDialog
          onClose={onCloseBarcodeScanner}
          onChangeBarcodeValue={setBarcodeValue}
        />
      )}
      {barcodeValue && barcodeProductLoaded && !barcodeProduct && (
        <Dialog open={true} onClose={reset} maxWidth="md">
          <DialogTitle>No product matches this barcode:</DialogTitle>
          <DialogContent>
            <Barcode value={barcodeValue} />
          </DialogContent>
          <DialogActions>
            <Button onClick={reset}>Cancel</Button>
            <Button onClick={onRescanBarcode}>Re-scan</Button>
          </DialogActions>
        </Dialog>
      )}
      {barcodeValue &&
        barcodeProductLoaded &&
        !!barcodeProduct &&
        !barcodeProduct.sendout && (
          <Dialog open={true} onClose={reset} maxWidth="md">
            <DialogTitle>
              We found a product that matches this barcode:
            </DialogTitle>
            <DialogContent>
              <p>Brand: {barcodeProduct.brand?.name}</p>
              <p>Product: {barcodeProduct.name}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={reset}>Cancel</Button>
              <Button onClick={onConfirmProductFromBarcodeScanner}>
                Add to sendout
              </Button>
            </DialogActions>
          </Dialog>
        )}
      {barcodeValue &&
        barcodeProductLoaded &&
        !!barcodeProduct &&
        !!barcodeProduct.sendout && (
          <Dialog open={true} onClose={reset} maxWidth="md">
            <DialogTitle>
              This product belongs to the following sendout:
            </DialogTitle>
            <DialogContent>
              <p>Sendout ID: {barcodeProduct.sendout.id}</p>
              <p>Status: {barcodeProduct.sendout.status}</p>
            </DialogContent>
            <DialogActions>
              <Button
                component={Link}
                to={{ pathname: `sendouts/${barcodeProduct.sendout.id}` }}
              >
                Open sendout
              </Button>
              <Button onClick={onReturnedProduct}>Returned</Button>
              <Button onClick={reset}>Cancel</Button>
              <Button onClick={onRescanBarcode}>Re-scan</Button>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
}
