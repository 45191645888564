import { FC } from 'react';
import CreateButton from 'components/CreateButton';
import Toolbar from 'components/Toolbar';

interface IListHeaderProps {
  title: string;
}

const ListHeader: FC<IListHeaderProps> = (props) => {
  const { title } = props;

  return (
    <Toolbar title={title}>
      <CreateButton />
    </Toolbar>
  );
};

export default ListHeader;
