export const contactColumns = [
  {
    label: 'Name',
    source: 'name',
    hide: false,
  },
  {
    label: 'Email',
    source: 'email',
    hide: false,
  },
  {
    label: 'Phone',
    source: 'phone',
    hide: false,
  },
  {
    label: 'Address',
    source: 'addresses',
    hide: false,
  },
  {
    label: 'Notes',
    source: 'notes',
    hide: false,
  },
];
