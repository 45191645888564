import { SimpleForm, Edit, EditProps } from 'react-admin';
import EditToolbar from './EditToolbar';
import BrandForm from './BrandForm';

const BrandEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit brand" />}
    >
      <BrandForm />
    </SimpleForm>
  </Edit>
);

export default BrandEdit;
