import { FieldProps, useRecordContext } from 'react-admin';
import { IProduct } from 'types/strapi';
import DotField from 'components/DotField';

export default function ProductStatusField(props: FieldProps<IProduct>) {
  const product = useRecordContext<IProduct>();
  if (!product) return null;
  const color = product.sendout ? '#DF4A4A' : '#7ABD51';
  const title = product.sendout ? 'Reserved' : 'Available';

  return <DotField color={color} title={title} source="status" />;
}
