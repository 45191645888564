import { TextInput, required } from 'react-admin';
import Section from './Section';

export default function CategoryForm() {
  return (
    <Section>
      <TextInput source="name" variant="standard" validate={required()} />
    </Section>
  );
}
