import { SaveButton as RASaveButton, ToolbarProps } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import variables from 'styles/variables';
import useQuery from 'hooks/useQuery';

export default function SaveButton(props: ToolbarProps) {
  const {
    alwaysEnableSaveButton,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    redirect,
    saving,
    submitOnEnter,
    validating,
  } = props;

  const query = useQuery();

  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );

  const disabledSave = !valueOrDefault(
    alwaysEnableSaveButton,
    !pristine && !validating
  );

  // Redirect if query param is present
  const redirectByQuery = query.get('redirect') ?? undefined;

  return (
    <RASaveButton
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      disabled={disabledSave}
      invalid={invalid}
      redirect={redirectByQuery || redirect}
      saving={saving}
      submitOnEnter={submitOnEnter}
      variant={isDesktopButton ? 'contained' : 'text'}
      label="Save"
      icon={<></>}
      color="primary"
    />
  );
}

const valueOrDefault = (value: any, defaultValue: any) =>
  typeof value === 'undefined' ? defaultValue : value;
