import { TextInput, required, FieldProps } from 'react-admin';
import { IColor } from 'types/strapi';
import Section from './Section';

export default function ColorForm(props: FieldProps<IColor>) {
  const { record } = props;
  if (!record) return null;

  return (
    <Section>
      <TextInput source="name" validate={required()} {...props} />
    </Section>
  );
}
