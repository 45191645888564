import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Box } from '@material-ui/core';
import Toolbar from 'components/Toolbar';
import Scanner from './Scanner';
import ProductNotFound from './ProductNotFound';
import ProductFound from './ProductFound';
import ProductInSendout from './ProductInSendout';
import { IProduct } from 'types/strapi';

const Scan = () => {
  const dataProvider = useDataProvider();

  const [barcodeValue, setBarcodeValue] = useState('');
  const [searchComplete, setSearchComplete] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);

  useEffect(() => {
    if (barcodeValue) {
      dataProvider
        .getList<IProduct>('products', {
          pagination: { page: 1, perPage: 10000 },
          sort: { field: 'created_at', order: 'asc' },
          filter: { barcode: barcodeValue },
        })
        .then(({ data }) => {
          setProduct(data[0] || null);
          setSearchComplete(true);
        });
    }
  }, [barcodeValue, dataProvider]);

  const reset = () => {
    setBarcodeValue('');
    setSearchComplete(false);
    setProduct(null);
  };

  return (
    <>
      <Toolbar title="Barcode Scanner" />
      <Box sx={{ height: '100%', marginTop: '3.5rem', overflow: 'hidden' }}>
        {!searchComplete && !barcodeValue && (
          <Scanner
            barcodeValue={barcodeValue}
            setBarcodeValue={setBarcodeValue}
          />
        )}
        {searchComplete && !product && (
          <ProductNotFound barcodeValue={barcodeValue} reset={reset} />
        )}
        {searchComplete && !!product && !product?.sendout && (
          <ProductFound product={product} />
        )}
        {searchComplete && !!product?.sendout && (
          <ProductInSendout product={product} />
        )}
      </Box>
    </>
  );
};

export default Scan;
