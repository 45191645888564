import {
  List,
  TopToolbar,
  FilterButton,
  SearchInput,
  SelectInput,
  ReferenceInput,
  BulkDeleteButton,
  ListProps,
  DateInput,
  Exporter,
  downloadCSV,
  BulkExportButton,
  BulkActionProps,
  AutocompleteInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { sendoutStatusChoices } from 'constants/index';
import ListHeader from 'components/ListHeader';
import CustomPagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import ResponsiveSendoutList from 'components/ResponsiveSendoutList';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';
import ColumnsFilter from 'components/ColumnsFilter';
import FlagFilter from './FlagFilter';
import { AppState } from 'redux/AppState';
import { IContact, ISendout } from '../../../types/generated/strapi';
import jsonExport from 'jsonexport/dist';
import createLocaleDateString from 'utils/createLocaleDateString';
import ExportCsvButton from './ExportCsvButton';

const SendoutList = (props: ListProps) => {
  const columns = useSelector(
    (state: AppState) => state.columnFilters.sendouts
  );

  return (
    <>
      <ListHeader title="Sendouts" />
      <List
        {...props}
        actions={
          <>
            <TopToolbar>
              <ExportCsvButton label="Export" />
              <FilterButton />
              <ColumnsFilter resource="sendouts" columns={columns} />
            </TopToolbar>
          </>
        }
        perPage={10}
        filters={[
          <SearchInput variant="outlined" source="q" alwaysOn />,
          <SelectInput
            source="status"
            variant="outlined"
            choices={sendoutStatusChoices}
            allowEmpty
          />,
          <ReferenceInput
            {...props}
            label="Contact"
            source="contact"
            reference="contacts"
            enableGetChoices={({ q }) => !!q && q.length > 2}
            allowEmpty
            sort={{ field: 'lastName', order: 'ASC' }}
          >
            <AutocompleteInput
              suggestionLimit={15}
              optionText={(contact: IContact) =>
                `${contact?.firstName || ''} ${contact?.lastName || ''}`
              }
              options={{
                suggestionsContainerProps: {
                  modifiers: {
                    computeStyle: {
                      enabled: true,
                      gpuAcceleration: false,
                    },
                    applyStyle: {
                      enabled: true,
                    },
                  },
                },
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="products.brand"
            label="Brand"
            reference="brands"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <FlagFilter
            label="Unread messages"
            source="unreadMessages"
            filters={{
              'sendoutMessages.user_null': true,
              'sendoutMessages.read': false,
              'sendoutMessages.sendout_null': false,
            }}
          />,
          <FlagFilter
            label="Overdue returns"
            source="overdueReturns"
            filters={{
              '_where[status][0]': 'Dispatched',
              '_where[status][1]': 'Received',
              '_where[status][2]': 'Returning',
              estimatedReturnDate_lte: new Date().toISOString().split('T')[0],
            }}
          />,
          <DateInput
            label="Created (start date)"
            source="created_at_gte"
            variant="outlined"
          />,
          <DateInput
            label="Created (end date)"
            source="created_at_lte"
            variant="outlined"
          />,
        ]}
        filter={getOrganizationFilter()}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={<SendoutBulkActionButtons />}
        pagination={<CustomPagination />}
        empty={<EmptyTable columns={columns} />}
        exporter={exporter}
      >
        <ResponsiveSendoutList columns={columns} />
      </List>
    </>
  );
};

const exporter: Exporter = (records: ISendout[], fetchRelatedRecords) => {
  const data = records.map((record) => ({
    created_at: createLocaleDateString({
      //@ts-ignore-line
      value: record.created_at,
      withTime: true,
    }),
    contact: record.contact
      ? record.contact.firstName + ' ' + record.contact.lastName
      : '',
    status: record.status,
    products:
      record.products?.length > 0
        ? record.products.map((product) => product.name).join(', ')
        : record.returnedProducts?.length > 0
        ? record.returnedProducts.map((product) => product.name).join(', ')
        : '',
    estimatedReturnDate: createLocaleDateString({
      value: record.estimatedReturnDate,
    }),
    dueDate: createLocaleDateString({ value: record.dueDate }),
    notes: record.notes,
    address: record.address
      ? [
          record.address?.line1,
          record.address?.line2,
          record.address?.postcode,
          record.address?.city,
          record.address?.country,
        ]
          .filter((c) => c)
          .join(', ')
      : '',
  }));
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, 'sendouts');
  });
};

const SendoutBulkActionButtons = (props: BulkActionProps) => (
  <>
    <BulkExportButton {...props} />
    <BulkDeleteButton {...props} undoable={false} />
  </>
);

export default SendoutList;
