import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  Box,
  makeStyles,
} from '@material-ui/core';
import 'swiper/swiper-bundle.css';

interface ListImageFieldModalProps extends DialogProps {
  images: string[];
}

export default function ListImageFieldModal(props: ListImageFieldModalProps) {
  const { images, ...restProps } = props;
  const classes = useStyles();

  return (
    <Dialog {...restProps} classes={{ paper: classes.dialogWrapper }}>
      <DialogContent>
        {!!images.length && (
          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            autoHeight
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <Box className={classes.imageWrapper}>
                  <img src={image} alt="" className={classes.image} />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: any) => ({
  dialogWrapper: {
    width: '90vw',
    maxWidth: '600px',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
  },
}));
