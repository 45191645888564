import { Typography, Box, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as strapi from '@casbah/strapi-fetch';
import { getJwt } from 'utils/getJwt';
import { ISendout } from '../../../types/generated/strapi';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { makeStyles } from '@material-ui/core/styles';

export default function DashboardOverdueReturns() {
  const { loading, count, error, items } = useDashboardOverdueReturnsData();
  const seeMoreCount = count - items.length;
  const styles = useStyles();

  if (loading) {
    return (
      <Box className={styles.root}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.root}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (items.length === 0) {
    return (
      <Box className={styles.root}>
        <Typography>No overdue sendouts</Typography>
      </Box>
    );
  }

  return (
    <>
      {items.map((item, index) => (
        <Box key={index} className={styles.root}>
          <Box className={styles.date}>
            <Box className={styles.month}>
              {new Date(item.estimatedReturnDate as Date).toLocaleDateString(
                'en-GB',
                { month: 'short' }
              )}
            </Box>
            <Box className={styles.day}>
              {new Date(item.estimatedReturnDate as Date).toLocaleDateString(
                'en-GB',
                { day: '2-digit' }
              )}
            </Box>
          </Box>
          <Box>
            <Box className={styles.title}>{`Sendout #${item.id}`}</Box>
            <Box className={styles.subtitle}>
              {`${item.products.length} Products `}
            </Box>
          </Box>
          <Link className={styles.linkLabel} to={`/sendouts/${item.id}`}>
            Go to sendout
          </Link>
        </Box>
      ))}
      <Box className={styles.root}>
        <Link
          className={styles.seeAllLink}
          to={`/sendouts?displayedFilters={"overdueReturns":"true"}&filter={}`}
        >
          {seeMoreCount > 0 ? `+${seeMoreCount} see all` : 'see all'}
        </Link>
      </Box>
    </>
  );
}

const useDashboardOverdueReturnsData = () => {
  const [count, setCount] = useState(0);
  const [items, setItems] = useState<ISendout[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;
    Promise.all([
      strapi.get<number>(
        getStrapiURL(
          `/sendouts/count?status=Dispatched&status=Received&status=Returning&estimatedReturnDate_lt=${
            new Date().toISOString().split('T')[0]
          }`
        ),
        getJwt()
      ),
      strapi.get<ISendout[]>(
        getStrapiURL(
          `/sendouts?status=Dispatched&status=Received&status=Returning&estimatedReturnDate_lt=${
            new Date().toISOString().split('T')[0]
          }&_limit=4`
        ),
        getJwt()
      ),
    ]).then((res) => {
      if (!mounted) return;
      if (res[0].ok && res[1].ok) {
        setCount(res[0].payload as number);
        setItems(res[1].payload as ISendout[]);
        setLoading(false);
      } else {
        setLoading(false);
        setError(res[0].messages.join(', ') || 'Unknown error');
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  return {
    items,
    loading,
    count,
    error,
  } as const;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem 2rem',
    borderRadius: '2px',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
  date: {
    display: 'inline-block',
    marginRight: '2rem',
    border: '1px solid #FFA827',
    borderRadius: '5px',
    padding: '0.25rem 0',
    textAlign: 'center',
    width: '2.75rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '1.35rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '2.5rem',
      marginRight: '1rem',
    },
  },
  month: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '0.9rem',
  },
  day: {
    fontSize: '1.4rem',
  },
  title: {
    fontSize: '1rem',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  subtitle: {
    fontSize: '1rem',
    color: '#90929e',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  linkLabel: {
    marginLeft: 'auto',
    fontSize: '1rem',
    color: 'rgb(0, 102, 204)',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  seeAllLink: {
    fontSize: '1rem',
    color: 'rgb(0, 102, 204)',
    textDecoration: 'underline',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));
