import {
  useGetMany,
  useList,
  ListContextProvider,
  ListView,
  Button,
  BulkActionProps,
  useListContext,
} from 'react-admin';
import { useFormState, useField } from 'react-final-form';
import ResponsiveProductList from 'components/ResponsiveProductList';
import { SendoutFormValues } from 'types/sendoutFormValues';
import { makeStyles } from '@material-ui/core/styles';
import { getIdArray } from 'utils/getId';
import { sendoutProductsColumns } from 'constants/sendoutProductsColumns';
import EmptyTable from 'components/EmptyTable';
import { ISendout } from 'types/strapi';

export default function SendoutProductsTable(props: { readonly: boolean }) {
  const formState = useFormState<ISendout>();
  const productIds = [
    ...getIdArray(formState.values.products),
    ...getIdArray(formState.values.returnedProducts),
  ];

  const { data, loading } = useGetMany('products', productIds);
  const listContext = useList({
    data,
    ids: productIds,
    loading,
    loaded: !loading,
  });

  const classes = useStyles();

  return (
    <ListContextProvider
      value={{ ...listContext, basePath: '/products', resource: 'products' }}
    >
      <ListView
        pagination={false}
        actions={false}
        bulkActionButtons={props.readonly ? false : <BulkActionButtons />}
        classes={classes}
        empty={
          props.readonly ? (
            false
          ) : (
            <EmptyTable columns={sendoutProductsColumns} />
          )
        }
      >
        <ResponsiveProductList
          onRowClickReturn={props.readonly ? '' : 'edit'}
          columns={sendoutProductsColumns}
        />
      </ListView>
    </ListContextProvider>
  );
}

const BulkActionButtons = (props: BulkActionProps) => {
  const { selectedIds } = props;
  const { onUnselectItems } = useListContext();
  const formState = useFormState<SendoutFormValues>();

  const {
    input: { onChange: onChangeProducts },
  } = useField('products');

  const {
    input: { onChange: onChangeReturnedProducts },
  } = useField('returnedProducts');

  const onButtonClick = (action: 'returned' | 'notReturned' | 'remove') => {
    const productsIds = getIdArray(formState.values.products);
    const returnedProductIds = getIdArray(formState.values.returnedProducts);
    if (!productsIds.length && !returnedProductIds.length) return;

    let resultProducts: number[] = [];
    let resultReturnedProducts: number[] = [];

    if (action === 'remove') {
      resultProducts = productsIds.filter((id) => !selectedIds?.includes(id));
      resultReturnedProducts = returnedProductIds.filter(
        (id) => !selectedIds?.includes(id)
      );
    } else if (action === 'returned') {
      const selectedProductIds = productsIds.filter((id) =>
        selectedIds?.includes(id)
      );
      resultProducts = productsIds.filter((id) => !selectedIds?.includes(id));
      resultReturnedProducts = [...returnedProductIds, ...selectedProductIds];
    } else if (action === 'notReturned') {
      const selectedReturnedProductIds = returnedProductIds.filter((id) =>
        selectedIds?.includes(id)
      );
      resultProducts = [...productsIds, ...selectedReturnedProductIds];
      resultReturnedProducts = returnedProductIds.filter(
        (id) => !selectedIds?.includes(id)
      );
    } else {
      return;
    }

    onUnselectItems();
    onChangeProducts(resultProducts);
    onChangeReturnedProducts(resultReturnedProducts);
  };

  return (
    <>
      <Button onClick={() => onButtonClick('returned')} label="Returned" />
      <Button
        onClick={() => onButtonClick('notReturned')}
        label="Not returned"
      />
      <Button onClick={() => onButtonClick('remove')} label="Remove" />
    </>
  );
};

const useStyles = makeStyles({
  root: {
    marginTop: 0,
    padding: 0,
  },
  main: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
});
