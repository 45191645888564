import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';
import * as strapi from '@casbah/strapi-fetch';
import _ from 'lodash';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';

export default function DashboardTopBrands() {
  const { brands, loading, error } = useTopBrandsData({
    startDate: useMemo(
      () => new Date(new Date().setDate(new Date().getDate() - 30)),
      []
    ),
    endDate: useMemo(() => new Date(), []),
  });

  const styles = useStyles();

  if (loading) {
    return (
      <Box className={styles.container}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.container}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (brands.length === 0) {
    return (
      <Box className={styles.container}>
        <Typography>No brands</Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.brandsGraph}>
        {brands.map((brand, index) => (
          <Box
            key={index}
            className={styles.brandsGraphItem}
            style={{ width: brand.percent + '%' }}
          />
        ))}
      </Box>
      <Box className={styles.brandsGrid}>
        {brands.map((brand, index) => (
          <Box key={index} className={styles.brand}>
            <Box className={styles.brandName}>{brand.name}</Box>
            <Box className={styles.productsColumn}>
              <Box>{brand.productsCount} Products</Box>
              <Box>{brand.percent}%</Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

interface IBrand {
  name: string;
  percent: string;
  productsCount: number;
}

const useTopBrandsData = (props: { startDate: Date; endDate: Date }) => {
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;

    strapi
      .query<
        {
          products: {
            brand: { name: string };
          }[];
        }[]
      >(
        getStrapiURL('/graphql'),
        `sendouts(limit: -1, where: {
          _and: [
            { created_at_gte: "${
              props.startDate.toISOString().split('T')[0]
            }" },
            { created_at_lte: "${props.endDate.toISOString().split('T')[0]}" },
            { products_null: false },
            { organization: "${getOrganizationFilter().organization}" }
          ],
        }) {
          products {
            brand {
              name
            }
          }
        }`,
        getJwt()
      )
      .then((res) => {
        if (!mounted) return;

        if (res.ok) {
          if (!res.payload?.length) {
            setBrands([]);
            return;
          }

          const allBrands = _.flattenDeep(
            res.payload.map((x) =>
              x.products.map((c) => c.brand?.name).filter((c) => c)
            )
          );
          const brandGroups = _.groupBy(allBrands);

          setBrands(
            Object.keys(brandGroups)
              .map((b) => ({
                name: b,
                productsCount: brandGroups[b].length,
                percent: (
                  (brandGroups[b].length / allBrands.length) *
                  100
                ).toFixed(0),
              }))
              .sort((a, b) => b.productsCount - a.productsCount)
              .slice(0, 5)
          );
        } else {
          setError(res.messages.join(', ') || 'Unknown error');
        }
      })
      .finally(() => {
        if (!mounted) return;
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [props.startDate, props.endDate]);

  return {
    brands,
    loading,
    error,
  } as const;
};

const colors = ['#017EFA', '#30D988', '#007AB5', '#DF4A4A', '#FFA827'];

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    padding: '2rem',
  },
  brandsGraph: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin: '2rem',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  brandsGraphItem: {
    height: '10px',
    '&:nth-child(1)': {
      backgroundColor: colors[0],
    },
    '&:nth-child(2)': {
      backgroundColor: colors[1],
    },
    '&:nth-child(3)': {
      backgroundColor: colors[2],
    },
    '&:nth-child(4)': {
      backgroundColor: colors[3],
    },
    '&:nth-child(5)': {
      backgroundColor: colors[4],
    },
  },
  brandsGrid: {},
  brand: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2rem 0',
    margin: '0 2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderBottom: '1px solid #e0e0e0',
    },
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '1rem 0',
      margin: '0 1rem',
    },
  },
  productsColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
  },
  brandName: {
    fontWeight: 'normal',
    '*:nth-child(1) > &': {
      color: colors[0],
    },
    '*:nth-child(2) > &': {
      color: colors[1],
    },
    '*:nth-child(3) > &': {
      color: colors[2],
    },
    '*:nth-child(4) > &': {
      color: colors[3],
    },
    '*:nth-child(5) > &': {
      color: colors[4],
    },
  },
}));
