import {
  Layout,
  Sidebar,
  LayoutProps,
  MenuItemLink,
  useGetIdentity,
} from 'react-admin';
import { Box, Collapse, Typography } from '@material-ui/core';
import BottomMenu from 'components/BottomMenu';
import { makeStyles } from '@material-ui/core/styles';
import variables from '../styles/variables';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useState } from 'react';
import { useMenuItems } from 'hooks/useMenuItems';

export default function MyLayout(props: LayoutProps) {
  const styles = useLayoutStyles();
  return (
    <Layout
      {...props}
      classes={styles}
      appBar={MyAppBar}
      sidebar={MySidebar}
      menu={MyMenu}
    />
  );
}

const MySidebar = (props: any) => {
  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <BottomMenu />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Sidebar
          {...props}
          ModalProps={{ disableScrollLock: true }}
          open={true}
        />
      </Box>
    </>
  );
};

const MyMenu = (props: any) => {
  const { identity } = useGetIdentity();
  const { home, user, settings, other } = useMenuItems();
  const styles = useMenuStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const SettingsToggleIcon = settings.iconToggle(open);

  return (
    <Box className={styles.menu}>
      <Box className={styles.logo}>
        <Logo className={styles.logoSvg} />
      </Box>

      <MenuItemLink
        className={styles.link}
        to={home.url}
        primaryText={home.label}
        leftIcon={<home.icon />}
        exact
      />

      {other.map((menuItem) => (
        <MenuItemLink
          key={menuItem.label}
          className={styles.link}
          to={menuItem.url}
          primaryText={menuItem.label}
          leftIcon={<menuItem.icon />}
        />
      ))}

      <MenuItemLink
        className={styles.link}
        to={settings.url}
        primaryText={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: '0.75rem',
            }}
          >
            <Typography>{settings.label}</Typography>
            <SettingsToggleIcon />
          </Box>
        }
        onClick={handleClick}
        leftIcon={<settings.icon />}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: '0.75rem',
          }}
        >
          {settings.children.map((menuItem) => (
            <MenuItemLink
              key={menuItem.label}
              className={styles.link}
              to={menuItem.url}
              primaryText={menuItem.label}
              leftIcon={<menuItem.icon />}
            />
          ))}
        </Box>
      </Collapse>

      {identity && (
        <Box className={styles.profileLinkWrapper}>
          <Box className={styles.divider} />
          <MenuItemLink
            className={styles.profileLink}
            to={user.url}
            primaryText={user.label}
            leftIcon={<user.icon />}
          />
        </Box>
      )}
    </Box>
  );
};

const MyAppBar = () => <></>;

const useLayoutStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    height: '100%',
    minHeight: 'calc(100 * var(--vh, 1vh))',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 0,
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    backgroundColor: 'whitesmoke',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    zIndex: 'auto',
    paddingTop: 0,
    paddingLeft: '80px',
    paddingRight: '80px',
    paddingBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: `calc(${variables.appBar.mobileHeight} + 1rem)`,
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '100px',
    },
  },
}));

const useMenuStyles = makeStyles((theme) => ({
  menu: {
    width: '100%',
  },
  logo: {
    paddingTop: '45px',
    paddingLeft: '30px',
    paddingBottom: '33px',
    backgroundColor: '#000',
    color: '#fff',
  },
  logoSvg: {
    width: '80px',
    height: '22px',
  },
  link: {
    textTransform: 'capitalize',
  },
  profileLinkWrapper: {
    marginTop: '26px',
  },
  profileLink: {
    display: 'flex',
    paddingLeft: '34px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  divider: {
    borderTop: '1px solid #666666',
    width: '80%',
    margin: '0 26px 19px 26px',
  },
}));
