import { sendoutStatusChoices } from 'constants/index';
import {
  DateInput,
  SelectInput,
  TextInput,
  required,
  FieldProps,
  ReferenceInput,
  AutocompleteInput,
  useRedirect,
  useDataProvider,
  ArrayInput,
  SimpleFormIterator,
  email,
} from 'react-admin';
import SendoutEditProducts from 'components/SendoutEditProducts';
import {
  Box,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { IContact, ISendout } from 'types/strapi';
import Section from './Section';
import SectionHeader from './SectionHeader';
import TwoColumns from './TwoColumns';
import { ReactComponent as ContactsIcon } from 'assets/svg/resources/contacts.svg';
import SendoutShipping from './SendoutShipping';
import { useForm, useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Print as PrintIcon } from '@material-ui/icons';
import { printHtmlElement } from 'utils/printHtmlElement';
import ContactPrint from './ContactPrint';

const SendoutForm = (props: FieldProps<ISendout>) => {
  const { record } = props;
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const form = useForm();
  const {
    values: { address, cc },
    dirty,
  } = useFormState<ISendout>();
  if (!record) return null;

  const addNewCC = () => {
    form.change('cc', [...(cc || []), {}]);
  };
  const onContactSelected = (contact?: number) => {
    if (!contact) {
      form.change('address', null);
    } else {
      dataProvider
        .getOne<IContact>('contacts', { id: contact })
        .then(({ data }) => {
          const contactAddress = data.addresses?.[0];
          if (contactAddress)
            form.change('address', {
              ...(address || {}),
              line1: contactAddress.line1,
              line2: contactAddress.line2,
              city: contactAddress.city,
              postcode: contactAddress.postcode,
              country: contactAddress.country,
            });
          else form.change('address', null);
        });
    }
  };

  return (
    <>
      <Section>
        <SelectInput
          source="status"
          choices={sendoutStatusChoices}
          defaultValue="New"
          validate={required()}
          {...props}
        />
        <TwoColumns>
          <Box>
            <DateInput source="dueDate" {...props} />
          </Box>
          <Box>
            <DateInput source="estimatedReturnDate" {...props} />
          </Box>
        </TwoColumns>
        <TwoColumns>
          <Box>
            <TextInput source="event" {...props} />
          </Box>
          <Box>
            <TextInput source="talent" {...props} />
          </Box>
        </TwoColumns>
        <TextInput
          style={{ maxWidth: '100%' }}
          source="notes"
          multiline
          {...props}
        />
      </Section>

      <SectionHeader title="Recipient">
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ContactsIcon width={16} height={16} />}
          component={Link}
          to={{
            pathname: `/contacts/create/?redirect=${window.location.pathname}`,
          }}
        >
          Create Contact
        </Button>
      </SectionHeader>
      <Section>
        <TwoColumns>
          <Box>
            <ReferenceInput
              {...props}
              label="Contact"
              source="contact"
              reference="contacts"
              validate={required()}
              enableGetChoices={({ q }) => !!q && q.length > 2}
              onChange={onContactSelected}
              sort={{ field: 'lastName', order: 'ASC' }}
            >
              <AutocompleteInput
                suggestionLimit={15}
                optionText={(contact: IContact) =>
                  `${contact?.firstName || ''} ${contact?.lastName || ''}`
                }
                options={{
                  InputProps: {
                    endAdornment: record?.contact ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="go to contact page"
                          onClick={() =>
                            redirect(`/contacts/${record.contact}`)
                          }
                        >
                          <ContactsIcon width={24} height={24} />
                        </IconButton>
                        <Tooltip
                          title={dirty ? 'Please save new changes first' : ''}
                        >
                          <IconButton
                            aria-label="print contact details"
                            onClick={() =>
                              !dirty && printHtmlElement('contact-print')
                            }
                          >
                            <PrintIcon
                              style={{
                                color: dirty
                                  ? 'rgba(0, 0, 0, 0.12)'
                                  : undefined,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <ContactPrint />
                      </InputAdornment>
                    ) : null,
                  },
                  suggestionsContainerProps: {
                    modifiers: {
                      computeStyle: {
                        enabled: true,
                        gpuAcceleration: false,
                      },
                      applyStyle: {
                        enabled: true,
                      },
                    },
                  },
                }}
              />
            </ReferenceInput>
            <TextInput
              label="Address line 1"
              source="address.line1"
              {...props}
            />
            <TextInput
              label="Address line 2"
              source="address.line2"
              {...props}
            />
          </Box>
          <Box>
            <TextInput label="Postcode" source="address.postcode" {...props} />
            <TextInput label="City" source="address.city" {...props} />
            <TextInput label="Country" source="address.country" {...props} />
          </Box>
        </TwoColumns>
      </Section>
      <SectionHeader title="Recipient CC">
        <Button variant="contained" color="secondary" onClick={addNewCC}>
          Add new CC
        </Button>
      </SectionHeader>
      <Section>
        {cc?.length ? (
          <ArrayInput source="cc" label="">
            <SimpleFormIterator addButton={<span></span>}>
              <TextInput
                source="email"
                label="Email"
                validate={[required(), email()]}
                {...props}
              />
            </SimpleFormIterator>
          </ArrayInput>
        ) : (
          <p style={{ color: 'gray' }}>No recipient CC found</p>
        )}
      </Section>
      <SendoutShipping />
      <SendoutEditProducts {...props} />
    </>
  );
};

export default SendoutForm;
