import { IOrganization, IProduct } from 'types/strapi';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';

import qs from 'qs';
import _ from 'lodash';

export const getNewProductBarcode = async (): Promise<string> => {
  const query = {
    _limit: 1,
    _sort: 'barcode:DESC',
  };
  const jwt = getJwt();

  const productResponse = await strapi.get<IProduct[]>(
    getStrapiURL(`/products/?${qs.stringify(query, { indices: false })}`),
    jwt
  );

  const organizationId = getOrganizationFilter()?.organization;
  const organizationResponse = await strapi.get<IOrganization>(
    getStrapiURL(`/organizations/${organizationId}`),
    jwt
  );

  if (productResponse.ok && organizationResponse.ok) {
    const maxBarcode = productResponse.data?.[0]?.barcode;
    const barcodePrefix = organizationResponse.data?.barcodePrefix;

    return getNextBarcode(maxBarcode, barcodePrefix);
  } else {
    if (productResponse.error) console.error(productResponse.error);
    if (organizationResponse.error) console.error(organizationResponse.error);
  }

  return '';
};

const getNextBarcode = (
  barcode: string | undefined,
  prefix: string | undefined
): string => {
  let nextBarcodeNumber = 1;

  if (barcode) {
    const numberPattern = /\d+/g;
    const barcodeNumber = _.last(barcode.match(numberPattern));
    if (barcodeNumber) {
      nextBarcodeNumber = Number(barcodeNumber) + 1;
    }
  }

  const numberPartLength = 10 - (prefix?.length || 0);

  return `${prefix || ''}${String(nextBarcodeNumber).padStart(
    numberPartLength,
    '0'
  )}`;
};
