import {
  SelectInput,
  TextInput,
  required,
  FieldProps,
  ReferenceInput,
} from 'react-admin';
import { ICollection } from 'types/strapi';
import Section from './Section';

const CollectionForm = (props: FieldProps<ICollection>) => {
  const { record } = props;
  if (!record) return null;

  return (
    <>
      <Section>
        <TextInput source="name" validate={required()} {...props} />
        <ReferenceInput
          label="Brand"
          source="brand"
          reference="brands"
          variant="standard"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Section>
    </>
  );
};

export default CollectionForm;
