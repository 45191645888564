import fetch from 'cross-fetch';
import cookies from 'utils/cookies';
import qs from 'qs';
import { IDpdCredentials, IDpdCountry, IDpdService } from '../types/dpd';
import * as base64 from 'Base64';

// const apiUrl = 'https://api.dpdlocal.co.uk'
const apiUrl = 'https://cms.edit.exposure.net/services/dpd';

/**
 * Collection address
 * This is where the sendouts will be collected from
 */
const collectionAddress = {
  organization: 'Exposure',
  addressStreet: '20, Little Portland Street',
  addressProperty: null,
  postcode: 'W1W 8BS',
  town: 'London',
  countryCode: 'GB',
  notificationEmail: 'digitaladmin@exposure.net', // showroom@exposure.net
  notificationPhone: null,
};

/**
 * Default DPD headers
 */
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/**
 * Authenticate
 * Logs in with username and password to return a GeoSession id
 */
export const authenticate = async (
  accountNumber: string,
  username: string,
  password: string
) => {
  // login
  const encodedCredentials = base64.btoa(`${username}:${password}`); //+ 'zzz'
  const resp = await fetch(`${apiUrl}/user/?action=login`, {
    method: 'POST',
    headers: { ...headers, Authorization: `Basic ${encodedCredentials}` },
  });

  // handle error
  if (resp.status !== 200) {
    throw Error(
      `Unable to login with supplied credentials: ${resp.statusText}`
    );
  }

  // handle success
  const respData = await resp.json();

  const dpdCredentials = {
    GeoClient: `account/${accountNumber}`,
    GeoSession: respData.data.geoSession,
  };

  // save to cookies (when in browser)
  if (typeof window !== 'undefined') {
    cookies.setCookie('dpdIntegration', JSON.stringify(dpdCredentials), 30);
  }

  // and return
  return dpdCredentials;
};

/**
 * Get DPD credentials
 * Returns GeoClient and GeoSession stored in cookies after logging in if
 * they exist or null otherwise
 */
export const getDpdCredentials = (): IDpdCredentials | undefined => {
  let credentials = cookies.getCookie('dpdIntegration');
  if (credentials) return JSON.parse(credentials);
};

/**
 * Removes expired credentials
 */
export const removeExpiredCredentials = () => {
  cookies.deleteCookie('dpdIntegration');
};

/**
 * Get countries
 * Returns a list of countries supported by DPD
 */
export const getCountries = async (
  credentials: IDpdCredentials
): Promise<IDpdCountry[]> => {
  const resp = await fetch(`${apiUrl}/shipping/country`, {
    method: 'GET',
    headers: { ...headers, ...credentials },
  });
  if (resp.status !== 200) {
    throw Error(`Unable to get the list of countries: ${resp.statusText}`);
  }

  const respData = await resp.json();
  if (respData && respData.data && respData.data.country) {
    return respData.data.country as IDpdCountry[];
  }

  return [];
};

/**
 * Get country
 * Returns country information by country code
 */
export const getCountry = async (
  credentials: IDpdCredentials,
  countryCode: string
): Promise<IDpdCountry | undefined> => {
  const resp = await fetch(`${apiUrl}/shipping/country/${countryCode}`, {
    method: 'GET',
    headers: { ...headers, ...credentials },
  });
  if (resp.status !== 200) {
    throw Error(`Unable to get country by code: ${resp.statusText}`);
  }

  const respData = await resp.json();
  if (respData && respData.data && respData.data.country) {
    return respData.data.country;
  }
};

/**
 * Get services
 * Returns a list of services supported for the given address
 */
export const getServices = async (
  credentials: IDpdCredentials,
  weightKg: number,
  addressLine1: string,
  addressLine2: string | null = null,
  postcode: string,
  town: string,
  countryCode: string
): Promise<IDpdService[]> => {
  // countries
  const collectCountry = await getCountry(
    credentials,
    collectionAddress.countryCode
  );
  const deliverCountry = await getCountry(credentials, countryCode);
  if (!collectCountry || !deliverCountry) {
    throw Error(`Unknown or unsupported country ${countryCode}`);
  }

  // configure shipment
  const shipmentConfig = {
    // config
    businessUnit: '0',
    deliveryDirection: '1',
    numberOfParcels: '1',
    shipmentType: '0',
    totalWeight: `${weightKg}`,

    // collect
    collectionDetails: {
      address: {
        countryCode: collectCountry.countryCode,
        countryName: collectCountry.countryName,
        locality: null,
        organisation: collectionAddress.organization,
        postcode: collectionAddress.postcode,
        property: null,
        street: collectionAddress.addressStreet,
        town: collectionAddress.town,
        county: null,
      },
    },

    // deliver
    deliveryDetails: {
      address: {
        countryCode: deliverCountry.countryCode,
        countryName: deliverCountry.countryName,
        locality: null,
        organization: null,
        postcode: postcode,
        street: addressLine1,
        property: addressLine2,
        town: town,
        county: null,
      },
    },
  };

  const query = qs.stringify(shipmentConfig, { allowDots: true });
  const resp = await fetch(`${apiUrl}/shipping/network/?${query}`, {
    method: 'GET',
    headers: { ...headers, ...credentials },
  });
  if (resp.status !== 200) {
    throw Error(`Unable to get available services: ${resp.statusText}`);
  }

  const respData = await resp.json();
  if (respData && respData.data) {
    return respData.data;
  }

  return [];
};

/**
 * Create a shipment
 * Inserts a new shipment into the DPD app
 */
export const createShipment = async (
  credentials: IDpdCredentials,
  weightKg: number,
  serviceNetworkCode: string,
  contactName: string,
  contactPhone: string | null,
  organisation: string | null,
  countryCode: string,
  postcode: string,
  town: string,
  address1: string,
  address2: string | null = null,
  notificationEmail: string | null = null,
  notificationPhone: string | null = null,
  deliveryInstructions: string | null = null,
  shippingReference1: string | null = null,
  shippingReference2: string | null = null,
  shippingReference3: string | null = null
) => {
  // configure shipment
  const shipmentConfig = {
    jobId: null,
    collectionOnDelivery: false,
    invoice: null,
    collectionDate: null,
    consolidate: false,
    consignment: [
      {
        consignmentNumber: null,
        consignmentRef: null,
        parcel: [],
        networkCode: serviceNetworkCode,
        numberOfParcels: 1,
        totalWeight: weightKg,
        shippingRef1: shippingReference1,
        shippingRef2: shippingReference2,
        shippingRef3: shippingReference3,
        customsValue: null,
        deliveryInstructions: deliveryInstructions,
        parcelDescription: null,
        liability: false,
        liabilityValue: null,

        // collect
        collectionDetails: {
          collectionDetails: {
            contactName: collectionAddress.organization,
            telephone: collectionAddress.notificationPhone,
          },
          address: {
            organisation: collectionAddress.organization,
            countryCode: collectionAddress.countryCode,
            postcode: collectionAddress.postcode,
            street: collectionAddress.addressStreet,
            locality: null,
            town: collectionAddress.town,
            county: null,
          },
        },

        // deliver
        deliveryDetails: {
          contactDetails: {
            contactName: contactName,
            telephone: contactPhone,
          },
          notificationDetails: {
            email: notificationEmail,
            mobile: notificationPhone,
          },
          address: {
            organisation,
            countryCode,
            postcode,
            town,
            street: address1,
            locality: address2,
            county: null,
          },
        },
      },
    ],
  };

  const resp = await fetch(`${apiUrl}/shipping/shipment`, {
    method: 'POST',
    headers: { ...headers, ...credentials },
    body: JSON.stringify(shipmentConfig),
  });

  // send to DPD for verification
  // console.log('------------------------------------------------------------')
  // console.log('PRINT ME')
  // console.log('------------------------------------------------------------')
  // console.log(shipmentConfig)
  // console.log('------------------------------------------------------------')

  if (resp.status !== 200) {
    let msg = `Unable to create shipment: ${resp.statusText}`;
    if (resp.status === 401) msg = 'Unauthorized';
    throw Error(msg);
  }

  let respData = await resp.json();
  if (!respData || !respData.data) {
    throw Error(`Unexpected error when processing shipment response`);
  }

  // create response
  respData = respData.data;
  const consignment = respData.consignmentDetail[0];
  const response = {
    shipmentId: respData.shipmentId.toString(),
    consignmentNumber: consignment.consignmentNumber,
    parcelNumber: consignment.parcelNumbers[0],
  };

  // and return
  return response;
};

/**
 * Get shipping label
 * Returns printable shipping label in HTML format
 */
export const getShippingLabel = async (
  credentials: IDpdCredentials,
  shipmentId: string
) => {
  const resp = await fetch(`${apiUrl}/shipping/shipment/${shipmentId}/label`, {
    method: 'GET',
    headers: { ...headers, ...credentials, Accept: 'text/html' },
  });
  if (resp.status !== 200) {
    let msg = `Unable to retrieve shipping label: ${resp.statusText}`;
    if (resp.status === 401) msg = 'Unauthorized';
    throw Error(msg);
  }

  // return printable label
  const html = await resp.text();
  return html;
};
