import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import { useMemo, useState } from 'react';
import { useDataProvider } from 'react-admin';

export default function SearchResource<T>(props: {
  resource: string;
  label?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  onSelected: (option: T | null) => void;
  /** A function that returns an array of strings to be used for matching the search keywords */
  match: (option: T) => (string | undefined)[];
  renderOption?: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  filter?: (option: T) => boolean;
}) {
  const dataProvider = useDataProvider();
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const [value, setValue] = useState<T | null>(null);
  const [resources, setResources] = useState<T[]>([]);
  const filteredResources = useMemo(
    () => (props.filter ? resources.filter(props.filter) : resources),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resources]
  );

  const onInputChange = (value: string) => {
    setInputValue(value);
    if (!value) {
      clear();
      return;
    }
    if (value.length > 2) {
      dataProvider
        .getList(props.resource, {
          pagination: {
            page: 1,
            perPage: 15,
          },
          sort: {
            field: 'id',
            order: 'asc',
          },
          filter: {
            _q: value,
          },
        })
        .then((value) => {
          setResources(value.data as any);
        });
    }
  };

  const onChange = (value: T | null) => {
    props.onSelected(value);
  };

  const clear = () => {
    setInputValue('');
    setValue(null);
    setResources([]);
  };

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      size="small"
      renderInput={(params) => (
        <TextField
          variant={props.variant}
          {...params}
          label={props.label || 'Search'}
        />
      )}
      onBlur={clear}
      onInputChange={(event, value, reason) => onInputChange(value)}
      inputValue={inputValue}
      onChange={(event, value, reason) => onChange(value)}
      value={value}
      options={filteredResources}
      renderOption={props.renderOption}
      getOptionLabel={(option) =>
        props
          .match(option)
          .filter((o) => o !== undefined)
          .join(' ')
      }
      popupIcon={null}
      noOptionsText={`No ${props.resource} found`}
    />
  );
}
