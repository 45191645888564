import themeBase from './theme-base';

const themeMuiOverrides = {
  MuiListItem: {
    root: {
      paddingBottom: '0px',
    },
  },
  MuiListItemText: {
    root: {
      borderBottom: `1px solid ${themeBase.palette.grey[300]}`,
      paddingBottom: '8px',
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
      // hide validation errors cant use display none, will affect layout
      '& .MuiFormHelperText-root.Mui-error': {
        visibility: 'hidden',
        poinerEvents: 'none',
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: themeBase.shadows[2],
    },
  },
  MuiBottomNavigationAction: {
    root: {
      paddingTop: '20px !important',
      paddingBottom: '40px !important',
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: 'transparent',
    },
  },
  MuiDialogContent: {
    root: {
      paddingBottom: '32px',
    },
  },
  MuiDialogActions: {
    root: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      '& button': {
        padding: '5px 12px',
        marginRight: '8px',
        border: '1px solid rgba(0, 0, 0, 0.5)',
        '& svg': {
          display: 'none',
        },
      },
    },
  },
  MuiButton: {
    root: {
      whiteSpace: 'nowrap',
    },
    outlined: {
      padding: '5px 12px',
    },
    label: {
      fontWeight: 400,
      [themeBase.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
    containedPrimary: {
      '&:hover, &:focus': {
        backgroundColor: 'black',
      },
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiIconButton: {
    root: {
      [themeBase.breakpoints.down('sm')]: {
        padding: '8px',
      },
    },
    edgeEnd: {
      [themeBase.breakpoints.down('sm')]: {
        margin: '0',
      },
    },
  },
  MuiTableRow: {
    root: {
      '&:last-child td': {
        borderBottom: 0,
      },
    },
  },
};

export default themeMuiOverrides;
