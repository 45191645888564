import { productColumns } from 'constants/productColumns';
import { sendoutColumns } from 'constants/sendoutColumns';
import { contactColumns } from 'constants/contactColumns';
import { brandColumns } from 'constants/brandColumns';
import { collectionColumns } from 'constants/collectionColumns';
import { categoryColumns } from 'constants/categoryColumns';
import { sizeColumns } from 'constants/sizeColumns';
import { colorColumns } from 'constants/colorColumns';
import { seasonColumns } from 'constants/seasonColumns';

export default function columnFilters(
  state = initialState,
  action: ColumnFilterAction
): ColumnFilters {
  switch (action.type) {
    case COLUMNFILTER:
      const { resource, columns } = action.payload;

      localStorage.setItem(resource, JSON.stringify(columns));

      return {
        ...state,
        [resource]: columns,
      };
    default:
      return state;
  }
}

const setHideFromLocalStorage = (columns: Column[], savedColumns: Column[]) => {
  if (!savedColumns.length) return columns;

  columns.forEach((column) => {
    const exist = savedColumns.find(
      (savedColumn) => savedColumn.label === column.label
    );
    if (exist) {
      column.hide = exist.hide;
    }
  });

  return columns;
};

const initialState: ColumnFilters = {
  products: setHideFromLocalStorage(
    productColumns,
    JSON.parse(localStorage.getItem('products') || '[]')
  ),
  sendouts: setHideFromLocalStorage(
    sendoutColumns,
    JSON.parse(localStorage.getItem('sendouts') || '[]')
  ),
  contacts: setHideFromLocalStorage(
    contactColumns,
    JSON.parse(localStorage.getItem('contacts') || '[]')
  ),
  brands: setHideFromLocalStorage(
    brandColumns,
    JSON.parse(localStorage.getItem('brands') || '[]')
  ),
  categories: setHideFromLocalStorage(
    categoryColumns,
    JSON.parse(localStorage.getItem('categories') || '[]')
  ),
  collections: setHideFromLocalStorage(
    collectionColumns,
    JSON.parse(localStorage.getItem('collections') || '[]')
  ),
  seasons: setHideFromLocalStorage(
    seasonColumns,
    JSON.parse(localStorage.getItem('seasons') || '[]')
  ),
  sizes: setHideFromLocalStorage(
    sizeColumns,
    JSON.parse(localStorage.getItem('sizes') || '[]')
  ),
  colors: setHideFromLocalStorage(
    colorColumns,
    JSON.parse(localStorage.getItem('colors') || '[]')
  ),
};

export const COLUMNFILTER = 'COLUMNFILTER';

export type ColumnFilterPayload = {
  resource: string;
  columns: Column[];
};

export interface ColumnFilterAction {
  readonly type: typeof COLUMNFILTER;
  readonly payload: ColumnFilterPayload;
}

export type Column = {
  label: string;
  source: string;
  hide: boolean;
};

export interface ColumnFilters {
  products: Column[];
  sendouts: Column[];
  contacts: Column[];
  brands: Column[];
  collections: Column[];
  categories: Column[];
  seasons: Column[];
  sizes: Column[];
  colors: Column[];
}
