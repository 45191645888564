import CollectionForm from 'components/CollectionForm';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import EditToolbar from './EditToolbar';

const CollectionEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit collection" />}
    >
      <CollectionForm />
    </SimpleForm>
  </Edit>
);

export default CollectionEdit;
