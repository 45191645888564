import { FieldProps, useRecordContext, useResourceContext } from 'react-admin';
import { IProduct, ISendout } from 'types/strapi';
import { useFormState } from 'react-final-form';
import { getId } from 'utils/getId';
import DotField from 'components/DotField';

export default function SendoutProductReturnedField(
  props: FieldProps<IProduct>
) {
  const product = useRecordContext<IProduct>();
  const resource = useResourceContext();
  const formState = useFormState<ISendout>();
  const isResourceSendout = resource === 'sendouts';
  if (!product || !isResourceSendout) return null;

  const isReturned = formState.values.returnedProducts?.find(
    (c) => getId(c) === getId(product)
  );

  const color = isReturned ? '#7ABD51' : '#DF4A4A';
  const title = isReturned ? 'Returned' : 'Not returned';

  return <DotField color={color} title={title} source="returned" />;
}
