import { SimpleForm, CreateProps } from 'react-admin';
import Create from 'components/Create';
import CreateToolbar from './CreateToolbar';
import SeasonForm from './SeasonForm';

const SeasonCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create season" />}
    >
      <SeasonForm />
    </SimpleForm>
  </Create>
);

export default SeasonCreate;
