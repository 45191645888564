// https://github.com/Wolox/react-chat-widget

import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';
import * as strapi from '@casbah/strapi-fetch';
import {
  Widget,
  addUserMessage,
  addResponseMessage,
  dropMessages,
  setBadgeCount,
  isWidgetOpened,
  toggleWidget,
} from 'react-chat-widget';
import { useDataProvider, useGetIdentity } from 'react-admin';
import 'react-chat-widget/lib/styles.css';
import './index.css';
import { ISendout, ISendoutMessage, IUser } from 'types/strapi';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';

interface ChatProps {
  sendoutId?: string | number | undefined;
  sendoutToken?: string | undefined;
}

function SendoutChat(props: ChatProps) {
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  //const [messages, setMessages] = useState<ISendoutMessage[]>([]);
  const [sendout, setSendout] = useState<ISendout>();

  const userId = useMemo(() => identity?.id || null, [identity]);
  const isPublicUser = useMemo(() => (userId ? false : true), [userId]);
  const widgetWrapperRef = useRef<HTMLDivElement>(null);
  const isListenerAttached = useRef(false);
  const jwt = getJwt();

  useEffect(() => {
    sendout?.sendoutMessages.forEach((message) => {
      const { text = '', user } = message;
      if (isPublicUser) {
        user ? addResponseMessage(text) : addUserMessage(text);
      } else {
        user ? addUserMessage(text) : addResponseMessage(text);
      }
    });
  }, [isPublicUser, sendout?.sendoutMessages]);

  // Set badge count in chat
  useEffect(() => {
    const unreadMessagesCount =
      sendout?.sendoutMessages.filter((message) =>
        isPublicUser
          ? message.user && !message.read
          : !message.user && !message.read
      ).length ?? 0;
    setBadgeCount(unreadMessagesCount);
  }, [isPublicUser, sendout?.sendoutMessages]);

  const fetchSendoutWithId = useCallback(
    (sendoutId) => {
      strapi
        .get<ISendout>(getStrapiURL(`/sendouts/${sendoutId}`), jwt)
        .then((response) => {
          if (response.ok) {
            setSendout(response.data);
          } else {
            console.error(response.error);
          }
        });
    },
    [jwt]
  );

  const fetchSendoutWithToken = useCallback((sendoutToken) => {
    strapi
      .get<ISendout>(getStrapiURL(`/sendouts/token/${sendoutToken}`))
      .then((response) => {
        if (response.ok) {
          setSendout(response.data);
        } else {
          console.error(response.error);
        }
      });
  }, []);

  // Close widget and cleanup scroll lock on unmount
  useEffect(() => {
    return () => {
      if (isWidgetOpened()) {
        toggleWidget();
      }
      toggleScrollLock(false);
    };
  }, []);

  // Fetch messages
  useEffect(() => {
    // Clear messages
    dropMessages();

    if (props.sendoutId) fetchSendoutWithId(props.sendoutId);
    else if (props.sendoutToken) fetchSendoutWithToken(props.sendoutToken);
  }, [
    fetchSendoutWithId,
    fetchSendoutWithToken,
    props.sendoutId,
    props.sendoutToken,
  ]);

  const createNewSendoutMessage = (newMessage: string) => {
    const message = constructSendoutMessage(newMessage);

    if (props.sendoutId) createNewSendoutMessageWithSendoutId(message);
    else createNewSendoutMessageWithSendoutToken(message);
  };

  const constructSendoutMessage = (newMessage: string): ISendoutMessage => {
    return {
      text: newMessage,
      user: { id: userId } as IUser,
      sendout: { id: sendout?.id } as ISendout,
      organization: sendout?.organization,
    } as ISendoutMessage;
  };

  const createNewSendoutMessageWithSendoutId = (
    newMessage: ISendoutMessage
  ) => {
    dataProvider.create('sendout-messages', {
      data: newMessage,
    });
  };

  const createNewSendoutMessageWithSendoutToken = (
    newMessage: ISendoutMessage
  ) => {
    strapi
      .post<ISendoutMessage>(
        getStrapiURL(`/sendout-messages-by-token?token=${props.sendoutToken}`),
        newMessage
      )
      .then((response) => {
        if (!response.ok) {
          console.error(response.error);
        }
      });
  };

  const markMessagesAsRead = async () => {
    // Mark messages as read
    const ids = sendout?.sendoutMessages
      .filter(
        (message) =>
          !message.read && (isPublicUser ? message.user : !message.user)
      )
      .map((message) => message.id);

    if (!ids || !ids.length) return;

    const updateParams = constructSendoutMessageReadUpdateParams();

    if (props.sendoutId)
      await markSendoutMessageAsReadWithSendoutId(ids, updateParams);
    else await markSendoutMessageAsReadWithSendoutToken(ids, updateParams);
  };

  const constructSendoutMessageReadUpdateParams = (): ISendoutMessage => {
    return {
      read: true,
    } as ISendoutMessage;
  };

  const markSendoutMessageAsReadWithSendoutId = async (
    ids: number[],
    updateParams: ISendoutMessage
  ) => {
    dataProvider.updateMany('sendout-messages', {
      ids,
      data: updateParams,
    });
  };

  const markSendoutMessageAsReadWithSendoutToken = async (
    ids: number[],
    updateParams: ISendoutMessage
  ) => {
    await Promise.all(
      ids.map(async (id) => {
        const response = await strapi.put<ISendoutMessage>(
          getStrapiURL(
            `/sendout-messages-by-token/${id}?token=${props.sendoutToken}`
          ),
          updateParams,
          jwt
        );
        if (!response.ok) {
          console.error(response.error);
        }
      })
    );
  };

  const handleToggleChat = (isOpen: boolean) => {
    toggleScrollLock(isOpen);

    if (isOpen) {
      markMessagesAsRead();
      return;
    }
    const input = widgetWrapperRef.current?.querySelector('.rcw-input');
    input && removeChatInputKeydownListener(input as HTMLDivElement);
  };

  const toggleScrollLock = (isChatOpen: boolean) => {
    const isSmall = window.matchMedia('screen and (max-width: 800px)').matches;
    document.body.style.overflow = isChatOpen && isSmall ? 'hidden' : 'auto';
  };

  const onChatInputKeydown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      document.execCommand('insertLineBreak');
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!widgetWrapperRef.current) return;
    const messagesContainer = widgetWrapperRef.current.querySelector(
      '.rcw-messages-container'
    );

    if (!messagesContainer) return;
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  }, []);

  const handleTextInputChange = (e: any) => {
    if (isListenerAttached.current) return;
    addChatInputKeydownListener(e.target);
  };

  const removeChatInputKeydownListener = (e: HTMLDivElement) => {
    isListenerAttached.current = false;
    e.removeEventListener('keydown', onChatInputKeydown);
  };

  const addChatInputKeydownListener = (e: HTMLDivElement) => {
    isListenerAttached.current = true;
    e.addEventListener('keydown', onChatInputKeydown);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    // Avoid RA submittion because butttons with type submit in that chat library
    e.preventDefault();

    //@ts-ignore Scroll to bottom after keyboard appears on mobile
    const isInput = e.target.className === 'rcw-input';
    isInput && setTimeout(scrollToBottom, 200);
  };

  return (
    <div onClick={handleClick} ref={widgetWrapperRef}>
      <Widget
        handleTextInputChange={handleTextInputChange}
        handleNewUserMessage={createNewSendoutMessage}
        handleToggle={handleToggleChat}
        title="Chat"
        subtitle=""
        emojis={true}
        showTimeStamp={false}
        autofocus={false}
      />
    </div>
  );
}

export default SendoutChat;
