import {
  Datagrid,
  FunctionField,
  SimpleList,
  FieldProps,
  useRecordContext,
  TextField,
  DateField,
} from 'react-admin';
import { Box, Badge } from '@material-ui/core';
import { Mail as MailIcon } from '@material-ui/icons';
import { ISendout, ISendoutMessage } from 'types/strapi';
import { ReactComponent as SendoutOpenIcon } from 'assets/svg/sendout-open.svg';
import { ReactComponent as SendoutClosedIcon } from 'assets/svg/sendout-closed.svg';
import { ResponsiveListProps } from 'types/ResponsiveListProps';
import { sendoutStatusChoices } from 'constants/index';
import EmptyTableCell from './EmptyTableCell';

const ResponsiveSendoutList = (props: ResponsiveListProps) => {
  const { columns, ...rest } = props;

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Datagrid rowClick="edit" {...rest}>
          {columns.map((c) => {
            switch (c.source) {
              case 'created_at':
                return c.hide ? null : (
                  <DateField label={c.label} source={c.source} key={c.source} />
                );
              case 'contact':
                return c.hide ? null : (
                  <FunctionField<ISendout>
                    source={c.source}
                    label={c.label}
                    render={(record) => getFullName(record)}
                    key={c.source}
                  />
                );
              case 'status':
                return c.hide ? null : (
                  <StatusField
                    label={c.label}
                    source={c.source}
                    mobile={false}
                    key={c.source}
                  />
                );
              case 'products':
                return c.hide ? null : (
                  <ProductsField
                    source={c.source}
                    label={c.label}
                    sortable={false}
                    key={c.source}
                  />
                );
              case 'messages':
                return c.hide ? null : (
                  <UnreadMessagesField
                    source={c.source}
                    label={c.label}
                    sortable={false}
                    key={c.source}
                  />
                );
              case 'estimatedReturnDate':
                return c.hide ? null : (
                  <DateField source={c.source} label={c.label} key={c.source} />
                );
              default:
                return c.hide ? null : (
                  <TextField source={c.source} label={c.label} key={c.source} />
                );
            }
          })}
        </Datagrid>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <SimpleList
          primaryText={
            <StatusField label="Status" source="status" mobile={true} />
          }
        />
      </Box>
    </>
  );
};

const getFullName = (record?: ISendout) => {
  if (!record || !record.contact) return '';

  const { firstName, lastName } = record.contact;
  const fullName = [firstName, lastName].filter((chunk) => !!chunk).join(' ');

  return fullName;
};

const StatusField = ({
  label,
  mobile,
  source,
}: {
  label: string;
  mobile: boolean;
  source: string;
}) => (
  <FunctionField<ISendout>
    render={(record) => {
      if (!record) return '';

      const unread =
        record.sendoutMessages.filter((message: ISendoutMessage | number) => {
          if (typeof message === 'number') return false;
          return !message.read && !message.user;
        }).length || undefined;

      let status: string = record.status;
      const statuses = sendoutStatusChoices.filter((s) => s.id === status);
      if (statuses.length) status = statuses[0].name;

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {mobile && (
            <Box sx={{ mr: 3, ml: 2 }}>
              {status === 'New' ? (
                <SendoutOpenIcon style={{ width: '62px', height: '62px' }} />
              ) : (
                <SendoutClosedIcon style={{ width: '62px', height: '62px' }} />
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            {mobile && <Box>{getFullName(record)}</Box>}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>{status}</Box>
              {mobile && unread && (
                <Box>
                  <Badge badgeContent={unread} color="error">
                    <MailIcon />
                  </Badge>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    }}
  />
);

const UnreadMessagesField = (props: FieldProps<ISendout>) => {
  const { sendoutMessages } = useRecordContext(props);
  if (sendoutMessages.length) {
    const unread = sendoutMessages.filter((x) => x?.read === false).length;
    if (unread) {
      return (
        <Badge badgeContent={unread} color="error">
          <MailIcon />
        </Badge>
      );
    } else return <MailIcon />;
  }
  return <EmptyTableCell />;
};

const ProductsField = (props: FieldProps<ISendout>) => {
  const { products } = useRecordContext(props);
  const count = products.length || 0;

  return <FunctionField render={() => count} />;
};

export default ResponsiveSendoutList;
