import {
  List,
  ListProps,
  SearchInput,
  TopToolbar,
  TextInput,
  FilterButton,
  ReferenceInput,
  SelectInput,
  Exporter,
  downloadCSV,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';
import ListHeader from 'components/ListHeader';
import ResponsiveProductList from 'components/ResponsiveProductList';
import ProductImportCsv from 'components/ProductImportCsv';
import CustomPagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import ColumnsFilter from 'components/ColumnsFilter';
import FlagFilter from './FlagFilter';
import { AppState } from 'redux/AppState';
import ProductListBulkActionButtons from './ProductListBulkActionButtons';
import { IProduct } from 'types/strapi';
import jsonExport from 'jsonexport/dist';
import ExportCsvButton from './ExportCsvButton';

const ProductList = (props: ListProps) => {
  const columns = useSelector(
    (state: AppState) => state.columnFilters.products
  );

  return (
    <>
      <ListHeader title="Products" />
      <List
        {...props}
        exporter={exporter}
        perPage={10}
        filter={getOrganizationFilter()}
        filterDefaultValues={{ '_where[_or][0][archived_eq]': false }}
        filters={[
          <SearchInput variant="outlined" source="q" alwaysOn />,
          <TextInput variant="outlined" label="Barcode" source="barcode" />,
          <ReferenceInput
            variant="outlined"
            source="brand"
            label="Brand"
            reference="brands"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="categories"
            label="Category"
            reference="categories"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="collections"
            label="Collection"
            reference="collections"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="colors"
            label="Colour"
            reference="colors"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="size"
            label="Size"
            reference="sizes"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <ReferenceInput
            variant="outlined"
            source="season"
            label="Seasons"
            reference="seasons"
            allowEmpty
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>,
          <FlagFilter
            label="Include archived"
            source="includeArchived"
            filters={{
              '_where[_or][0][archived_eq]': false,
              '_where[_or][1][archived_eq]': true,
            }}
            defaultFilters={{ '_where[_or][0][archived_eq]': false }}
          />,
          <FlagFilter
            label="Bad names"
            source="badNames"
            filters={{ name_contains: 'n/a' }}
          />,
          <FlagFilter
            label="No colours"
            source="noColors"
            filters={{ colors_null: true }}
          />,
          <FlagFilter
            label="No brand"
            source="noBrand"
            filters={{ brand_null: true }}
          />,
        ]}
        actions={
          <>
            <TopToolbar>
              <ExportCsvButton />
              <ProductImportCsv resource="products" />
              <FilterButton />
              <ColumnsFilter resource="products" columns={columns} />
            </TopToolbar>
          </>
        }
        bulkActionButtons={<ProductListBulkActionButtons />}
        pagination={<CustomPagination />}
        empty={<EmptyTable columns={columns} />}
      >
        <ResponsiveProductList onRowClickReturn="edit" columns={columns} />
      </List>
    </>
  );
};

const exporter: Exporter = (records: IProduct[], fetchRelatedRecords) => {
  const data = records.map((record) => {
    const result = {} as any;
    const keys = Object.keys(record) as (keyof IProduct)[];
    keys.forEach((key) => {
      if (key === 'images') {
        return (result[key] =
          record[key]?.map((image) => image.url).join('\n') || '');
      }
      if (key === 'brand') {
        return (result[key] = record[key]?.name || '');
      }
      if (key === 'colors') {
        return (result[key] = record[key]?.map((c) => c.name).join('\n') || '');
      }
      if (key === 'categories') {
        return (result[key] = record[key]?.map((c) => c.name).join('\n') || '');
      }
      if (key === 'collections') {
        return (result[key] = record[key]?.map((c) => c.name).join('\n') || '');
      }
      if (key === 'season') {
        return (result[key] = record[key]?.name || '');
      }
      if (key === 'size') {
        return (result[key] = record[key]?.name || '');
      }
      if (key === 'sendout') {
        return (result['sendoutId'] = record[key]?.id || '');
      }
      if (key === 'organization' || key === 'keywords') {
        return;
      }
      return (result[key] = record[key]);
    });
    return result;
  });
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, 'products');
  });
};

export default ProductList;
