import { RouteWithoutLayout } from 'react-admin';
import { Route } from 'react-router-dom';
import SendoutShare from 'components/SendoutShare';
import Menu from 'components/Menu';
import Scan from 'components/Scan';

const customRoutes = [
  <RouteWithoutLayout
    key="sendout-preview"
    path="/sendout/:id"
    component={SendoutShare}
  />,
  <Route key="menu" path="/menu" component={Menu} />,
  <Route key="scan" path="/scan" component={Scan} />,
];

export default customRoutes;
