import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import merge from 'lodash/merge';
import * as importCsvMessages from 'react-admin-import-csv/lib/i18n';

const customMessages = {
  en: {
    ra: {
      message: {
        delete_title: 'Delete %{name}',
      },
    },
    csv: {
      dialogImport: {
        title: 'Importing...',
      },
      dialogCommon: {
        subtitle: 'Please, do not close the browser.',
      },
    },
  },
};

// Deep merge objects
const messages = {
  en: merge(englishMessages, importCsvMessages.en, customMessages.en),
};

const i18nProvider = polyglotI18nProvider(
  //@ts-ignore
  (locale) => messages[locale] || messages.en
);

export default i18nProvider;
