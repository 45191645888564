import {
  ToolbarProps,
  CloneButton as RACloneButton,
  useRecordContext,
  Record,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import variables from 'styles/variables';
import _ from 'lodash';

export default function CloneButton(props: ToolbarProps & { omit?: string[] }) {
  const record = useRecordContext();
  const filteredRecord = _.omit(record, props.omit || []) as Record;
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  return (
    <RACloneButton
      variant={isDesktopButton ? 'contained' : 'text'}
      label={!isXSmall ? 'Clone' : undefined}
      icon={!isXSmall ? <></> : <span>Clone</span>}
      color={isDesktopButton ? 'secondary' : 'primary'}
      record={filteredRecord}
      size="medium"
    />
  );
}
