import { Button, useMediaQuery } from '@material-ui/core';
import { ToolbarProps } from 'react-admin';
import { Link } from 'react-router-dom';
import variables from 'styles/variables';
import ShareIcon from '@material-ui/icons/Share';

const ShareButton = (props: ToolbarProps) => {
  const { record } = props;
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );

  return (
    <Button
      target="_blank"
      component={Link}
      to={`/sendout/${record?.shareToken}`}
      variant={isDesktopButton ? 'contained' : 'text'}
      color={isDesktopButton ? 'secondary' : 'primary'}
      endIcon={isDesktopButton ? <ShareIcon /> : undefined}
    >
      Share
    </Button>
  );
};

export default ShareButton;
