import Toolbar from 'components/Toolbar';
import LogoutButton from 'components/LogoutButton';
import SaveButton from 'components/SaveButton';
import { FC } from 'react';
import {
  TextInput,
  SimpleForm,
  Edit,
  EditProps,
  email,
  PasswordInput,
  ToolbarProps,
} from 'react-admin';
import buildVersion from '../assets/ts/build-version';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Section from './Section';

export default function UserEdit(props: EditProps) {
  const classes = useStyles();

  return (
    <>
      <Edit {...props}>
        <SimpleForm
          warnWhenUnsavedChanges
          submitOnEnter={false}
          redirect={false}
          variant="standard"
          toolbar={<UserEditToolbar />}
        >
          <Section>
            <TextInput source="username" />
            <TextInput source="email" validate={email()} />
            <PasswordInput source="password" />
          </Section>
        </SimpleForm>
      </Edit>
      <Box className={classes.buildVersion}>Build version: {buildVersion}</Box>
    </>
  );
}

const UserEditToolbar: FC<ToolbarProps> = (props) => (
  <Toolbar title="Account">
    <LogoutButton {...props} />
    <SaveButton {...props} />
  </Toolbar>
);

const useStyles = makeStyles((theme) => ({
  buildVersion: {
    opacity: 0.3,
    fontSize: '0.7rem',
  },
}));
