import { ListActionsProps } from 'react-admin';
import {
  IBrand,
  ICategory,
  ICollection,
  IColor,
  IProduct,
  ISize,
} from 'types/strapi';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';
import ImportCsv from 'components/ImportCsv';
import { IColumnToImport } from 'components/ImportCsv/types';
import { getStrapiURL } from 'utils/getStrapiUrl';
import { getJwt } from 'utils/getJwt';
import * as strapi from '@casbah/strapi-fetch';

interface ProductCsvColumns extends IColumnToImport {
  path: keyof IProduct;
  collectionFieldNameToValidateWith?:
    | keyof IBrand
    | keyof ISize
    | keyof IColor
    | keyof ICollection
    | keyof ICategory;
}

export default function ProductImportCsv(props: ListActionsProps) {
  async function getColumnsToImport(): Promise<ProductCsvColumns[]> {
    const jwt = getJwt();
    const { organization } = getOrganizationFilter();

    const [
      { payload: sizes },
      { payload: brands },
      { payload: colors },
      { payload: categories },
      { payload: collections },
      { payload: seasons },
    ] = await Promise.all([
      strapi.get<ISize[]>(getStrapiURL(`/sizes?_limit=-1`), jwt),
      strapi.get<IBrand[]>(
        getStrapiURL(`/brands?_limit=-1&organization=${organization}`),
        jwt
      ),
      strapi.get<IColor[]>(getStrapiURL(`/colors?_limit=-1`), jwt),
      strapi.get<ICategory[]>(
        getStrapiURL(`/categories?_limit=-1&organization=${organization}`),
        jwt
      ),
      strapi.get<ICollection[]>(
        getStrapiURL(`/collections?_limit=-1&organization=${organization}`),
        jwt
      ),
      strapi.get<ICollection[]>(
        getStrapiURL(`/seasons?_limit=-1&organization=${organization}`),
        jwt
      ),
    ]);

    return [
      {
        csvColumnName: 'name',
        path: 'name',
        validationRule: 'required',
      },
      {
        csvColumnName: 'description',
        path: 'description',
      },
      {
        csvColumnName: 'barcode',
        path: 'barcode',
      },
      {
        csvColumnName: 'brand',
        path: 'brand',
        validationRule: 'relationOne',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: brands || [],
      },
      {
        csvColumnName: 'size',
        path: 'size',
        validationRule: 'relationOne',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: sizes || [],
      },
      {
        csvColumnName: 'colors',
        path: 'colors',
        validationRule: 'relationMany',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: colors || [],
      },
      {
        csvColumnName: 'collections',
        path: 'collections',
        validationRule: 'relationMany',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: collections || [],
      },
      {
        csvColumnName: 'categories',
        path: 'categories',
        validationRule: 'relationMany',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: categories || [],
      },
      {
        csvColumnName: 'productCode',
        path: 'productCode',
      },
      {
        csvColumnName: 'gender',
        path: 'gender',
      },
      {
        csvColumnName: 'season',
        path: 'season',
        validationRule: 'relationOne',
        collectionFieldNameToValidateWith: 'name',
        collectionEntries: seasons || [],
      },
    ];
  }

  return (
    <ImportCsv
      listActionProps={props}
      getColumnsToImport={getColumnsToImport}
      labels={{ summary: 'products' }}
    />
  );
}
