export default function createLocaleDateString({
  value,
  locales,
  options,
  withTime,
}: {
  value: Date | string | undefined;
  locales?: string;
  options?: object;
  withTime?: boolean;
}): string {
  if (!value) return '';
  const date = value instanceof Date ? value : new Date(value);
  let dateOptions = options;
  if (
    typeof value === 'string' &&
    value.length <= 10 &&
    !withTime &&
    !options
  ) {
    // Input is a date string (e.g. '2022-02-15') without time and time zone.
    // Force timezone to UTC to fix issue with people in negative time zones
    // who may see a different date when calling toLocaleDateString().
    dateOptions = { timeZone: 'UTC' };
  }
  return withTime
    ? date.toLocaleString(locales, options)
    : date.toLocaleDateString(locales, dateOptions);
}
