import {
  BulkActionProps,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Button } from '@material-ui/core';

const ProductArchiveBulkActionButton = (props: BulkActionProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();

  const onClick = () => {
    dataProvider
      .updateMany('products', {
        ids: props.selectedIds || [],
        data: { archived: true },
      })
      .then(() => {
        refresh();
        notify(`${props.selectedIds?.length} products archived successfully`, {
          type: 'info',
          undoable: true,
        });
        onUnselectItems();
      })
      .catch(() => {
        notify('Archive not completed', {
          type: 'error',
        });
      });
  };

  return (
    <Button variant="text" color="primary" onClick={onClick}>
      Archive
    </Button>
  );
};

export default ProductArchiveBulkActionButton;
