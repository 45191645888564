import { IProduct, ISendout } from 'types/strapi';

export type ISendoutStatus = ISendout['status'];

export interface ISendoutStatusChoice {
  id: ISendoutStatus;
  name: string;
}

export const sendoutStatusChoices: ISendoutStatusChoice[] = [
  { id: 'New', name: 'New' },
  { id: 'PendingApproval', name: 'Pending Approval' },
  { id: 'Approved', name: 'Approved' },
  { id: 'Rejected', name: 'Rejected' },
  { id: 'Dispatching', name: 'Dispatching' },
  { id: 'Dispatched', name: 'Dispatched' },
  { id: 'Received', name: 'Received' },
  { id: 'Returning', name: 'Returning' },
  { id: 'Returned', name: 'Returned' },
];

export type IProductGender = IProduct['gender'];

export interface IProductGenderChoice {
  id: IProductGender;
  name: IProductGender;
}

export const productGenderChoices: IProductGenderChoice[] = [
  { id: 'Junior', name: 'Junior' },
  { id: 'Men', name: 'Men' },
  { id: 'Unisex', name: 'Unisex' },
  { id: 'Women', name: 'Women' },
];
