import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function SectionHeader(props: {
  title: string;
  children?: JSX.Element;
}) {
  const styles = useStyles();
  return (
    <Grid
      className={styles.root}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Box className={styles.title}>{props.title}</Box>
      <Box>{props.children}</Box>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '30px',
  },
  title: {
    fontSize: '1.1rem',
    'font-weight': '500',
  },
}));
