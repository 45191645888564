import { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Quagga from '@ericblade/quagga2';

const useStyles = makeStyles((theme) => ({
  cameraWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 32px)',
    backgroundColor: '#ccc',
  },
  camera: {
    display: 'block',
    width: '100%',
    height: '100%',
    '&> video': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    '&> canvas': {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
}));

interface ScannerProps {
  barcodeValue: string;
  setBarcodeValue: (value: string) => void;
}

const Scanner = (props: ScannerProps) => {
  const { barcodeValue, setBarcodeValue } = props;
  const classes = useStyles();

  const cameraEl = useRef<HTMLDivElement>(null);

  const onQuaggaInit = useCallback((error: Error) => {
    if (error) {
      if (error.name === 'NotAllowedError') {
        // TODO: show error if camera not allowed
        console.log('NOT ALLOWED CAMERA');
      }
      return;
    }

    Quagga.start();
  }, []);

  const initScanner = useCallback(() => {
    if (!cameraEl.current) return;
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: cameraEl.current,
          constraints: {
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: ['code_128_reader'],
          debug: {
            drawBoundingBox: true,
            drawScanline: true,
          },
        },
      },
      (error) => onQuaggaInit(error)
    );

    Quagga.onDetected((data) => {
      const result = data.codeResult.code;

      if (result) {
        setBarcodeValue(result);
        Quagga.stop();
      }
    });
  }, [onQuaggaInit, setBarcodeValue]);

  useEffect(() => {
    initScanner();

    return () => {
      Quagga.stop();
    };
  }, [initScanner, cameraEl]);

  if (barcodeValue) {
    return null;
  }

  return (
    <div className={classes.cameraWrapper}>
      <div id="camera" ref={cameraEl} className={classes.camera} />
    </div>
  );
};

export default Scanner;
