import { ToolbarProps } from 'react-admin';
import Toolbar from './Toolbar';
import DeleteButton from './DeleteButton';
import SaveButton from './SaveButton';

interface EditToolbarProps extends ToolbarProps {
  title: string;
}
export default function EditToolbar(props: EditToolbarProps) {
  return (
    <Toolbar title={props.title}>
      <DeleteButton {...props} />
      <SaveButton {...props} />
    </Toolbar>
  );
}
