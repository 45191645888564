import themeBase from './theme-base';

const themeRaOverrides = {
  RaDatagrid: {
    headerCell: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      position: 'static',
      borderBottom: `1px solid ${themeBase.palette.grey[300]}`,
    },
    rowCell: {
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
    },
  },
  RaListToolbar: {
    toolbar: {
      minHeight: '0',
      marginBottom: '1rem',
      backgroundColor: 'transparent !important',
      [themeBase.breakpoints.down('sm')]: {
        paddingLeft: '0',
      },
    },
  },
  RaTopToolbar: {
    root: {
      minHeight: '57px',
      padding: '0 !important',
      backgroundColor: 'transparent !important',
      alignItems: 'center',
      '& > *': {
        marginLeft: '0.5rem !important',
      },
    },
  },
  RaEmpty: {
    toolbar: {
      display: 'none',
    },
  },
  RaSimpleFormIterator: {
    line: {
      [themeBase.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
  },
  RaFormInput: {
    input: {
      width: '100%',
      // hide validation errors cant use display none, will affect layout
      '& .MuiFormHelperText-root.Mui-error': {
        visibility: 'hidden',
      },
      [themeBase.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
  },
  RaImageInput: {
    dropZone: {
      border: '2px dashed lightgray',
      padding: '50px',
      borderRadius: '4px',
    },
  },
  RaFilterForm: {
    form: {
      minHeight: 0,
      marginTop: 0,
    },
  },
  RaSearchInput: {
    input: {
      marginTop: 'boom',
    },
  },
  RaFilterFormInput: {
    body: {
      alignItems: 'center',
    },
    hideButton: {
      padding: '4px',
      order: 2,
      color: '#b2b2b2',
    },
    spacer: {
      order: 3,
    },
  },
  RaFileInputPreview: {
    removeButton: {
      zIndex: '1',
    },
  },
  RaFileInput: {
    preview: {
      width: 'calc(100% - 8px)',
      [themeBase.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
      },
    },
  },
  RaImageField: {
    list: {
      padding: 0,
      margin: 0,
    },
    image: {
      width: '100%',
      height: '50%',
      maxHeight: '100%',
      borderRadius: '4px',
      objectFit: 'contain',
      margin: '0px',
      boxShadow: themeBase.shadows[1],
    },
  },
  RaLabeled: {
    value: {
      '& .previews': {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gap: '8px',
      },
      [themeBase.breakpoints.down('xs')]: {
        '& .previews': {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  },
  RaList: {
    main: {
      maxWidth: '100vw',
      boxShadow: themeBase.shadows[1],
    },
  },
  RaCreate: {
    main: {
      margin: '0!important',
    },
    card: {
      boxShadow: 'none',
      overflow: 'inherit',
      backgroundColor: 'transparent',
      '& form': {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
  },
  RaEdit: {
    main: {
      margin: '0!important',
    },
    card: {
      boxShadow: 'none',
      overflow: 'inherit',
      backgroundColor: 'transparent',
      '& form': {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
  },
  RaSingleFieldList: {
    root: {
      marginTop: '0',
      marginbottom: '0',
    },
  },
  RaSidebar: {
    root: {
      [themeBase.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    fixed: {
      height: '100vh',
      width: 240,
      backgroundColor: '#000',
    },
  },
  RaMenu: {
    open: {
      padding: '33px 0px 18px 0',
    },
    main: {
      marginTop: '2px',
      marginBottom: '7px',
    },
  },
  RaMenuItemLink: {
    root: {
      color: themeBase.palette.secondary.main,
      paddingLeft: '32px',
      margin: '0',
      padding: '12px 0',
      fontSize: '16px',
      fontWeight: 300,
      '&:hover': {
        backgroundColor: '#181818',
      },
    },
    active: {
      color: themeBase.palette.primary.contrastText,
      fontWeight: 500,
    },
    icon: {
      color: '#939393',
      '& svg': {
        width: '20px',
        height: '100%',
      },
    },
  },
  RaButton: {
    label: {
      paddingLeft: 0,
      fontWeight: 400,
      [themeBase.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
    smallIcon: {
      height: '0.9em',
      width: '0.9em',
      marginRight: '0.1em',
    },
  },
  RaFilterButton: {
    root: {
      marginLeft: '0.5rem',
    },
  },
  RaDeleteWithConfirmButton: {
    deleteButton: {
      color: 'reset',
      '&:hover': {
        backgroundColor: 'reset',
      },
    },
  },
  RaSaveButton: {
    leftIcon: {
      color: themeBase.palette.primary.contrastText,
    },
  },
  RaCardContentInner: {
    root: {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
};

export default themeRaOverrides;
