import { SimpleForm, Edit, EditProps } from 'react-admin';
import EditToolbar from './EditToolbar';
import ColorForm from './ColorForm';

const ColorEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit color" />}
    >
      <ColorForm />
    </SimpleForm>
  </Edit>
);

export default ColorEdit;
