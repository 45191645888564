import CollectionForm from 'components/CollectionForm';
import Create from 'components/Create';
import { CreateProps, SimpleForm } from 'react-admin';
import CreateToolbar from './CreateToolbar';

const CollectionCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create collection" />}
    >
      <CollectionForm />
    </SimpleForm>
  </Create>
);

export default CollectionCreate;
