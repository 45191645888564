import {
  List,
  ListProps,
  TopToolbar,
  SearchInput,
  FilterButton,
  TextInput,
  BulkDeleteButton,
  Exporter,
  downloadCSV,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { getOrganizationFilter } from 'utils/getOrganizationFilter';
import ListHeader from 'components/ListHeader';
import ResponsiveContactList from 'components/ResponsiveContactList';
import CustomPagination from 'components/Pagination';
import ContactImportCsv from 'components/ContactImportCsv';
import EmptyTable from 'components/EmptyTable';
import ColumnsFilter from 'components/ColumnsFilter';
import { AppState } from 'redux/AppState';
import { IContact } from 'types/strapi';
import jsonExport from 'jsonexport/dist';
import ExportCsvButton from './ExportCsvButton';

const ContactList = (props: ListProps) => {
  const columns = useSelector(
    (state: AppState) => state.columnFilters.contacts
  );

  return (
    <>
      <ListHeader title="Contacts" />
      <List
        {...props}
        exporter={exporter}
        perPage={10}
        filters={[
          <SearchInput variant="outlined" source="q" alwaysOn />,
          <TextInput variant="outlined" label="Email" source="email" />,
          <TextInput variant="outlined" label="Phone number" source="phone" />,
        ]}
        filter={getOrganizationFilter()}
        actions={
          <>
            <TopToolbar>
              <ExportCsvButton />
              <ContactImportCsv resource="contacts" />
              <FilterButton />
              <ColumnsFilter resource="contacts" columns={columns} />
            </TopToolbar>
          </>
        }
        bulkActionButtons={<BulkDeleteButton undoable={false} />}
        pagination={<CustomPagination />}
        empty={<EmptyTable columns={columns} />}
      >
        <ResponsiveContactList columns={columns} onRowClickReturn="edit" />
      </List>
    </>
  );
};

const exporter: Exporter = (records: IContact[], fetchRelatedRecords) => {
  const data = records.map((record) => {
    const addresses: Record<string, any> = {};
    if (record.addresses.length > 0) {
      record.addresses.forEach((address, index) => {
        addresses[`addresses.${index}.line1`] = address.line1;
        addresses[`addresses.${index}.line2`] = address.line2;
        addresses[`addresses.${index}.postcode`] = address.postcode;
        addresses[`addresses.${index}.city`] = address.city;
        addresses[`addresses.${index}.country`] = address.country;
      });
    }
    return {
      firstName: record.firstName,
      lastName: record.lastName,
      email: record.email,
      phone: record.phone,
      company: record.company,
      role: record.role,
      notes: record.notes,
      ...addresses,
      sendoutIds: record.sendouts.map((sendout) => sendout.id).join(', '),
    };
  });
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, 'contacts');
  });
};

export default ContactList;
