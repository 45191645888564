import { makeStyles } from '@material-ui/core/styles';

export default function TwoColumns(props: {
  children: [JSX.Element, JSX.Element];
}) {
  const styles = useStyles();
  return <div className={styles.root}>{props.children}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      width: '46%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));
