import { TextInput } from 'react-admin';
import { useCallback, useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import {
  CameraAlt as CameraIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import { useFormState, useForm } from 'react-final-form';
import Barcode from 'components/Barcode';
import BarcodeScannerWithDialog from './BarcodeScannerWithDialog';
import BarcodePrint from './BarcodePrint';
import { printHtmlElement } from 'utils/printHtmlElement';

const BarcodeInput = () => {
  const { values } = useFormState();
  const [barcodeScannerOpen, setBarcodeScannerOpen] = useState(false);
  const form = useForm();

  const updateBarcode = useCallback(
    (value: string) => {
      form.change('barcode', value);
    },
    [form]
  );

  const openBarcodeScanner = useCallback(() => {
    setBarcodeScannerOpen(true);
  }, []);

  const onBarcodeScannerClosed = useCallback(() => {
    setBarcodeScannerOpen(false);
  }, []);

  const printBarcode = useCallback(() => {
    printHtmlElement('product-barcode-print');
  }, []);

  return (
    <>
      <TextInput
        source="barcode"
        variant="standard"
        helperText="Barcode is automatically created when product is saved"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Scan barcode"
                title="Scan barcode"
                edge="end"
                onClick={openBarcodeScanner}
              >
                <CameraIcon />
              </IconButton>
              {values.barcode && (
                <IconButton
                  aria-label="Print barcode"
                  title="Print barcode"
                  edge="end"
                  onClick={printBarcode}
                >
                  <PrintIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          updateBarcode(event.target.value);
        }}
      />

      {values.barcode && (
        <>
          <Barcode value={values.barcode} />
          <BarcodePrint />
        </>
      )}

      {barcodeScannerOpen && (
        <BarcodeScannerWithDialog
          onClose={onBarcodeScannerClosed}
          onChangeBarcodeValue={updateBarcode}
        />
      )}
    </>
  );
};

export default BarcodeInput;
