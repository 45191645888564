import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from 'components/Toolbar';
import DashboardTopBrands from './DashboardTopBrands';
import { ReactComponent as SendoutsIcon } from 'assets/svg/resources/sendouts.svg';
import DashboardCountCard from './DashboardCountCard';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DashboardTableCard from './DashboardTableCard';
import { ReactComponent as ProductsIcon } from 'assets/svg/resources/products.svg';
import TwoColumns from './TwoColumns';
import DashboardOverdueReturns from './DashboardOverdueReturns';
import DashboardSendoutsChart from './DashboardSendoutsChart';
import CreateButton from './CreateButton';

export default function Dashboard() {
  const styles = useStyles();

  return (
    <>
      <Toolbar title="Dashboard">
        <CreateButton path="/sendouts/create" label="Create sendout" />
        <CreateButton path="/products/create" label="Create product" />
      </Toolbar>
      <TwoColumns>
        <Box>
          <Box className={styles.cardsWrapper}>
            <DashboardCountCard
              fetchUrl="/sendouts/count?status=PendingApproval"
              link='/sendouts?displayedFilters={"status":"true"}&filter={"status":"PendingApproval"}'
              icon={<SendoutsIcon />}
              title="Sendouts pending approval"
              emptyTitle="No sendouts pending approval"
              linkLabel="Review and approve"
              variant="withCountOnRight"
            />
            <DashboardCountCard
              fetchUrl="/sendouts/count?status=Approved"
              link='/sendouts?displayedFilters={"status":"true"}&filter={"status":"Approved"}'
              icon={<SendoutsIcon />}
              title="Sendouts approved"
              emptyTitle="No sendouts approved"
              linkLabel="Review and dispatch"
              variant="withCountOnRight"
            />
            <DashboardCountCard
              fetchUrl="/sendout-messages/count?read=false&sendout_null=false&user_null=true"
              link='/sendouts?displayedFilters={"unreadMessages":"true"}'
              icon={<MailOutlineIcon fontSize="inherit" />}
              title="Unread messages"
              emptyTitle="No unread messages"
              linkLabel="Read and reply"
              variant="withCountOnRight"
            />
          </Box>
          <Box className={styles.tableWrapper}>
            <DashboardTableCard title="Overdue Returns">
              <DashboardOverdueReturns />
            </DashboardTableCard>
          </Box>
        </Box>
        <Box>
          <Box className={styles.tableWrapper}>
            <DashboardTableCard title="Data Health">
              <DashboardCountCard
                fetchUrl="/products/count?name_contains=n/a&archived=false"
                link='/products?displayedFilters={"badNames":"true"}'
                icon={<ProductsIcon />}
                title="Products with bad names"
                emptyTitle="All products are named correctly"
                subtitle={(count) => `${count} Products`}
                emptySubtitle="Well done!"
                linkLabel="Check and fix"
                variant="default"
              />
              <DashboardCountCard
                fetchUrl="/products/count?colors_null=true&archived=false"
                link='/products?displayedFilters={"noColors":"true"}'
                icon={<ProductsIcon />}
                title="Products with no colours"
                emptyTitle="All products have a colours"
                subtitle={(count) => `${count} Products`}
                emptySubtitle="Well done!"
                linkLabel="Check and fix"
                variant="default"
              />
              <DashboardCountCard
                fetchUrl="/products/count?brand_null=true&archived=false"
                link='/products?displayedFilters={"noBrand":"true"}'
                icon={<ProductsIcon />}
                title="Products with no brand"
                emptyTitle="All products have a brand"
                subtitle={(count) => `${count} Products`}
                emptySubtitle="Well done!"
                linkLabel="Check and fix"
                variant="default"
              />
            </DashboardTableCard>
          </Box>
          <Box className={styles.tableWrapper}>
            <DashboardTableCard title="Top Brands">
              <DashboardTopBrands />
            </DashboardTableCard>
          </Box>
        </Box>
      </TwoColumns>
      <DashboardTableCard title="Sendouts">
        <DashboardSendoutsChart />
      </DashboardTableCard>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginBottom: '3.75rem',
  },
  cardsWrapper: {
    marginBottom: '3.75rem',
    '& > *:not(:last-child)': {
      marginBottom: '1.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      '& > *:not(:last-child)': {
        marginBottom: '1.4rem',
      },
    },
  },
}));
