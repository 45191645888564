import { SimpleForm, CreateProps } from 'react-admin';
import Create from 'components/Create';
import CreateToolbar from './CreateToolbar';
import BrandForm from './BrandForm';

const BrandCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      toolbar={<CreateToolbar title="Create brand" />}
    >
      <BrandForm />
    </SimpleForm>
  </Create>
);

export default BrandCreate;
