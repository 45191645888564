import { ListActionsProps } from 'react-admin';
import { ICategory } from 'types/strapi';
import ImportCsv from 'components/ImportCsv';
import { IColumnToImport } from 'components/ImportCsv/types';

interface CategoryCsvColumns extends IColumnToImport {
  path: keyof ICategory;
}

const CategoryImportCsv = (props: ListActionsProps) => {
  function getColumnsToImport(): Promise<CategoryCsvColumns[]> {
    return Promise.resolve([
      {
        csvColumnName: 'name',
        path: 'name',
        validationRule: 'required',
      },
    ]);
  }

  return (
    <ImportCsv
      listActionProps={props}
      getColumnsToImport={getColumnsToImport}
      labels={{ summary: 'categories' }}
    />
  );
};

export default CategoryImportCsv;
