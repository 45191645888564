import { EmptyProps } from 'react-admin';
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Column } from 'redux/columnFilters';
export interface CustomEmptyProps extends EmptyProps {
  columns: Column[];
}

const EmptyTable = (props: CustomEmptyProps) => {
  const { resource, columns } = props;

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c) =>
                  c.hide ? null : (
                    <TableCell key={`${resource} ${c.source}`}>
                      {c.label}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>No {resource} yet</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <List>
          <ListItem>
            <ListItemText>No {resource} yet</ListItemText>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default EmptyTable;
