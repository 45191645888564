import { SimpleForm, Edit, EditProps } from 'react-admin';
import EditToolbar from './EditToolbar';
import SizeForm from './SizeForm';

const SizeEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit size" />}
    >
      <SizeForm />
    </SimpleForm>
  </Edit>
);

export default SizeEdit;
