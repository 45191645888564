import { useResourceContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button, useMediaQuery } from '@material-ui/core';
import variables from 'styles/variables';

const CreateButton = (props: { path?: string; label?: string } | undefined) => {
  const resource = useResourceContext();
  const isDesktopButton = useMediaQuery((theme: any) =>
    theme.breakpoints.up(variables.appBar.breakpointDesktop)
  );

  return (
    <Button
      component={Link}
      to={{
        pathname: props?.path || `/${resource}/create`,
        state: { _scrollToTop: true },
      }}
      variant={isDesktopButton ? 'contained' : 'text'}
      color="primary"
    >
      {props?.label || 'Create'}
    </Button>
  );
};

export default CreateButton;
