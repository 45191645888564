import { SimpleForm, Edit, EditProps } from 'react-admin';
import ContactForm from 'components/ContactForm';
import EditToolbar from './EditToolbar';

const ContactEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      submitOnEnter={false}
      variant="standard"
      redirect={false}
      toolbar={<EditToolbar title="Edit contact" />}
    >
      <ContactForm />
    </SimpleForm>
  </Edit>
);

export default ContactEdit;
